(function () {
    'use strict';

    angular
        .module('alpha.common.alphaLookupTree')
        .factory('AlphaLookupTreeService', AlphaLookupTreeService);

    AlphaLookupTreeService.$inject = [
        'UserPreferences',
        'LookupInterface',
        'Utils',
        '$q'
    ];

    function AlphaLookupTreeService(
        UserPreferences,
        LookupInterface,
        Utils,
        $q
    ) {

        return {
            getRoot: getRoot,
            getChildren: getChildren,
            filterTree: filterTree,
            getLookupValues: getLookupValues,
        };

        /**
         * Provides a partial tree from the root level. If a selected
         * lookup value is specified, that value will be included along
         * with its siblings, its parents, and their siblings.
         *
         * @method getRoot
         *
         * @param {String} libraryId Service name of the lookup library to get the tree from
         * @param {Object} [fieldTypeOptions] Field type options to consider for security, exclusive objects, etc.
         * @param {String} fieldTypeOptions.recordTypeId Service name of the record type the field type belongs to
         * @param {String} fieldTypeOptions.fieldTypeId Service name of the field type
         * @param {Boolean} [fieldTypeOptions.exclusiveObjects] Whether to filter values based on exclusive objects for the record type
         * @param {String} [selectedValue] Service name of a lookup value that needs to be shown in an expanded tree
         * @param {Boolean} [forceRefresh] Whether to always load the data from the server instead of using the request cache
         * @param {Boolean} showExpired  Condition to show expired lookup values
         * @param {Boolean} applyLkpSec Condition to apply lookup security exclusively
         * @returns {Object} A promise to be resolved with the tree or rejected with an error
         */
        function getRoot(libraryId, fieldTypeOptions, selectedValue, forceRefresh, showExpired , applyLkpSec) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    if (forceRefresh) {
                        LookupInterface.getLookupAncestorsWithUncles(
                            clientId,
                            libraryId,
                            selectedValue || null,
                            _.get(fieldTypeOptions, 'recordTypeId') || null,
                            _.get(fieldTypeOptions, 'fieldTypeId') || null,
                            _.get(fieldTypeOptions, 'exclusiveObjects') || false,
                            showExpired || false,
                            applyLkpSec || false,
                            _successCallback,
                            _errorCallback
                        );
                    } else {
                        LookupInterface.getLookupAncestorsWithUnclesFromCache(
                            clientId,
                            libraryId,
                            selectedValue || null,
                            _.get(fieldTypeOptions, 'recordTypeId') || null,
                            _.get(fieldTypeOptions, 'fieldTypeId') || null,
                            _.get(fieldTypeOptions, 'exclusiveObjects') || false,
                            showExpired || false,
                            applyLkpSec || false,
                            _successCallback,
                            _errorCallback
                        );
                    }
                    function _successCallback(response) {
                        deferred.resolve({children: Utils.removeAngularProps(response)});
                    }
                    function _errorCallback(response) {
                        deferred.reject(response.data.errorMessage);
                    }
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        /**
         * Provides the children of a branch node in a tree.
         *
         * @method getChildren
         *
         * @param {String} libraryId Service name of the lookup library to get the children from
         * @param {Object} [fieldTypeOptions] Field type options to consider for security, exclusive objects, etc.
         * @param {String} fieldTypeOptions.recordTypeId Service name of the record type the field type belongs to
         * @param {String} fieldTypeOptions.fieldTypeId Service name of the field type
         * @param {Boolean} [fieldTypeOptions.exclusiveObjects] Whether to filter values based on exclusive objects for the record type
         * @param {Object} node Branch node to get the children from
         * @param {String} node.id Service name of the lookup value the node represents
         * @param {Boolean} showExpired  Condition to show expired lookup values
         * @param {Boolean} applyLkpSec Condition to apply lookup security
         * @returns {Object} A promise to be resolved with the children or rejected with an error
         */
        function getChildren(libraryId, fieldTypeOptions, node, showExpired, applyLkpSec) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    LookupInterface.getImmediateChildrenForLookup(
                        clientId,
                        libraryId,
                        node.id,
                        _.get(fieldTypeOptions, 'recordTypeId') || null,
                        _.get(fieldTypeOptions, 'fieldTypeId') || null,
                        _.get(fieldTypeOptions, 'exclusiveObjects') || false,
                        showExpired || false,
                        applyLkpSec || false,
                        function success(response) {
                            deferred.resolve(response);
                        },
                        function error(response) {
                            deferred.reject(response.data.errorMessage);
                        }
                    );
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        /**
         * Provides a flat list of lookup values filtered by a string.
         *
         * @method filterTree
         *
         * @param {String} libraryId Service name of the lookup library to get the values from
         * @param {Object} [fieldTypeOptions] Field type options to consider for security, exclusive objects, etc.
         * @param {String} fieldTypeOptions.recordTypeId Service name of the record type the field type belongs to
         * @param {String} fieldTypeOptions.fieldTypeId Service name of the field type
         * @param {Boolean} [fieldTypeOptions.exclusiveObjects] Whether to filter values based on exclusive objects for the record type
         * @param {String} filter Filter to be applied to the values
         * @param {Boolean} showExpired  Condition to show expired lookup values
         * @param {Boolean} applyLkpSec Condition to apply lookup security
         * @returns {Object} A promise to be resolved with the matching values or rejected with an error
         */
        function filterTree(libraryId, fieldTypeOptions, filter, showExpired,applyLkpSec) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    LookupInterface.searchLookupInLibrary(
                        clientId,
                        libraryId,
                        _.get(fieldTypeOptions, 'recordTypeId') || null,
                        _.get(fieldTypeOptions, 'fieldTypeId') || null,
                        _.get(fieldTypeOptions, 'exclusiveObjects') || false,
                        showExpired || false,
                        applyLkpSec || false,
                        filter,
                        function success(response) {
                            deferred.resolve(response);
                        },
                        function error(response) {
                            deferred.reject(response.data.errorMessage);
                        }
                    );
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function getLookupValues(lookupLibraryId, lookupValueId) {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function success(clientId) {
                    LookupInterface.getLookupValuesFromCache(
                        clientId,
                        lookupLibraryId,
                        lookupValueId,
                        false,
                        null,
                        true,
                        function success(response) {
                            deferred.resolve(response);
                        },
                        function error(response) {
                            deferred.reject(response.data.errorMessage);
                        }
                    );
                })
                .catch(function(reason){
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
    }
})();
