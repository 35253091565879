(function () {
    'use strict';

    angular
        .module('alpha.common.alphaSmartTable', [
            'smart-table',
            'ui.bootstrap',
            'alpha.translations',
            'alpha.utils.I18n',
            'alpha.utils.Events',
            'alpha.common.directives.alphaToggleSlider',
            'alpha.directives.ventivSelectValue',
            'alpha.utils.recordData',
            'alpha.common.services.authentication',
            'alpha.common.directives.alphaFileUpload',
            'alpha.directives.aonLookup',
            'alpha.common.alphaLookupTree',
            'alpha.common.directives.alphaNumberInput'
        ])
        .config(config);

    config.$inject = ['stConfig'];

    function config(stConfig) {
        stConfig.sort.skipNatural = true;
        stConfig.sort.delay = 0;
    }
})();
