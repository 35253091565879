(function() {
    'use strict';
    angular
        .module('alpha.login.Login', [
            'ngCookies',
            'ui.bootstrap',
            'AlphaApi',
            'UserPreferences',
            'alpha.directives.messageHolder',
            'alpha.translations',
            'alpha.utils.Loader',
            'alpha.utils.Utils',
            'alpha.utils.I18n',
            'alpha.directives.ventivSelectValue',
            'alpha.common.services.alphaLocalStorage',
            'alpha.utils.Utils',
            'alpha.common.directives.alphaNumberInput',
            'alpha.directives.ventivRestrictedInput'
        ])
        .constant('LOGIN_PAGE_PATH', '/dynamic/login.jsp')
        .constant('SECURITY_QUESTION_PAGE_PATH', '/dynamic/password-management.jsp#/newUserPassword')
        .constant('COOKIE_ACCEPT_MAX_DAYS', 1825)
        .config(config);

    config.$inject = [
        '$compileProvider',
        '$locationProvider',
        '$qProvider'
    ];

    function config($compileProvider, $locationProvider, $qProvider) {
        $compileProvider.debugInfoEnabled(false);
        $compileProvider.commentDirectivesEnabled(false);
        $compileProvider.cssClassDirectivesEnabled(false);
        $locationProvider.hashPrefix('');
        $qProvider.errorOnUnhandledRejections(false);
    }
})();
