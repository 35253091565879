(function () {
    'use strict';

    angular
        .module('alpha.utils.I18n', [
            'ngCookies',
            'AlphaApi',
            'UserPreferences',
            'alpha.translations',
            'tmh.dynamicLocale'
        ])
        .config(config)
        .run(i18nInitializer)
        .factory('I18nUtil', I18nUtil);

    config.$inject = ['tmhDynamicLocaleProvider'];

    function config(tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern(applicationContextRoot + '/vendor/angular/1.7.8/angular-1.7.8/i18n/angular-locale_{{locale}}.js');
    }

    I18nUtil.$inject = [
        'DataModelDesignInterface',
        'I18NInterface',
        'UserPreferences',
        'tmhDynamicLocale',
        '$window',
        '$filter',
        '$locale',
        '$log',
        '$q'
    ];

    function I18nUtil(
        DataModelDesignInterface,
        I18NInterface,
        UserPreferences,
        tmhDynamicLocale,
        $window,
        $filter,
        $locale,
        $log,
        $q
    ) {
        var _applicationLanguageCode;

        return {
            getI18nString: getI18nString,
            setLocale: setLocale,
            getLocale: getLocale,
            setLanguage: setLanguage,
            getLanguage: getLanguage,
            getDisplayCharacters: getDisplayCharacters,
            getMomentFormat: getMomentFormat,
            getBrowserLocale: getBrowserLocale,
            getAllLanguages: getAllLanguages,
            getAllLocales: getAllLocales,
            getClientLanguages: getClientLanguages,
            getRequiredLanguages: getRequiredLanguages,
            isCommaDecimalSeparater:isCommaDecimalSeparater,
            sortByLocale:sortByLocale
        };
        /**
         * Translates a string using whatever labels have been loaded
         * for the angular-translate library.
         *
         * @method getI18nString
         *
         * @param {String} label Name of the label to translate
         * @param {String} defaultString Default en-US translation; used to generate the properties file
         * @param {Object} dynamicValues Dynamic values keyed by name, i.e. {someValue: 500} for the string "The value is {{someValue}}."
         *
         * @returns {String} The translation of the specified label
         */
        function getI18nString(label, defaultString, dynamicValues) {
            return $filter('translate')(label, dynamicValues);
        }
        /**
         * Sets the locale of the application and sets up the related
         * vendor libraries. This localizes the display of data such
         * as dates or numbers. It will not have any effect on which
         * language is displayed for multilingual content. It does not
         * need to match the application language.
         *
         * @method setLocale
         *
         * @param {String} locale New locale in the standard ISO format, i.e. en-US
         */
        function setLocale(locale) {
            var isoCode = locale.toLowerCase();
            if (isoCode !== $locale.id.toLowerCase()) {
                tmhDynamicLocale.set(isoCode)
                    .then(function() {
                        moment.locale(isoCode);
                        _enableLongYear();
                    })
                    .catch(function() {
                        $log.warn('The ' + locale + ' locale is not available.');
                    });
            } else {
                _enableLongYear();
            }
            function _enableLongYear() {
                $locale.DATETIME_FORMATS.short = $locale.DATETIME_FORMATS.short.replace(/y{2,4}/, 'yyyy');
                $locale.DATETIME_FORMATS.shortDate = $locale.DATETIME_FORMATS.shortDate.replace(/y{2,4}/, 'yyyy');
            }
        }
        function getLocale() {
            return $locale.id;
        }
        /**
         * Sets the language of the application. This controls which
         * language will be displayed when multilingual content is
         * available. It will not have any affect on localized APIs,
         * such as the translate library. It also will not affect the
         * format of data such as dates or numbers. This should only
         * be called with a language supported by the current client,
         * and it should always match the language on the back end. It
         * does not need to match the application locale.
         *
         * @method setLanguage
         *
         * @param {String} language New language in the proprietary application format, i.e. en_US
         */
        function setLanguage(language) {
            _applicationLanguageCode = language;
        }
        function getLanguage() {
            return _applicationLanguageCode;
        }
        /**
         * Provides a list of characters that may be included in the
         * display of a particular type of data given the current
         * locale. Can be used for masking user input. All characters
         * will be converted to lower case.
         *
         * Characters may fall outside the ASCII range and should not
         * be used in regular expressions until ES6 is implemented.
         *
         * @method getDisplayCharacters
         *
         * @param {String} typeOfData Type of data being displayed; not necessarily a field type's data type
         *
         * @returns {String[]} The characters which may be displayed in the field
         */
        function getDisplayCharacters(typeOfData) {
            var chars = '';
            switch(typeOfData) {
                case 'Number':
                    chars += '0123456789';
                    chars += $locale.NUMBER_FORMATS.DECIMAL_SEP;
                    chars += $locale.NUMBER_FORMATS.GROUP_SEP;
                    _.forEach($locale.NUMBER_FORMATS.PATTERNS, function(pattern) {
                        chars += pattern.negPre.replace('\u00a4', '');
                        chars += pattern.negSuf.replace('\u00a4', '');
                        chars += pattern.posPre.replace('\u00a4', '');
                        chars += pattern.posSuf.replace('\u00a4', '');
                    });
                    break;
                case 'DateTime':
                    chars += '0123456789';
                    chars += $locale.DATETIME_FORMATS.short.replace(/[a-zA-Z]/g, '');
                    if (_.includes($locale.DATETIME_FORMATS.short, 'a')) {
                        _.forEach($locale.DATETIME_FORMATS.AMPMS, function(ampm) {
                            chars += ampm;
                        });
                    }
                    break;
                case 'Date':
                    chars += '0123456789';
                    chars += $locale.DATETIME_FORMATS.shortDate.replace(/[a-zA-Z]/g, '');
                    break;
                case 'Time':
                    chars += '0123456789';
                    chars += $locale.DATETIME_FORMATS.shortTime.replace(/[a-zA-Z]/g, '');
                    if (_.includes($locale.DATETIME_FORMATS.short, 'a')) {
                        _.forEach($locale.DATETIME_FORMATS.AMPMS, function(ampm) {
                            chars += ampm;
                        });
                    }
                    break;
            }
            return _.uniq(chars.toLowerCase().split(''));
        }
        /**
         * Provides a date format to be used with Moment that matches
         * the definition for the current Angular locale.
         *
         * @method getMomentFormat
         *
         * @param {String} typeOfData Type of data being displayed; not necessarily a field type's data type
         *
         * @returns {String} The format to be provided to Moment
         */
        function getMomentFormat(typeOfData) {
            switch(typeOfData) {
                case 'DateTime':
                    return _convert($locale.DATETIME_FORMATS.short);
                case 'Date':
                    return _convert($locale.DATETIME_FORMATS.shortDate);
                case 'Time':
                    return _convert($locale.DATETIME_FORMATS.shortTime);
            }
            function _convert(format) {
                return format
                    .replace(/yyyy/g, 'YYYY')
                    .replace(/yy/g, 'YY')
                    .replace(/y/g, 'Y')
                    .replace(/LLLL/g, 'MMMM')
                    .replace(/dd/g, 'DD')
                    .replace(/d/g, 'D')
                    .replace(/EEEE/g, 'dddd')
                    .replace(/EEE/g, 'ddd')
                    .replace(/sss/g, 'SSS')
                    .replace(/a/g, 'A')
                    .replace(/Z/g, 'ZZ');
            }
        }
        /**
         * Provides the browser locale; this may be different from the application locale.
         *
         * @method getBrowserLocale
         *
         * @returns {String} The browser locale in the standard ISO format, i.e. en-US
         */
        function getBrowserLocale() {
            var deferred = $q.defer();
            I18NInterface.getBrowserLocaleFromCache(
                function success(response) {
                    deferred.resolve(response.browserLocale);
                },
                function error(response) {
                    deferred.reject(response.data.errorMessage);
                }
            );
            return deferred.promise;
        }
        /**
         * Provides a list of all languages for all clients.
         *
         * @method getAllLanguages
         *
         * @returns {Object} A promise to be resolved with the languages or rejected with an error
         */
        function getAllLanguages() {
            var deferred = $q.defer();
            DataModelDesignInterface.getAvailableLocales(
                function success(response) {
                    deferred.resolve(response.localeConfigurationList);
                },
                function error(response) {
                    deferred.reject(response.data.errorMessage);
                }
            );
            return deferred.promise;
        }
        /**
         * Provides a list of all locales supported by the application.
         *
         * @method getAllLocales
         *
         * @returns {Object} A promise to be resolved with the locales or rejected with an error
         */
        function getAllLocales() {
            var deferred = $q.defer();
            DataModelDesignInterface.getAvailableUiLocales(
                function success(response) {
                    deferred.resolve(response.uiLocaleList);
                },
                function error(response) {
                    deferred.reject(response.data.errorMessage);
                }
            );
            return deferred.promise;
        }
        /**
         * Provides a list of languages for the current client.
         *
         * @method getClientLanguages
         *
         * @returns {Object} A promise to be resolved with the languages or rejected with an error
         */
        function getClientLanguages() {
            var deferred = $q.defer();
            UserPreferences.getClientId()
                .then(function(clientId) {
                    DataModelDesignInterface.getClientFromCache(
                        clientId,
                        function success(response) {
                            deferred.resolve(response.client.availableLocales);
                        }, function error(response) {
                            deferred.reject(response.data.errorMessage);
                        });
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        /**
         * Returns a list of languages required by the client. Multilingual
         * descriptions should always include all of these languages.
         *
         * @method getRequiredLanguages
         *
         * @returns {String[]} List of language codes, i.e. en_US
         */
        function getRequiredLanguages() {
            return _.uniq(['en_US', _applicationLanguageCode]);
        }
        function isCommaDecimalSeparater() {
            return $locale.NUMBER_FORMATS.DECIMAL_SEP===',' && $locale.NUMBER_FORMATS.GROUP_SEP!=='.';
        }

        function sortByLocale(x,y,ascendingOrder) {
            return ascendingOrder ? x.localeCompare(y,_.replace(getLanguage(), '_', '-')) : y.localeCompare(x,_.replace(getLanguage(), '_', '-'));
        }
    }

    /**
     * This initializer sets up the application for internationalization of
     * date and time formats, translations of static text, etc. It will run
     * automatically when the I18nUtil's module is included.
     */

    i18nInitializer.$inject = ['I18nUtil', 'UserPreferences', '$cookies'];

    function i18nInitializer(I18nUtil, UserPreferences, $cookies) {
        _initialize(); // Ensures application is never running without a language
        UserPreferences.getCurrentPreferences()
            .then(function(prefs) {
                if (prefs && prefs.defaultUiLocale) {
                    _initialize(prefs.defaultUiLocale);
                } else {
                    I18nUtil.getBrowserLocale().then(_initialize);
                }
            });
        function _initialize(preferredLocale) {
            I18nUtil.setLocale(_.replace(preferredLocale, '_', '-') || 'en-US');
            I18nUtil.setLanguage($cookies.get('LOCALE') || _.replace(preferredLocale, '-', '_') || 'en_US');
        }
    }
})();
