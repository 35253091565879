(function () {
    'use strict';

    /**
     * Adds a row of fields below the header so that user can filter values of the grid.
     *
     * @method alphaSmartTableUserFilter
     *
     * @example
     *      HTML:
     *      <div alpha-smart-table-user-filter
     *          columns="tableColumns"></div>
     *
     * @param {Array} columns The column array configuration for the smart table
     */


    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableUserFilter', alphaSmartTableUserFilter);

    alphaSmartTableUserFilter.$inject = [
        'Events',
        'AuthenticationService',
        '$uibModal',
        'AlphaSmartTableService',
        'SavedSearchService',
        '$timeout'];

    function alphaSmartTableUserFilter(Events, AuthenticationService, $uibModal, AlphaSmartTableService, SavedSearchService, $timeout) {
        return {
            link: link,
            templateUrl: applicationContextRoot + '/static/custom/structuredSearch/partial/directives/userFilter.directive.html',
            controller: ['$scope', '$element', '$attrs','SavedSearchService', controller],
            controllerAs: 'userFilterCtrl',
            bindToController: true,
            restrict: 'AE',
            scope: true,
            transclude: true
        };

        function link($scope, element, attrs) {
            $scope.containerId = attrs.id;
            $scope.filterOptionSelectorUrl = applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/popovers/userFilterOperatorDialog.html';
            $scope.betweenSelectorUrl = applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/popovers/userFilterBetweenValueDialog.html';
            $scope.multiSelectionUrl = applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/popovers/userFilterMultiSelectionDialog.html';
            $scope.$watch(attrs.columns, function (newVal) {
                var _columns = _.isArray(newVal) ? _.map(newVal, function(column) {
                    column.filterOperatorOpen = false;
                    column.filterValueOpen = false;
                    return column;
                }) : newVal;
                $scope.columns = _columns;
                initUserFilters();
            });

            $scope.$watch(attrs.queryId, function (newVal) {
                $scope.queryId = newVal;
                initUserFilters();
            });

            $scope.$watch(attrs.showUserFilter, function (newVal){
                $scope.showUserFilter = newVal;
            });

            function initUserFilters() {
                if($scope.columns == undefined ||  $scope.queryId == undefined){
                    return;
                }
                var searchQuery;
                $scope.options = {};
                $scope.filters = {};
                $scope.includeExpiredValueFilter = {}
                $scope.currentlyAppliedFilterQuery = SavedSearchService.getUpdatedQuery();
                $scope.termFilters = SavedSearchService.getTermFilters();

                if (_.isEmpty(AlphaSmartTableService.getMasterFilter($scope.queryId))) {
                    AlphaSmartTableService.getSavedSearch($scope.queryId)
                        .then(function (savedSearch) {
                            searchQuery = savedSearch;
                            $scope.currentQuery = savedSearch;
                            return AlphaSmartTableService.getRecordTypesForQuery(searchQuery);
                        })
                        .then(function (recordTypes) {
                            _.forEach(getVisibleColumns(), function (column) {
                                var name = column.name;
                                var fieldType = _.find(recordTypes[column.recordTypeId].allFields, {id: column.fieldTypeId});
                                var filter = {
                                    _fieldType: fieldType,
                                    operator: SavedSearchService.getSelectDefaultOperator(fieldType),
                                    name: name
                                };
                                $scope.filters[name] = filter;
                                if(fieldType.dataType === "LookupLibrary" && $scope.currentQuery.queryType === "QuickSearch"){
                                    $scope.includeExpiredValueFilter[name] = _setUpFilters($scope.currentQuery, filter);
                                }
                            });
                            AlphaSmartTableService.setMasterFilter($scope.filters, $scope.queryId);
                        });
                }else {
                    $scope.filters = AlphaSmartTableService.getMasterFilter($scope.queryId);
                }
            }

            function _setUpFilters(searchQuery, filter) {
                var includeExpiredValueFilters = {};

                if($scope.termFilters){
                    _.forEach($scope.termFilters, function (term) {
                        if(_.isEqual(filter.operator.id,term.operator.id) && _.isEqual(filter._fieldType.id,term._fieldType.id) || (ruleHasLevelOperator(term.operator) && (term.operator.id === "SPECIFIC_LEVEL" || term.operator.id === "EXCLUDING_LEVEL"))){
                            if (term.includeExpiredValue !== undefined) {
                                if (!includeExpiredValueFilters) {
                                    includeExpiredValueFilters = {};
                                }
                                angular.extend(includeExpiredValueFilters, {
                                    includeExpiredValue: term.includeExpiredValue
                                });
                            }
                        }
                    });
                }else {
                    _.forEach($scope.currentQuery.terms, function (term) {
                        if(_.isEqual(filter.operator.id,term.operator) && _.isEqual(filter._fieldType.id,term.field.fieldName) || (term.operator === "SPECIFIC_LEVEL" || term.operator === "EXCLUDING_LEVEL")){
                            if (term.includeExpiredValue !== undefined) {
                                if (!includeExpiredValueFilters) {
                                    includeExpiredValueFilters = {};
                                }
                                angular.extend(includeExpiredValueFilters, {
                                    includeExpiredValue: term.includeExpiredValue
                                });
                            }
                        }
                    });
                }
                return includeExpiredValueFilters;
            }

            function ruleHasLevelOperator(operator) {
                return operator ?  _.includes(['SPECIFIC_LEVEL','EXCLUDING_LEVEL'], operator.id) : false;
            }

            function getVisibleColumns() {
                return _.filter($scope.columns, 'visible');
            }
        }

        function controller($scope, $element, $attributes, SavedSearchService) {
            var self = this;
            self.$onInit = function () {
                controllerInit(self, $scope, SavedSearchService);
            };
        }

        function controllerInit(vm, $scope, SavedSearchService) {
            vm.getColumns = getColumns;
            vm.createOperatorDialog = createOperatorDialog;
            vm.operatorHasRange = operatorHasRange;
            vm.operatorHasValue = operatorHasValue;
            vm.isOperatorMultiple = isOperatorMultiple;
            vm.ruleHasLevelOperator = ruleHasLevelOperator;
            vm.operatorHasLiteralValue = operatorHasLiteralValue;
            vm.applyMasterFilter = applyMasterFilter;
            vm.currentMasterFilters = currentMasterFilters;
            vm.getOperatorOptions = getOperatorOptions;
            vm.updateFilterOperator = updateFilterOperator;
            vm.setDefaultValueForFilter = setDefaultValueForFilter;
            vm.closeUserFilterBetweenPopup = closeUserFilterBetweenPopup;
            vm.closeMultiSelectPopup = closeMultiSelectPopup;
            vm.applyMultiSelectValues = applyMultiSelectValues;
            vm.isFilterableColumn = isFilterableColumn;
            vm.closeFilterOperator = closeFilterOperator;
            vm.getMultiSelectDisplayText = getMultiSelectDisplayText;
            vm.showMultiSelectionPopup = showMultiSelectionPopup;
            vm.clearMultiSelectValues = clearMultiSelectValues;
            vm.openedOperatorColumnIndex = 0;
            vm.showFilterRow = false;
            vm.clearBetweenOperatorValues = clearBetweenOperatorValues;
            vm.triggerBlurEventOnEnterOnBetweenOperatorValues = triggerBlurEventOnEnterOnBetweenOperatorValues;
            vm.checkAndClosePopup = checkAndClosePopup;
            vm.getBetweenOperatorValues = getBetweenOperatorValues;

            $scope.$watch('filters', function (newValue, oldValue) {
                vm.filters = $scope.filters;
            });

            $scope.$watch('includeExpiredValueFilter', function (newValue, oldValue) {
                vm.includeExpiredValueFilter = $scope.includeExpiredValueFilter;
            });

            $scope.$on('FilterOperator', function (event, data){
                vm.filters[data.columnName].operator = data.filter.operator;
                if (!operatorHasValue(data.filter.operator)) {
                    applyMasterFilter();
                } else if (operatorHasRange(data.filter.operator)) {
                    if (vm.filters[data.columnName].value !=null && vm.filters[data.columnName].maxValue  !=null) {
                        applyMasterFilter();
                    }
                } else if(isOperatorMultiple(data.filter.operator)){
                    if(vm.filters[data.columnName].value && Array.isArray(vm.filters[data.columnName].value)){
                        applyMasterFilter();
                    }
                } else if (vm.filters[data.columnName].value && !Array.isArray(vm.filters[data.columnName].value)) {
                    applyMasterFilter();
                }
            });

            $scope.$watch('showUserFilter', function (newValue, oldValue) {
                vm.showFilterRow = $scope.showUserFilter;
                vm.queryName = $scope.queryId;
            });


            Events.subscribe('', $scope, 'userFilterController', ['ShowInGridFilter'], function(data) {
                if(($scope.queryId === data.queryName) && (_.isEmpty(vm.filters) && !_.isEmpty(data.filters))){
                    vm.filters = angular.copy(data.filters);
                }
                if($scope.queryId === undefined){
                    vm.queryName = data.queryName;
                    vm.showFilterRow = !vm.showFilterRow;
                }
                else if($scope.queryId === data.queryName){ //This condition ensures that each dashboard do not interfere with others.
                    vm.queryName = data.queryName;
                    vm.showFilterRow = !vm.showFilterRow;
                }
            });

            Events.subscribe('', $scope, 'userFilterController', ['HideInGridFilter'], function(data) {
                if($scope.queryId === data.queryName){
                    vm.showFilterRow = false;
                }
            });

            function getColumns() {
                return $scope.columns;
            }

            function getOperatorOptions(fieldType) {
                return SavedSearchService.getOperatorOptions(fieldType);
            }

            function setDefaultValueForFilter(filter) {
                if (filter) {
                    if (!operatorHasValue(filter.operator) || (filter._fieldType.lookupType === 'Tree' && (ruleHasLevelOperator(filter.operator) ||
                                                                ruleHasLevelOperator($scope.previousMasterFilters[filter.name].operator)))) {
                        if (_.has(filter, 'value')) {
                            delete filter.value;
                        }
                        if (_.has(filter, 'maxValue')) {
                            delete filter.maxValue;
                        }
                    } else if ((filter._fieldType.lookupType === 'Tree' || filter._fieldType.lookupType === 'List')
                        && (SavedSearchService.operatorHasStringValue(filter.operator)
                        && !SavedSearchService.operatorHasStringValue($scope.previousMasterFilters[filter.name].operator))) {
                        if (_.has(filter, 'value')) {
                            delete filter.value;
                        }
                    } else if (!operatorHasRange(filter.operator)) {
                        if (_.has(filter, 'maxValue')) {
                            delete filter.maxValue;
                        }
                    } else if(!isOperatorMultiple(filter.operator)){ //Switching from IN or NOT IN operator to any other.
                        if(Array.isArray(filter.value)){
                            delete filter.value;
                        }
                    } else if(isOperatorMultiple(filter.operator)){ //Switching to IN or NOT IN operator from any other.
                        if(!Array.isArray(filter.value)){
                            delete filter.value;
                        }
                    }
                }
            }

            function updateFilterOperator(columnName){
                vm.setDefaultValueForFilter(vm.filters[columnName]);
                $scope.$root.$broadcast('FilterOperator', {columnName: columnName, filter: vm.filters[columnName]});
                $scope.columns[vm.openedOperatorColumnIndex].filterOperatorOpen = false;
                if (operatorHasRange(vm.filters[columnName].operator) || isOperatorMultiple(vm.filters[columnName].operator)) {
                    $scope.columns[vm.openedOperatorColumnIndex].filterValueOpen = true;
                } else {
                    $scope.columns[vm.openedOperatorColumnIndex].filterValueOpen = false;
                }
            }

            function createOperatorDialog(columnIndex, columnName) {
                var queryName = vm.queryName;
                vm.openedOperatorColumnIndex = columnIndex;
                vm.columnName = columnName;
                _.forEach($scope.columns, function(column, index) {
                    if (columnIndex !== index) {
                        column.filterOperatorOpen = false;
                    }
                    column.filterValueOpen = false;
                });
                _showOperatorDialog(queryName, $scope.filters);
            }

            function operatorHasRange(operator) {
                return SavedSearchService.operatorHasRange(operator);
            }

            function operatorHasValue(operator) {
                var hasVal = SavedSearchService.operatorHasValue(operator);
                return hasVal;
            }

            function isOperatorMultiple(operator) {
                return SavedSearchService.isOperatorMultiple(operator);
            }

            function ruleHasLevelOperator(operator) {
                return SavedSearchService.ruleHasLevelOperator(operator);
            }

            function operatorHasLiteralValue(operator) {
                return SavedSearchService.operatorHasLiteralValue(operator);
            }

            function getFirstThirtyVisibleColumns() {
                var filterableColumnsArr = _.filter($scope.columns, function (column) {
                    return column.fieldMetadata && _.includes(['String', 'LargeTextField', 'LookupLibrary', 'Number', 'SummarizedNumber', 'Currency', 'CurrencyType', 'SummarizedCurrency', 'Attachment', 'Date', 'Location', 'LargeText'], column.fieldMetadata.dataType);
                })
                return filterableColumnsArr.slice(0,30);
            }

            function applyMasterFilter() {
                var filters = [];
                var sortOrder = [];
                var searchColumnsList = getFirstThirtyVisibleColumns();
                _.forOwn(vm.filters, function (filter, columnName) {
                        var column = _.find(searchColumnsList, {name: columnName});
                        var value = filter.value;
                        var entityArray = [];
                        if (column != undefined) {
                            if (column.fieldTypeId === '_entity_' && !_.isEmpty(value)) {
                                if (filter.operator && (filter.operator.id === 'IN' || filter.operator.id === 'NOT_IN')) {
                                    _.forEach(value, function (val) {
                                        entityArray.push(val.id);
                                    });
                                } else {
                                    entityArray.push(value.id);
                                }
                            }
                            var term = SavedSearchService.getTermFromFilterWithIncludeOptions(filter, column, entityArray, false, true);
                            if(term) {
                                filters.push(term);
                            }

                            if(column.sortDirection!=null){
                                sortOrder.push(column);
                            }
                        }
                    }
                );
                vm.currentMasterFilters(vm.filters, vm.queryName);
                var changes = {terms: filters, queryName: vm.queryName, sortOrder: sortOrder}
                _masterCallback(changes);
            }

            function checkAndClosePopup(column){
                var columnIndex = vm.openedOperatorColumnIndex;
                $timeout(function(){
                    if(column.filterValueOpen === true && $scope.filters[column.name].maxValue  !=null && !isNaN($scope.filters[column.name].maxValue)
                        && $scope.filters[column.name].value  !=null && !isNaN($scope.filters[column.name].value)){
                        $scope.columns[columnIndex].filterValueOpen = false;
                    }
                }, 100);
            }

            function closeUserFilterBetweenPopup(columnName) {
                if ($scope.columns[vm.openedOperatorColumnIndex].filterValueOpen) {
                    var applyFilter;
                    if ($scope.filters[columnName]._fieldType.dataType === 'Date') {
                        applyFilter = $scope.filters[columnName].value && $scope.filters[columnName].maxValue;
                    } else {
                        applyFilter = $scope.filters[columnName].value != null && !isNaN($scope.filters[columnName].value) && $scope.filters[columnName].maxValue != null && !isNaN($scope.filters[columnName].maxValue);
                    }

                    if (applyFilter) {
                        $scope.columns[vm.openedOperatorColumnIndex].filterValueOpen = false;
                        $scope.columns[vm.openedOperatorColumnIndex].filterOperatorOpen = false;
                        applyMasterFilter();
                    }
                }
            }

            function showMultiSelectionPopup(column){
                $scope.savedMultiSelectValue = $scope.filters[column.name].value;
                column.filterValueOpen = !column.filterValueOpen;
            }

            function clearMultiSelectValues(columnName){
                $scope.savedMultiSelectValue = '';
                $scope.filters[columnName].value = null;
                applyMasterFilter();
            }

            function closeMultiSelectPopup() {
                $scope.columns[vm.openedOperatorColumnIndex].filterValueOpen = false;
                $scope.columns[vm.openedOperatorColumnIndex].filterOperatorOpen = false;
                $scope.filters[$scope.columns[vm.openedOperatorColumnIndex].name].value = $scope.savedMultiSelectValue; //Restore the previous value.
            }

            function applyMultiSelectValues(){
                $scope.columns[vm.openedOperatorColumnIndex].filterValueOpen = false;
                $scope.columns[vm.openedOperatorColumnIndex].filterOperatorOpen = false;
                applyMasterFilter();
            }

            function clearBetweenOperatorValues(columnName) {
                $scope.filters[columnName].value = null;
                $scope.filters[columnName].maxValue = null;
                $scope.filters[columnName].displayValue = null;
                $scope.filters[columnName].maxDisplayValue = null;
                applyMasterFilter();
            }

            function getBetweenOperatorValues(columnName, dataType) {
                if (dataType === 'Date') {
                    return $scope.filters[columnName].value && $scope.filters[columnName].maxValue ? ($scope.filters[columnName].displayValue)  + '-' + $scope.filters[columnName].maxDisplayValue: '';
                } else {
                    return $scope.filters[columnName].value != null && !isNaN($scope.filters[columnName].value) && $scope.filters[columnName].maxValue != null && !isNaN($scope.filters[columnName].maxValue) ?
                        $scope.filters[columnName].value + '-' + $scope.filters[columnName].maxValue : '';
                }
            }

            function getMultiSelectDisplayText(valueArray){
                if(valueArray && Array.isArray(valueArray)) {
                    var displayValues = valueArray.map(function (element) {
                        return element.value;
                    });
                    return displayValues.join(', ');
                }
                return '';
            }

            function triggerBlurEventOnEnterOnBetweenOperatorValues(event) {
                if (event.keyCode === 13) {
                    event.target.blur()
                }
            }

            function _masterCallback(changes) {
                if(changes && changes.terms) {
                    AlphaSmartTableService.setMasterFilterApplied(changes && changes.terms && changes.terms.length > 0, changes.queryName);
                    AlphaSmartTableService.setMasterSearchFilter(changes, $scope.queryId);
                    Events.publish('alphaSmartTableHeaderDirective', 'refreshResults', {queryId: $scope.queryId});
                }
            }

            function currentMasterFilters(filters, queryName){
                AlphaSmartTableService.setMasterFilter(filters, queryName);
                $scope.previousMasterFilters = angular.copy(filters);
            }

            function _showOperatorDialog(queryName, filters) {
                $scope.queryName = queryName;
                $scope.column = _.find($scope.columns, function (column) {
                    return column.name === vm.columnName;
                });
                $scope.currentMasterFilters = setCurrentMasterFilter;
            }

            function setCurrentMasterFilter(filters, queryName) {
                AlphaSmartTableService.setMasterFilter(filters, queryName);
                $scope.previousMasterFilters = angular.copy(filters);
            }

            function isFilterableColumn(column){
                return column.fieldMetadata && _.includes(['String', 'LargeTextField', 'LookupLibrary', 'Number', 'SummarizedNumber', 'Currency', 'CurrencyType', 'SummarizedCurrency', 'Attachment', 'Date', 'Location','LargeText'], column.fieldMetadata.dataType) && !column.isNonFilterable;
            }

            function closeFilterOperator() {
                $scope.columns[vm.openedOperatorColumnIndex].filterOperatorOpen = false;
                $scope.columns[vm.openedOperatorColumnIndex].filterValueOpen = true;
            }
        }
    }
})();

