(function () {
    'use strict';

    /**
     * Display failed/successfully saved records confirmation message while saving editable grid records
     *
     * @method alphaSmartTableEditableGridConfirmMsg
     *
     * @example
     *      HTML:
     *      <alpha-smart-table-editable-grid-confirm-msg
     *          failed-grid-records="failedGridRecords"
     *          successfully-saved-grid-records="successfullySavedGridRecords"/>
     *
     * @param {String} failed-grid-records no of records failed while saving editing grid records
     * @param {String} successfully-saved-grid-records no of records successfully updated while saving editing grid records
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableEditableGridConfirmMsg', alphaSmartTableEditableGridConfirmMsg);

    alphaSmartTableEditableGridConfirmMsg.$inject = ['I18nUtil'];

    function alphaSmartTableEditableGridConfirmMsg(I18nUtil) {
        return {
            link: link,
            template: template,
            restrict: 'E',
            scope: {
                failedGridRecords : '=',
                successfullySavedGridRecords : '='
            }
        };

        function link(scope) {
            scope.getFailedRecordsMessage = getFailedRecordsMessage;
            scope.getSuccessfullySavedRecordsMessage = getSuccessfullySavedRecordsMessage;

            function getFailedRecordsMessage() {
                return I18nUtil.getI18nString('LBL_FAILED_GRID_RECORDS', '{{failedGridRecords}} record(s) need to be fixed before saving.',
                    { 'failedGridRecords' : scope.failedGridRecords });
            }

            function getSuccessfullySavedRecordsMessage() {
                return I18nUtil.getI18nString('LBL_UPDATED_GRID_RECORDS', '{{successfullySavedGridRecords}} record(s) successfully saved.',
                    { 'successfullySavedGridRecords' : scope.successfullySavedGridRecords });
            }
        }

        function template() {
            return '<div class="editable-grid-save-confirmation-msg">' +
                '    <span ng-if="failedGridRecords && failedGridRecords > 0" class="glyphicon glyphicon-info-sign"></span>' +
                '    <span ng-if="failedGridRecords && failedGridRecords > 0" class="failed-editable-grid-records" ng-bind="getFailedRecordsMessage()"></span>' +
                '    <span ng-if="successfullySavedGridRecords && successfullySavedGridRecords > 0" class="updated-editable-grid-records" ng-bind="getSuccessfullySavedRecordsMessage()"></span> ' +
                '</div>';
        }

    }
})();