(function () {
    'use strict';
    angular
        .module('alpha.common.services.alphaCommonBannerModal', ['alpha.utils.Events'])
        .factory('AlphaCommonBannerModalService', AlphaCommonBannerModalService);

    AlphaCommonBannerModalService.$inject=['$q', 'Events'];

    function AlphaCommonBannerModalService($q, Events){
        var _modalDeferred,
            _params;
        return {
            openConfirmationModal: openConfirmationModal,
            openAlertModal: openAlertModal,
            openNotificationModal: openNotificationModal,
            cancelModal: cancelModal,
            resolve: resolve,
            reject: reject
        };
        function openConfirmationModal(messageType, message, context) {
            _params = {
                type : 'confirmation',
                message: message,
                messageType: messageType,
                context: context,
                showButtons: true
            };
            _modalDeferred = $q.defer();
            Events.publish('BannerModal', 'open',
                _params);
            return _modalDeferred.promise;
        }
        function openAlertModal(messageType, message, context){
            _params = {
                type : 'alert',
                message: message,
                messageType: messageType,
                context: context,
                showButtons: true
            };
            _modalDeferred = $q.defer();
            Events.publish('BannerModal', 'open',
                _params);
            return _modalDeferred.promise;
        }
        function openNotificationModal(messageType, message, context){
            _params = {
                type : 'notification',
                message: message,
                messageType: messageType,
                context: context,
                showButtons: false,
                displayTime: 3000
            };
            _modalDeferred = $q.defer();
            Events.publish('BannerModal', 'open',
                _params);
            return _modalDeferred.promise;
        }
        function cancelModal() {
            Events.publish('BannerModal', 'cancel');
        }

        function resolve(data) {
            _modalDeferred.resolve(data);
        }

        function reject(reason) {
            _modalDeferred.reject(reason);
        }
    }
})();
