(function() {
    'use strict';

    /**
     * Displays a toggle slider.
     *
     * @method alphaToggleSlider
     *
     * @example
     *      HTML:
     *      <alpha-toggle-slider
     *          id="some_id"
     *          on-text="Enabled"
     *          off-text="Disabled"
     *          value="some_model_value"
     *          display-only="true"></alpha-toggle-slider>
     *
     * @param {Boolean} value Model to bind this widget to.
     * @param {String} on-text - string to show when toggle is true.  Defaults to 'Yes'
     * @param {String} off-text - string to show when toggle is false.  Defaults to 'No'
     * @param {Boolean} displayOnly If true, control will not do anything when clicked; use when special ng-click handling is needed.
     */

    angular
        .module('alpha.common.directives.alphaToggleSlider', [
            'alpha.utils.I18n'
        ])
        .directive('alphaToggleSlider', alphaToggleSlider);

    var template = '<button type="button" id="{{containerId}}_toggle" name="{{name}}" class="alpha-toggle-switch" ng-class="{\'on\': value}" ng-click="toggle($event)">' +
                       '<span class="label-on" ng-bind="onText" ng-show="viewMode.isDetailMode"></span>' +
                       '<span class="label-off" ng-bind="offText"  ng-show="!viewMode.isDetailMode"></span>' +
                       '<div class="handle"></div>' +
                    '</button>';

    alphaToggleSlider.$inject = [
        'I18nUtil'
    ];

    function alphaToggleSlider(I18nUtil) {
        return {
            link: link,
            restrict: 'E',
            require: '?ngModel',
            template: template,
            scope: {
                containerId: '@id',
                value: '=',
                displayOnly: '=',
                name:'@'
            }
        };
        function link(scope, element, attrs, ngModelCtrl) {
            scope.onText = attrs.onText || I18nUtil.getI18nString('LBL_YES', 'Yes');
            scope.offText = attrs.offText || I18nUtil.getI18nString('LBL_NO', 'No');
            scope.toggle = function($event) {
                if (!scope.displayOnly) {
                    scope.value = !scope.value;
                    if(_.isObject(ngModelCtrl)){
                        ngModelCtrl.$setViewValue(scope.value);
                    }
                    if (_.isObject($event)) {
                        $event.stopPropagation();
                    }
                }
            };
        }
    }
})();
