(function () {
    'use strict';

    /**
     * Allows the display text of a column to be modified in a Smart Table.
     *
     * @method alphaSmartTableEditable
     *
     * @example
     *      HTML:
     *      <div alpha-smart-table-editable
     *          display-name="displayName"></div>
     *
     * @param {String} display-name the displayName/description of the column
     */


    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableEditable', alphaSmartTableEditable);

    function alphaSmartTableEditable() {
        return {
            link: link,
            template: template,
            restrict: 'E',
            replace: true,
            transclude: true,
            scope: {
                displayName : '=',
                containerId: '@id'
            }
        };
        function link(scope) {
            var cachedDisplayName;
            scope.editing = false;
            scope.open = open;
            scope.cancel = cancel;
            scope.save = save;

            function open(){
                cachedDisplayName = scope.displayName;
                scope.editing = true;
            }
            function cancel(){
                scope.displayName = cachedDisplayName;
                scope.editing = false;
            }
            function save(){
                if(scope.columnEditForm.$valid){
                    scope.editing = false;
                }else{
                    scope.displayName = cachedDisplayName;
                }
            }
        }
        function template(){
            return  '<span class="alpha-smart-table-editable">' +
                    '   <span ng-hide="editing" class="editing">' +
                    '       <span id="{{containerId}}__display_name_span" class="heading sortable" ng-bind="displayName"></span>' +
                    '       <button id="{{containerId}}__edit_btn" class="alpha-button-reset edit-btn"><span class="glyphicon glyphicon-pencil" ng-click="$event.stopPropagation(); open()" uib-tooltip="{{\'BTN_EDIT\' | translate}}" tooltip-append-to-body="true"></span></button> ' +
                    '   </span>' +
                    '   <span ng-show="editing" ng-click="$event.stopPropagation();">' +
                    '       <form name="columnEditForm" ng-class="{\'has-error\': columnEditForm.displayName.$error.required}" novalidate>' +
                    '           <input id="{{containerId}}__display_name_input" class="form-control" name="displayName" required ng-model="displayName">' +
                    '           <button id="{{containerId}}__save_btn" class="alpha-button-reset edit-save-btn" ng-click="save()"><span class="glyphicon glyphicon-ok"></span></button>' +
                    '           <button id="{{containerId}}__cancel_btn" class="alpha-button-reset edit-cancel-btn" ng-click="cancel()"><span class="glyphicon glyphicon-remove"></span></button>' +
                    '       </form>' +
                    '   </span>' +
                    '</span>';
        }
    }
})();


