(function () {
    'use strict';
    angular.module('alpha.common.alphaSmartTable')
        .controller('AlphaSmartTableUploadController', AlphaSmartTableUploadController);

    AlphaSmartTableUploadController.$inject = ['$uibModalInstance', '$http', 'LoaderService', 'AlphaCommonBannerModalService', 'I18nUtil', '$q', 'UserPreferences','queryId'];

    function AlphaSmartTableUploadController($uibModalInstance, $http, LoaderService, AlphaCommonBannerModalService, I18nUtil, $q, UserPreferences, queryId){
        var vm = this;
        vm.cancel = cancel;
        vm.getUploadData = getUploadData;
        vm.uploadExcelReportFile = uploadExcelReportFile;
        vm.fileNotFound =false;

        function cancel(){
          $uibModalInstance.dismiss();
        }

        function getUploadData(formData,fileSize){
          vm.fileUploadData = {formData: formData, fileSize: fileSize};
        }
        function uploadExcelReportFile() {
            vm.fileNotFound = false;
            if (!vm.fileUploadData || (vm.fileUploadData&&vm.fileUploadData.formData.length==0)) {
                vm.fileNotFound = true;
            } else {
                if (_.isNumber(vm.fileUploadData.fileSize) && vm.fileUploadData.fileSize >= 2000000000){
                    AlphaCommonBannerModalService.openAlertModal('danger', I18nUtil.getI18nString('LBL_FILE_SIZE_EXCEEDED', 'The file can not be larger than 2 GB.'));
                } else {
                    LoaderService.startLoading();
                    UserPreferences.getClientId()
                        .then(function (clientId){
                            $http.post(applicationContextRoot + '/dp/upload/excelTemplate/'+clientId+'/'+queryId, vm.fileUploadData.formData, {
                                transformRequest: angular.identity,
                                headers: {'Content-Type': undefined}
                            }).then(function (response) {
                                AlphaCommonBannerModalService.openAlertModal('success',I18nUtil.getI18nString('MSG_EXCEL_TEMPLATE_UPLOADED', 'Excel template has been uploaded'));
                                if (response) {
                                    $uibModalInstance.close(response.data.excelFilename);
                                }

                            }).catch(function (reason) {
                                var message = _.isObject(reason) && _.has(reason.data, 'errorMessage') ? reason.data.errorMessage : I18nUtil.getI18nString('MSG_EXCEL_TEMPLATE_UPLOAD_ERROR', 'Excel template failed to upload.')
                                AlphaCommonBannerModalService.openAlertModal('danger', message);
                                $uibModalInstance.dismiss();
                            }).finally(function () {
                                LoaderService.stopLoading();
                            });
                        })
                }
            }
        }
    }
})();