(function () {
    angular
        .module('alpha.common.directives.alphaFieldHistory', [
            'ngSanitize',
            'AlphaApi',
            'UserPreferences',
            'alpha.utils.I18n',
            'alpha.utils.Loader',
            'alpha.utils.recordData',
            'alpha.utils.Modals',
            'alpha.translations',
            'alpha.common.alphaSmartTable',
            'alpha.common.directives.alphaBlocked',
            'alpha.common.services.authentication',
            'alpha.common.services.security',
            'ViewRecord.services.viewRecord'
        ])
        .directive('alphaFieldHistory', alphaFieldHistory);

    alphaFieldHistory.$inject = [
        'DataProviderInterface',
        'UserPreferences',
        'I18nUtil',
        'RecordDataUtil',
        'SecurityService',
        'AuthenticationService',
        'ViewRecordService',
        '$log',
        '$q',
        'LoaderService',
        'ModalService'
    ];

    function alphaFieldHistory(
        DataProviderInterface,
        UserPreferences,
        I18nUtil,
        RecordDataUtil,
        SecurityService,
        AuthenticationService,
        ViewRecordService,
        $log,
        $q,
        LoaderService,
        ModalService
    ){
        return {
            restrict: 'A',
            link: link,
            template : template,
            scope: {
                recordId: '=',
                recordMetadata: '='
            },
            transclude: true
        };
        function link(scope, element, attrs){
            var fieldHistory,
                fieldMetadata = scope.$eval(attrs.fieldMetadata),
                columns = [{
                    id: 'edited_date',
                    name: 'editedDate',
                    displayName: I18nUtil.getI18nString('LBL_DATE', 'Date'),
                    visible: true,
                    width: '10%',
                    getter: _getEditedDateFromRow,
                    sorter: _getSortableEditedDateFromRow
                },{
                    id:'tpa_source',
                    name: 'updatedByUser',
                    displayName: I18nUtil.getI18nString('LBL_SOURCE', 'Source'),
                    visible: true,
                    width: '29%'
                },{
                    id:'value',
                    name: 'value',
                    displayName: I18nUtil.getI18nString('LBL_VALUE', 'Value'),
                    template: 'fieldValue',
                    visible: true,
                    width: '61%',
                    getter: _getValueFromRow,
                    sorter: _getSortableValueFromRow
                }];

            if(fieldMetadata && fieldMetadata.showFullDataHistory){
                columns = [{
                    id: 'edited_date',
                    name: 'editedDate',
                    displayName: I18nUtil.getI18nString('LBL_DATE', 'Date'),
                    visible: true,
                    width: '10%',
                    getter: _getEditedDateFromRow,
                    sorter: _getSortableEditedDateFromRow
                },{
                    id:'tpa_source',
                    name: 'updatedByUser',
                    displayName: I18nUtil.getI18nString('LBL_SOURCE', 'Source'),
                    visible: true,
                    width: '28%'
                },{
                    id:'truncateData',
                    name: 'truncateData',
                    displayName: I18nUtil.getI18nString('LBL_TRUNCATE_DATA', 'Truncate Data'),
                    template: 'seeFullData',
                    visible: true,
                    width: '13%'

                },{
                    id:'value',
                    name: 'value',
                    displayName: I18nUtil.getI18nString('LBL_VALUE', 'Value'),
                    template: 'fieldValue',
                    visible: true,
                    width: '49%',
                    getter: _getValueFromRow,
                    sorter: _getSortableValueFromRow
                }];
            }
            scope.containerId = attrs.id;
            scope.openFieldHistory = openFieldHistory;
            scope.fieldHistoryCanBeRead = fieldHistoryCanBeRead;

            scope.showFullData = false;
            function setLeftPosition(event) {
                var windowWidth = window.screen.width;
                var element = event.currentTarget;
                var elementRect = element.getBoundingClientRect();
                var windowHeight = window.innerHeight;
                var popoverHeight = 200;

                var topPosition;
                topPosition = -(windowWidth - popoverHeight) / 100 ;

                setLeftPositionValue(topPosition);
            }
            var _value;
            function setLeftPositionValue(value) {
                _value = value;
            }

            function getLeftPositionValue() {
                return _value;
            }
        function setWidthPosition(){
            var windowWidth = window.screen.width;
                return   windowWidth > 1300 ? 83 : 73;
        }


            function openFieldHistory(){
                UserPreferences.getClientId()
                    .then(function(clientId){
                        DataProviderInterface.getFieldHistory(
                            clientId,
                            attrs.recordType,
                            scope.recordId,
                            attrs.fieldType,
                            function success(data){
                                fieldHistory = data;
                                setColumnVisibility();
                                openHistoryModal();
                            },
                            function error(reason){}
                        );
                    });
                function openHistoryModal(){
                    var options = {
                        animation: false,
                        windowClass: 'alpha-field-history',
                        templateUrl: applicationContextRoot + '/static/custom/common/app/partials/modals/alphaFieldHistory.html',
                        controller: ['$scope', '$uibModalInstance', function($scope, $uibModalInstance) {
                            $scope.isRightAligned = RecordDataUtil.isRightAligned(fieldMetadata.dataType);
                            $scope.containerId = scope.containerId;


                            $scope.columns = columns;
                            $scope.fieldHistory = fieldHistory;
                            $scope.ok = function () {
                                $uibModalInstance.dismiss();
                            };
                            $scope.seeFullData = seeFullData ;
                            $scope.setLeftPosition = setLeftPosition ;
                            $scope.setWidthPosition = setWidthPosition;
                            $scope.getLeftPositionValue = getLeftPositionValue;
                        }]
                    };
                    AuthenticationService.openAuthenticatedModal(options);
                }

                //Function added to hide 'Value' columns for internal fields,ex. Last Updated by user ID
                function setColumnVisibility(){
                    if(fieldHistory && fieldHistory[0]){
                        if(fieldHistory[0].hideColumn) {
                            _.forEach(columns, function (column,index) {
                                if (column.id === fieldHistory[0].hideColumn) {
                                    columns.splice(index, 1);
                                } else if(index===0){
                                    column.width='30%';
                                }else{
                                    column.width='70%';
                                }
                            });
                        }
                    }
                }
            }

            function seeFullData(transactionId) {
                var deferred = $q.defer();
                LoaderService.startLoading();
                getFieldHistoryFullData(transactionId)
                    .then(function(fullData) {
                        openFieldHistoryFullDataModal(fullData);
                        deferred.resolve();
                    })
                    .catch(function(reason) {
                        ModalService.openAlert(I18nUtil.getI18nString('MSG_ALERT', 'Alert'), reason);
                        deferred.reject();
                    })
                    .finally(LoaderService.stopLoading);
            }

            function openFieldHistoryFullDataModal(data) {
                var options = {
                    windowClass: 'modal-default',
                    templateUrl: applicationContextRoot + '/static/custom/common/app/partials/modals/alphaFieldHistoryFullData.html',
                    controller: ['$scope', '$uibModalInstance', OpenFieldHistoryFullDataController],
                    controllerAs: 'fieldHistoryFullData',
                    animation: false
                };
                return AuthenticationService.openAuthenticatedModal(options);

                function OpenFieldHistoryFullDataController($scope, $uibModalInstance) {
                    var vm = this;
                    $scope.historyData = RecordDataUtil.getDisplayValueForField(data.value, fieldMetadata,attrs.applyRedaction);
                    vm.ok = function () {
                        $uibModalInstance.close();
                    };
                }
            }

            function getFieldHistoryFullData(transactionId) {
                var deferred = $q.defer();
                UserPreferences.getClientId()
                    .then(function (clientId) {
                        DataProviderInterface.getFullDataFieldHistory(
                            clientId,
                            attrs.recordType,
                            attrs.fieldType,
                            transactionId,
                            function success(response) {
                                deferred.resolve(response)
                            },
                            function error(response) {
                                deferred.reject(response.data.errorMessage);
                            }
                        );
                    }).catch(function(reason) {
                    deferred.reject(reason);
                });
                return deferred.promise;
            }
            function fieldHistoryCanBeRead() {
                return _.isNumber(scope.recordId) && !ViewRecordService.changeRecordTypeModeEnabled() && SecurityService.fieldHistoryCanBeRead(null, attrs.fieldType, scope.recordMetadata);
            }
            function _getEditedDateFromRow(row) {
                return RecordDataUtil.getDisplayValue(row.editedDate, 'Date', 'Date');
            }
            function _getSortableEditedDateFromRow(row) {
                return RecordDataUtil.getSortableValue(row.editedDate, 'Date', 'Date');
            }
            function _getValueFromRow(row) {
                if (fieldMetadata.dataType === 'LookupLibrary') {
                    return row.value; // TODO: Real lookup values are not available
                } else {
                    return RecordDataUtil.getDisplayValueForField(row.value, fieldMetadata,attrs.applyRedaction);
                }
            }
            function _getSortableValueFromRow(row) {
                if (fieldMetadata.dataType === 'LookupLibrary') {
                    return row.value; // TODO: Real lookup values are not available
                } else {
                    return RecordDataUtil.getSortableValue(row.value, fieldMetadata.dataType, fieldMetadata.widgetType);
                }
            }
        }
        function template(element, attrs) {
          return    '<div ng-switch="fieldHistoryCanBeRead()">' +
                        '<a id="{{containerId}}__field_history" ' +
                            'class="alpha-button-reset alpha-field-history-btn" ' +
                            'ng-click="openFieldHistory()" ' +
                            'ng-switch-when="true"><ng-transclude></ng-transclude></a>' +
                        '<ng-transclude ng-switch-default></ng-transclude>' +
                    '</div>';
        }
    }
})();
