(function () {
    'use strict';
    /**
     * A directive for a generic tree with search capability.
     *
     * @method alphaSearchTree
     *
     * @example
     *      HTML:
     *      <alpha-search-tree
     *          id="searchTree"
     *          alpha-search-tree-data="treeObject"
     *          alpha-search-tree-node-id="name"
     *          alpha-search-tree-node-value="displayName"
     *          alpha-search-tree-is-dropdown="false"
     *          alpha-search-tree-placeholder="Please select value..."
     *          ng-model="selectedValue"></alpha-search-tree>
     *
     * @param {String} id Unique id of element
     * @param {Object} ng-model Model to bind this tree to
     * @param {Object} [alpha-search-tree-data] Object that contains the tree data
     * @param {String} [alpha-search-tree-node-id] The unique identification property to use for a tree node. Default is 'id'
     * @param {String} [alpha-search-tree-node-value] The property to use for a display value for a tree node. Default is 'value'
     * @param {Boolean} [alpha-search-tree-is-dropdown] A boolean on whether the tree is a dropdown or list box. Default is true
     * @param {String} [alpha-search-tree-placeholder] A placeholder when no value has been selected
     *
     */
    angular
        .module('alpha.common.directives.alphaSearchTree', [
            'alpha.utils.I18n'
        ])
        .directive('alphaSearchTree', alphaSearchTree);

    alphaSearchTree.$inject = [
        'I18nUtil',
        '$timeout'
    ];

    function alphaSearchTree(
        I18nUtil,
        $timeout
    ) {
        return {
            restrict: 'E',
            require: 'ngModel',
            templateUrl: applicationContextRoot + '/static/custom/common/app/partials/directives/alphaSearchTree.directive.html',
            scope: {
                data : '=alphaSearchTreeData',
                containerId : '@id'
            },
            link: link
        };

        function link(scope, element, attrs, ngModelCtrl){
            scope.selectNode = selectNode;
            scope.isNodeSelected = isNodeSelected;
            scope.filterTree = filterTree;
            scope.toggleNodeExpand = toggleNodeExpand;
            scope.initNode = initNode;
            scope.toggleTreeOpen = toggleTreeOpen;
            scope.hasChildren =  hasChildren;
            scope.clearSelection = clearSelection;
            scope.getSelectedValue = getSelectedValue;
            ngModelCtrl.$render = _render;
            scope.isDropdown = attrs.alphaSearchTreeIsDropdown ? scope.$eval(attrs.alphaSearchTreeIsDropdown) : true;
            scope.placeHolder = attrs.alphaSearchTreePlaceholder || I18nUtil.getI18nString('LBL_CHOOSE_VALUE', 'Choose a Value');
            scope.allowClear = attrs.alphaSearchTreeHideClearButton !== 'true';
            scope.nodeId =  attrs.alphaSearchTreeNodeId || 'id';
            scope.nodeValue = attrs.alphaSearchTreeNodeValue || 'value';
            scope.open = !scope.isDropdown;

            function selectNode(node) {
                ngModelCtrl.$setViewValue(node);
                ngModelCtrl.$render();
                if(scope.isDropdown){
                    toggleTreeOpen();
                }
            }
            function isNodeSelected(node){
                if(_.isObject(ngModelCtrl.$viewValue)){
                    return ngModelCtrl.$viewValue[scope.nodeId] === node[scope.nodeId] && ngModelCtrl.$viewValue[scope.nodeValue] === node[scope.nodeValue];
                }else if(!_.isEmpty(ngModelCtrl.$viewValue) && _.isString(ngModelCtrl.$viewValue)){
                    return ngModelCtrl.$viewValue === node[scope.nodeId];
                }else{
                    return false;
                }
            }
            function initNode(node){
                node.expanded = _.has(node, 'expanded') ? node.expanded : false;
            }
            function getSelectedValue(){
                return _.isObject(scope.selectedValue) ? scope.selectedValue[scope.nodeValue] : scope.selectedValue;
            }
            function toggleNodeExpand(node){
                node.expanded = !node.expanded;
            }
            function hasChildren(node){
                return _.has(node, 'children') && !_.isEmpty(node.children);
            }
            function clearSelection(){
                ngModelCtrl.$setViewValue(null);
                ngModelCtrl.$render();
            }
            function filterTree(searchText, noHighlight){
                if(searchText){
                    _searchNode(scope.data);
                }else{
                    _resetNodes(scope.data);
                }

                function _searchNode(nodes){
                    var found = false;
                    _.forEach(nodes, function(node){
                        _setNode(node);
                        _setNodeChildren(node);
                    });
                    return found;

                    function _setNode(node){
                        if(_nodeMatches(node)){
                            found = true;
                            node.matched = !noHighlight;
                        }else{
                            node.matched = false;
                        }
                    }
                    function _setNodeChildren(node){
                        if(node.children){
                            node.expanded = _searchNode(node.children);
                            if(node.expanded){
                                found = true;
                            }
                        }
                    }
                }
                function _resetNodes(nodes){
                    _.forEach(nodes, function(node){
                        node.expanded = false;
                        node.matched = false;
                        if(node.children){
                            _resetNodes(node.children);
                        }
                    });
                }
                function _nodeMatches(node){
                    return _.includes(_.toLower(node[scope.nodeValue]), _.toLower(searchText)) || _.includes(_.toLower(node[scope.nodeId]), _.toLower(searchText));
                }
            }
            function _findSelectedNode() {
                var selectedValue = _.isObject(scope.selectedValue) ? scope.selectedValue[scope.nodeValue] : scope.selectedValue;
                if (selectedValue) {
                    filterTree(selectedValue, true);
                    $timeout(function () {
                        var container = element.find('.tree-list-container'),
                            button = element.find('button.selected').closest('li');
                        if (button.length) {
                            container.scrollTop(button.offset().top - container.offset().top + container.scrollTop());
                        }
                    });
                }
            }
            function toggleTreeOpen(){
                scope.open = !scope.open;
                if(scope.open){
                    _findSelectedNode();
                    element.css({
                        'position': 'absolute',
                        'z-index': 9999,
                        'width': element.css('width')
                    });
                }else{
                    element.css({
                        'position': 'inherit',
                        'z-index': 'inherit'
                    });
                }
            }
            function _render(){
                scope.selectedValue = ngModelCtrl.$viewValue;
            }
            scope.$watch('data', function(value){
               if((!_.isEmpty(value) && scope.selectedValue)){
                   _findSelectedNode();
               }
            });
        }
    }
})();
