(function () {
    'use strict';

    /**
     * A directive that renders a tree component for a lookup library.
     *
     * @method alphaLookupTree
     *
     * @example
     *      HTML:
     *      <input id="FieldList"
     *          ng-model="someCtrl.modelValue"
     *          event-context="some_context"
     *          event-record-type="some_record_type"
     *          event-field-type="some_field_type"
     *          read-only-mode="true"
     *          alpha-lookup-tree
     *          alpha-lookup-library="RecordTypeFields"
     *          alpha-lookup-library-record-type="some_record_type"
     *          alpha-lookup-library-field-type="some_field_type"
     *          alpha-lookup-exclusive-objects="true"
     *          alpha-lookup-cache-library="false"
     *          alpha-lookup-multiple="true"
     *          alpha-lookup-placeholder="Please Select..."
     *          alpha-lookup-correlated-rec-type="recordTypeId"
     *          alpha-lookup-correlated-icon-id="iconId"
     *          alpha-lookup-scope-set-property="id"
     *          alpha-lookup-selection-formatter="formatterFunction">
     *
     * @param {String} id Unique ID of the element
     * @param {String} [event-context] Context for the field to listen to (rules, formulas, etc.)
     * @param {String} [event-record-type] Record type for the field to listen to (rules, formulas, etc.)
     * @param {String} [event-field-type] Field type for the field to listen to (rules, formulas, etc.)
     * @param {Boolean} [read-only-mode] Makes the input permanently read only even if a rule makes it writable.
     * @param {Object} ng-model Model to bind this component to
     * @param {Object} alpha-lookup-library ID of the Lookup Library that contains the data for this dropdown.
     * @param {String} [alpha-lookup-record-type] Record type to load the lookup library for
     * @param {String} [alpha-lookup-field-type] Field type to load the lookup library for
     * @param {Boolean} [alpha-lookup-exclusive-objects] Whether to filter values based on exclusive objects for the record type.
     * @param {Boolean} [alpha-lookup-cache-library] Whether the lookup library will be cached so it doesn't reload on every interaction. Defaults to true.
     * @param {String} [alpha-lookup-placeholder] Placeholder text when there is no value selected.
     * @param {String} [alpha-lookup-correlated-rec-type] The correlated recordtype if this is a correlated LL.
     * @param {String} [alpha-lookup-correlated-icon-id] The ID of the icon for the correlated recordtype.
     * @param {String} [alpha-lookup-scope-set-property] Name of or path to a property to pull from the lookup value and set to the model, i.e. "id" for lookups which need to be saved as strings.
     * @param {lookupValueFormatter} [alpha-lookup-selection-formatter] Formats lookup values for display in the selection; defaults to the value property
     *
     * @callback lookupValueFormatter
     *
     * @param {*} value The lookup value in the format of a model value
     * @param {String} lookupLibraryId Service name of the lookup library
     */

    angular
        .module('alpha.common.alphaLookupTree')
        .directive('alphaLookupTree', alphaLookupTree);

    alphaLookupTree.$inject = [
        'AlphaLookupTreeService',
        'UserPreferences',
        'AlphaTabManagerService',
        'I18nUtil',
        'RecordDataUtil',
        'RequiredFieldService',
        'RuleExecutionHelper',
        'LookupInterface',
        '$log',
        '$timeout',
        '$q',
        '$sce',
        '$document'
    ];

    function alphaLookupTree(
        AlphaLookupTreeService,
        UserPreferences,
        AlphaTabManagerService,
        I18nUtil,
        RecordDataUtil,
        RequiredFieldService,
        RuleExecutionHelper,
        LookupInterface,
        $log,
        $timeout,
        $q,
        $sce,
        $document
    ) {
        var POPUP_HEIGHT = 320;
        return {
            link: link,
            restrict: 'A',
            require: 'ngModel',
            templateUrl: applicationContextRoot + '/static/custom/common/alphaLookupTree/partials/alphaLookupTree.directive.html',
            scope: {
                containerId: '@id',
                recordCanBeRead: '&',
                alphaLookupSelectionFormatter: '=',
                scrollableContainer: '=?',
                forceScroll: '=?'
            }
        };

        function link(scope, element, attrs, ngModelCtrl){
            var correlatedRecordType = attrs.alphaLookupCorrelatedRecType,
                correlatedIcon = angular.element('#' + attrs.alphaLookupCorrelatedIconId),
                bypassAuthority = attrs.alphaLookupBypassAuthority ? scope.$eval(attrs.alphaLookupBypassAuthority) : false,
                onlyLowestLeafSelectable = attrs.onlyLowestLeafSelectable ? scope.$eval(attrs.onlyLowestLeafSelectable) : false,
                placeholderText = attrs.alphaLookupPlaceholder || I18nUtil.getI18nString('LBL_CHOOSE_VALUE', 'Choose a Value'),
                selectionFormatter = _.isFunction(scope.alphaLookupSelectionFormatter) ? scope.alphaLookupSelectionFormatter : _defaultSelectionFormatter,
                currentRecordMasterId = -1,
                readOnlyClass = 'readonly',
                _readOnlyMode,
                _readOnlyState;

            scope.tooltipUrl = applicationContextRoot + '/static/custom/common/alphaLookupTree/partials/alphaLookupTreeTooltip.html';
            scope.openRecordInTab = openRecordInTab;
            scope.selectNode = selectNode;
            scope.clearSelection = clearSelection;
            scope.isNodeSelected = isNodeSelected;
            scope.isAnyNodeSelected = isAnyNodeSelected;
            scope.filterTree = filterTree;
            scope.toggleNodeExpand = toggleNodeExpand;
            scope.toggleTreeOpen = toggleTreeOpen;
            scope.editingIsDisabled = editingIsDisabled;
            scope.initNode = initNode;
            scope.getDisabledStatus = getDisabledStatus;
            scope.searchFilterIsValid = searchFilterIsValid;
            scope.updateSearchFilter = updateSearchFilter;
            scope.removeTag = removeTag;
            scope.getFormattedSelection = getFormattedSelection;
            scope.getTooltipText = getTooltipText;
            scope.tooltipVal = null;
            scope.open = false;
            scope.searchFilter = {};
            scope.loadedToolTip = false;
            scope.loadToolTip = loadToolTip;
            scope.isTreeContainerPositionChanged=isTreeContainerPositionChanged;
            scope.multipleSelect = scope.$eval(attrs.alphaLookupMultiple);

            ngModelCtrl.$formatters.push(scope.multipleSelect? _multiFormatter : _singleFormatter);
            ngModelCtrl.$parsers.unshift(_parser);
            ngModelCtrl.$render = _render;

            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setReadOnly', 'setRequired'], attrs, _eventHandler);
            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    _readOnlyMode = true;
                    _enableReadOnly();
                } else {
                    _readOnlyMode = false;
                    if (!_readOnlyState) {
                        _disableReadOnly();
                    }
                }
                _updatePlaceHolderText();
            });
            correlatedIcon.on('click', openRecordInTab);
            if(scope.multipleSelect){
                $timeout(getFormattedSelection);
            }
            // Public methods

            function loadToolTip () {
                if (!scope.loadedToolTip) {
                    _loadToolTip();
                }
            }

            function isTreeContainerPositionChanged () {
                if(_.includes(scope.containerId,'_rule_') && _.includes(scope.containerId,'_value_')) {
                    return true;
                }
            }

            function getTooltipText(node) {
                return $sce.trustAsHtml('<p class="text-left">' + _getTooltipView(node.pathToRoot) + '</p>');
            }

            function selectNode(node) {
                var selectedValue;
                if(scope.multipleSelect) {
                    selectedValue = _.isArray(ngModelCtrl.$viewValue) ? angular.copy(ngModelCtrl.$viewValue) : [];
                   if(_.findIndex(selectedValue, {id: node.id})==-1) {
                       selectedValue.push(node);
                       ngModelCtrl.$setViewValue(selectedValue);
                       ngModelCtrl.$render();
                   }
                }else{
                    ngModelCtrl.$setViewValue(node);
                    ngModelCtrl.$render();
                }
                toggleTreeOpen();
            }
            function clearSelection($event) {
                ngModelCtrl.$setViewValue(null);
                ngModelCtrl.$render();
                if (_.isObject($event)) {
                    $event.stopPropagation();
                }
            }
            function openRecordInTab(){
                UserPreferences.getClientId().then(_openRecordInTab);
            }
            function isNodeSelected(node){
                if (_.isObject(node) && _.isObject(ngModelCtrl.$viewValue)){
                    return node.id === ngModelCtrl.$viewValue.id;
                }
            }
            function isAnyNodeSelected() {
                return _.isObject(ngModelCtrl.$viewValue);
            }
            function initNode(node){
                node.expanded = _.has(node, 'expanded') ? node.expanded : false;
            }
            function toggleNodeExpand(node){
                node.expanded = !node.expanded;
                var applyLkpSec = false;
                if (node.expanded && (attrs.alphaLookupCacheLibrary === 'false' || _.isEmpty(node.children))) {
                    node.isLoading = true;
                    if(scope.containerId === 'correlatedLookupEditor__parentInput'){
                        applyLkpSec = true;
                    }
                    AlphaLookupTreeService.getChildren(
                        attrs.alphaLookupLibrary,
                        {
                            recordTypeId: attrs.alphaLookupLibraryRecordType,
                            fieldTypeId: attrs.alphaLookupLibraryFieldType,
                            exclusiveObjects: attrs.alphaLookupExclusiveObjects === 'true'
                        },
                        node,
                        attrs.alphaLookupIncludeExpired === 'true',
                        applyLkpSec
                    )
                        .then(function(children) {
                            node.children = children;
                        })
                        .catch(function(reason) {
                            $log.log(reason);
                        })
                        .finally(function() {
                            node.isLoading = false;
                        });
                }
            }
            function getDisabledStatus(node){
                return isDisabledBySecurity() || isParentLeafDisabled();

                function isParentLeafDisabled(){
                    return onlyLowestLeafSelectable && !node.isLeaf;
                }
                function isDisabledBySecurity(){
                    return bypassAuthority ? false : ((_.has(node, 'readLookupAuthority') && node.readLookupAuthority === false) || (_.has(node,'updateLookupAuthority') && node.updateLookupAuthority === false));
                }
            }
            function filterTree(){
                scope.isLoading = true;
                var applyLkpSec = false;
                if(scope.containerId === 'correlatedLookupEditor__parentInput'){
                    applyLkpSec = true;
                }
                AlphaLookupTreeService.filterTree(
                    attrs.alphaLookupLibrary,
                    {
                        recordTypeId: attrs.alphaLookupLibraryRecordType,
                        fieldTypeId: attrs.alphaLookupLibraryFieldType,
                        exclusiveObjects: attrs.alphaLookupExclusiveObjects === 'true'
                    },
                    scope.searchFilter.text,
                    attrs.alphaLookupIncludeExpired === 'true',
                    applyLkpSec
                )
                    .then(function(results) {
                        scope.matchedList = results;
                    })
                    .catch(function(reason) {
                        $log.log(reason);
                    })
                    .finally(function() {
                        scope.isLoading = false;
                    });
            }
            function toggleTreeOpen() {
                if (scope.open) {
                    _closeTree();
                } else if (!scope.editingIsDisabled()) {
                    _openTree();
                }
            }
            function editingIsDisabled() {
                return _readOnlyMode || _readOnlyState ? true : false;
            }
            function searchFilterIsValid() {
                return _.isString(scope.searchFilter.text) && scope.searchFilter.text.length >= 2;
            }
            function updateSearchFilter() {
                if (_.isEmpty(scope.searchFilter.text)) {
                    scope.matchedList = null;
                }
            }
            function removeTag(indexId){
                scope.selectedValue.splice(indexId,1);
                scope.selectedValue.length==0 ? ngModelCtrl.$setViewValue(null): ngModelCtrl.$setViewValue(angular.copy(scope.selectedValue));
                ngModelCtrl.$render();
            }
            function getFormattedSelection() {
                return !_.isEmpty(scope.selectedValue) ? selectionFormatter(scope.selectedValue, attrs.alphaLookupLibrary) : placeholderText;
            }

            // Private functions

            function _loadToolTip() {
                if(!_.isNil(ngModelCtrl.$viewValue) && !(scope.multipleSelect)) {
                    _getTooltipContents().then(function (contents) {
                        scope.tooltipVal = contents;
                        scope.loadedToolTip = true;
                    });
                } else {
                    scope.tooltipVal = null;
                }
            }
            
            function _getModelValue() {
                ngModelCtrl.$modelValue = 0; // force the $formatters pipeline to run
            }
            function _eventHandler(data, event) {
                switch (event.topic) {
                    case 'setReadOnly':
                        _setReadOnly();
                        break;
                    case 'setRequired':
                        _setRequired();
                        break;
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
                function _setRequired() {
                    if (data.value === true) {
                        _enableRequired();
                    } else if (data.value === false) {
                        _disableRequired();
                    }
                }
            }
            function _enableReadOnly() {
                element.addClass(readOnlyClass);
                _closeTree();
            }
            function _disableReadOnly() {
                element.removeClass(readOnlyClass);
            }
            function _enableRequired() {
                ngModelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
            }
            function _disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }
            function _showSelectedNode() {
                _collapseAllNodes();
                _expandParentsOfSelectedNode();
                $timeout(_scrollToSelectedNode); // Timeout lets tree render before scrolling
            }
            function _collapseAllNodes() {
                _collapseNodes(scope.lookupValues.children);
                function _collapseNodes(nodes) {
                    _.forEach(nodes, function(node) {
                        node.expanded = false;
                        if (node.children) {
                            _collapseNodes(node.children);
                        }
                    });
                }
            }
            function _expandParentsOfSelectedNode() {
                _searchNode(scope.lookupValues.children);
                function _searchNode(nodes) {
                    var found = false;
                    _.forEach(nodes, function(node) {
                        if (node.id === scope.selectedValue.id) {
                            found = true;
                        }
                        if (node.children) {
                            node.expanded = _searchNode(node.children);
                            if (node.expanded) {
                                found = true;
                            }
                        }
                    });
                    return found;
                }
            }
            function _scrollToSelectedNode() {
                var container = element.find('.tree-list-container'),
                    button = element.find('button.selected').closest('li');
                if (button.length) {
                    container.scrollTop(button.offset().top - container.offset().top + container.scrollTop());
                }
            }
            function _openTree() {
                scope.open = true;
                _fetchLookups();
                _unbindBlurEvent();
                _bindBlurEvent();
                if (scope.scrollableContainer) {
                    _removeContainerScrollbar();
                }
            }
            function _closeTree() {
                scope.open = false;
                scope.searchFilter.text = null;
                scope.matchedList = null;
                _unbindBlurEvent();
                if (scope.scrollableContainer) {
                    _restoreContainerScrollbar();
                }
            }
            function _bindBlurEvent() {
                if (scope.scrollableContainer) {
                    $document.on('click.' + scope.containerId, function (e) {
                        _handleClickEvent(e);
                    });
                } else {
                    jQuery('body').on('click.' + scope.containerId, function(e) {
                        _handleClickEvent(e);
                    });
                }

                function _handleClickEvent(e) {
                    if (!jQuery.contains(element.get(0), e.target)) {
                        // This can happen during a digest cycle if an Angular component triggers a click event
                        $timeout(function() {
                            _closeTree();
                        });
                    }
                }
                scope.$on('lookup-dropdown-open', function(){
                    if(scope.open === true){
                        _closeTree();
                    }
                });

            }
            function _unbindBlurEvent() {
                if (scope.scrollableContainer) {
                    $document.off('click.' + scope.containerId);
                } else {
                    jQuery('body').off('click.' + scope.containerId);
                }
            }
            function _fetchLookups() {
                var forceRefresh = attrs.alphaLookupCacheLibrary === 'false';
                var applyLkpSec = false;
                scope.isLoading = true;
                if(scope.containerId === 'correlatedLookupEditor__parentInput'){
                    applyLkpSec = true;
                }
                AlphaLookupTreeService.getRoot(
                    attrs.alphaLookupLibrary,
                    {
                        recordTypeId: attrs.alphaLookupLibraryRecordType,
                        fieldTypeId: attrs.alphaLookupLibraryFieldType,
                        exclusiveObjects: attrs.alphaLookupExclusiveObjects === 'true'
                    },
                    _getSelectedValueId(),
                    forceRefresh,
                    attrs.alphaLookupIncludeExpired === 'true',
                    applyLkpSec
                )
                    .then(function(library) {
                        scope.lookupValues = library;
                        if (_.isObject(scope.selectedValue)) {
                            _showSelectedNode();
                        }
                    })
                    .catch(function(reason) {
                        $log.log(reason);
                    })
                    .finally(function() {
                        scope.isLoading = false;
                    });
            }
            function _openRecordInTab(clientId) {
                var tabConfig = _getViewTabConfig();
                AlphaTabManagerService.addTab(tabConfig.id, tabConfig.title, tabConfig.url);
                function _getViewTabConfig(){
                    return {
                        id: 'correlated-record-link-' + attrs.alphaLookupCorrelatedIconId,
                        title: correlatedRecordType,
                        url: RecordDataUtil.getRecordDetailUrl(clientId, correlatedRecordType, currentRecordMasterId)
                    };
                }
            }
            function _singleFormatter(modelValue) {
                var viewValue;
                if (!_.isEmpty(modelValue)) {
                    if (_.isString(modelValue)) {
                        viewValue = {
                            id: modelValue,
                            value: modelValue
                        };
                    } else if (_.isObject(modelValue)) {
                        viewValue = modelValue;
                    }
                }
                return viewValue;
            }
            function _multiFormatter(modelValue) {
                var viewValue;
                if (_.isArray(modelValue)) {
                    viewValue = _.map(modelValue, _singleFormatter);
                    _.pull(viewValue, undefined);
                }
                return viewValue;
            }
            function _parser(viewValue) {
                var modelValue = attrs.alphaLookupScopeSetProperty ? _getPropFromLookupValue(viewValue) : viewValue;
                return angular.copy(modelValue);
            }
            function _render() {
                scope.selectedValue = ngModelCtrl.$viewValue;
                // If present, enable/disable correlated record link based on selected element's recordMasterId value
                if(_.has(ngModelCtrl.$viewValue, 'recordMasterId')) {
                    currentRecordMasterId = ngModelCtrl.$viewValue.recordMasterId;
                }

                scope.tooltipVal = [I18nUtil.getI18nString('MSG_LOADING', 'Loading') + '...'];

                if (scope.loadedToolTip) {
                    // Force reload a new item
                    if(!_.isNil(ngModelCtrl.$viewValue) && !(scope.multipleSelect)) {
                        _getTooltipContents().then(function (contents) {
                            scope.tooltipVal = contents;
                        });
                    } else{
                        scope.tooltipVal = null;
                    }
                }
                
            }
            function _getSelectedValueId() {
                return _.get(ngModelCtrl.$viewValue, 'id') || null;
            }
            function _updatePlaceHolderText(){
                if(_readOnlyMode){
                    placeholderText = '';
                }else{
                    placeholderText = attrs.alphaLookupPlaceholder || I18nUtil.getI18nString('LBL_CHOOSE_VALUE', 'Choose a Value');
                }
            }
            function _getPropFromLookupValue(lookupValue) {
                return _.isObject(lookupValue) ? _.get(lookupValue, attrs.alphaLookupScopeSetProperty) : lookupValue;
            }
            function _defaultSelectionFormatter(selectedValue) {
                return selectedValue.value;
            }

            function _getTooltipView(pathToRoot) {
                if (!_.isNull(pathToRoot)) {
                    var parent = '';
                    _.forEach(pathToRoot, function (par, ind) {
                        var indCopy = ind;
                        while (indCopy > 0) {
                            parent = parent.concat('&nbsp;&nbsp;');
                            indCopy--;
                        }
                        if (ind === pathToRoot.length-1){
                            parent = parent.concat('<B>').concat(par).concat('<B>');
                        } else {
                            parent = parent.concat(par);
                            parent = parent.concat('<BR>');
                        }
                    });
                    return _.replace(parent, /'/g, '&#039;');
                }
            }

            function _getTooltipContents() {
                var deferred = $q.defer();

                AlphaLookupTreeService
                    .getLookupValues(attrs.alphaLookupLibrary, scope.selectedValue.id)
                    .then(function success(response){
                        var pathToRoot = _.get(response, 'lookupValueList[0].pathToRoot');
                        var value = _.get(response, 'lookupValueList[0].value');
                        var path = !_.isEmpty(value) ? [value] : null;
                        deferred.resolve(pathToRoot || path);
                    }).catch(function error(response){
                        deferred.reject(response);
                    });

                return deferred.promise;
            }

            /* When the lookup dropdown is closed, restore the horizontal scrollbar of the container. */
            function _restoreContainerScrollbar(){
                var node = _getScrollableContainer();
                if(deregisterPopup(node) > 0){
                    return; //Another popup got opened before we close this one.
                }
                if((node && node.height() < POPUP_HEIGHT) || scope.forceScroll){
                    node.css({'overflow-x' : 'auto', 'left' : 0});
                    node = node.parent();
                    node.css({'overflow-x' : 'unset'});
                }
            }

            /* When the container has a scrollbar, most likely due to fixed header nature of the table, lookup dropdown do not
            get displayed beyond the container boundary unless the scrollable ability is removed.*/
            function _removeContainerScrollbar(){
                var node = _getScrollableContainer();
                if(registerPopup(node) > 1) {
                    return; //Already the scrollbar is removed.
                }
                if((node && node.height() < POPUP_HEIGHT) || scope.forceScroll){
                    var scrollLeft = node.scrollLeft();
                    scrollLeft = -1 * scrollLeft;
                    node.css({'overflow-x' : 'unset', 'left': scrollLeft + 'px', 'position':'relative' });
                    node = node.parent();
                    node.css({'overflow-x' : 'clip'});
                } 
            }

            function _getScrollableContainer(){
                var node = null;
                if(scope.scrollableContainer) {
                    node = element.parent();
                    while (node && !node.hasClass(scope.scrollableContainer)) {
                        node = node.parent();
                        if(node.length == 0){
                            return null;
                        }
                    }
                }
                return node;
            }

            function registerPopup(container){
                if(!container){
                    return 0;
                }
                var popupCount = container.attr('popup-count');
                popupCount = popupCount >= 0 ? ++popupCount : 1;
                container.attr('popup-count', popupCount);
                return popupCount;
            }

            function deregisterPopup(container){
                if(!container){
                    return 1;
                }
                var popupCount = container.attr('popup-count');
                popupCount = popupCount > 0 ? --popupCount : 0;
                container.attr('popup-count', popupCount);
                return popupCount;
            }
        }
    }
})();
