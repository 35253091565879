(function () {
    'use strict';

    angular
        .module('alpha.common.services.preview',[] )
        .factory('PreviewService', PreviewService);

    PreviewService.$inject = [
        '$http',
        'Utils',
        '$q'
    ];

    function PreviewService(
        $http,
        Utils,
        $q
        ) {
        return {
            getFileType: getFileType,
            getMimeType: getMimeType,
            getAttachmentFile: getAttachmentFile
        };

        function getFileType(fileName) {
            if(fileName) {
                return fileName.split(".").pop();
            }
        }

        function getAttachmentFile(filePath){
            var deferred = $q.defer(),
                config = {
                    method: 'GET',
                    url: filePath,
                    responseType: 'blob'
                };
            $http(config)
                .then(function(response){
                    deferred.resolve(response);
                })
                .catch(function(error){
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function getMimeType(fileType) {
            var file_type = fileType.toLowerCase();
            if(file_type === "txt") {
                return "text/plain";
            } else if(file_type === "pdf") {
                return "application/pdf";
            } else if(file_type === "jpg" || file_type === "jpeg") {
                return "image/jpeg"
            } else if(file_type === "png") {
                return "image/png";
            } else {
                return "Not Supported";
            }
        }
    }
})();