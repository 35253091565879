(function() {
    'use strict';

    /**
     * Adds a handle that can resize a column in a Smart Table.
     *
     * @method alphaSmartTableResize
     *
     * @example
     *      HTML:
     *      <alpha-smart-table-resize
     *          column="someGridColumn"
     *          min="50"></alpha-smart-table-resize>
     *
     * @param {Object} column The column to set width on
     * @param {Number} column.width The width of the column in pixels
     * @param {Boolean} column.isDefaultWidth Whether the column is at its default width
     * @param {Number} [min] The minimum width of this column; default is 50
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableResize', alphaSmartTableResize);

    function alphaSmartTableResize() {
        var channel = 'alphaSmartTableResize';
        return {
            link: link,
            restrict: 'E'
        };
        function link(scope, element, attrs) {
            var column = scope.$eval(attrs.column),
                table_id = scope.$eval(attrs.table_id),
                minWidth = parseInt(attrs.minWidth) || 75,
                windowElement = angular.element(window),
                heading = element.closest('.alpha-table-heading'),
                table = element.closest('.alpha-table'),
                newWidth,
                headingCells, multiLineColumns, totalColWidth;
            element.on('mousedown.' + channel, function(mousedown) {
                var origWidth = heading.outerWidth(),
                    nthIndex = table.find('.alpha-table-heading').index(heading) + 1,
                    cells = heading.add(table.find('.alpha-table-cell:nth-child(' + nthIndex + ')')),
                    tableFirstCol, tableSecondCol;
                mousedown.preventDefault(); // Prevent text selection while dragging
                element.css('width', '100%'); // Mask the heading to prevent clicks if cursor is over it on release
                windowElement.on('mousemove.' + channel, function(mousemove) {
                    newWidth = Math.max(origWidth + (mousemove.pageX - mousedown.pageX), minWidth);
                    cells.css('width', newWidth);
                    if (table_id == 'search_table' || table_id == 'user_table' || table_id == 'work_flow_grid') {
                        if(table_id == 'work_flow_grid'){
                            tableFirstCol = heading.add(
                                table.find(".alpha-table-cell:nth-child(1)")
                            );
                            tableFirstCol.css("left", newWidth);
                        }
                        else if(table_id != 'work_flow_grid' && nthIndex==1){
                            tableSecondCol = heading.add(
                                table.find(".alpha-table-cell:nth-child(2)")
                            );
                            tableSecondCol.css("left", newWidth);
                        }                        
                    }
                    headingCells = table.find('.alpha-table-heading');
                    totalColWidth=8;
                    for(var cellIndex=0; cellIndex < headingCells.length; cellIndex++){
                        totalColWidth += headingCells[cellIndex].clientWidth;
                    }
                    multiLineColumns = table.find('.alpha-table-cell-multiline');
                    multiLineColumns.css('width', totalColWidth);
                });
                windowElement.on('mouseup.' + channel, function() {
                    element.css('width', '');
                    if (_.isNumber(newWidth)) {
                        scope.$apply(function(){
                            column.isDefaultWidth = false;
                            column.width = newWidth + 'px'; // preserve this value for preferences, redrawing the grid, etc.
                        });
                    }
                    windowElement.off('.' + channel);
                });
            });
            element.on('click.' + channel, function(click) {
                click.stopPropagation();
            });
        }
    }
})();
