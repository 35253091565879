(function () {
    'use strict';

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableChart', alphaSmartTableChart);

    alphaSmartTableChart.$inject = ['$timeout', '$filter', 'AlphaSmartTableService', 'I18nUtil', 'RecordDataUtil'];

    function alphaSmartTableChart($timeout, $filter, AlphaSmartTableService, I18nUtil, RecordDataUtil){
        return{
            templateUrl: applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/alphaSmartTableChart.directive.html',
            link : link,
            require: '^alphaSmartTable',
            scope: {
                chartType: '=',
                measure: '=',
                chartColumns: '=',
                columnSelectionVisible: '=',
                closeChart: '&',
                columns: '=',
                containerId: '@id',
                chartLegendToggle:'=?',
                chartDefaultFilterParam: '@',
                chartDefaultFilterDataType: '@',
                clearMasterFilter: '&'
            }
        };

        function link(scope, element, attrs, astCtrl){
            var chart, queryCurrencySymbol;
            scope.isDrillDown = false;
            scope.chartOptions = [{
                id: 'BAR',
                text: I18nUtil.getI18nString('LBL_BAR_CHART', 'Bar Chart')
            }, {
                id: 'LINE',
                text: I18nUtil.getI18nString('LBL_LINE_CHART', 'Line Chart')
            }, {
                id: 'PIE',
                text: I18nUtil.getI18nString('LBL_PIE_CHART', 'Pie Chart')
            }];
            scope.restoreCollection = restoreCollection;
            scope.closeCharting = closeCharting;
            scope.showColumnSelection = showColumnSelection;
            scope.selectedTermId = '';

            _init();

            // Public Functions
            function restoreCollection(){
                astCtrl.setFilter(null);
                scope.isDrillDown = false;
                if (_.isObject(chart)) {
                    chart.unselect();
                    chart.focus();
                }
            }
            function closeCharting(){
                astCtrl.unhighlightColumns();
                scope.closeChart();
            }
            function showColumnSelection(){
                scope.columnSelectionVisible = true;
            }

            // Private Functions

            function _init(){
                scope.$watch(function() {
                    return astCtrl.chartData;
                }, function(newVal) {
                    if (_.isArray(newVal)) {
                        _createChart(AlphaSmartTableService.getFormattedChartData(newVal, scope.chartType, scope.measure, scope.chartColumns.xAxis[0], scope.chartColumns.yAxis[0]) || {});
                    }
                });
                scope.$watch(function() {
                    return astCtrl.currency;
                }, function(newVal) {
                    queryCurrencySymbol = _.isObject(newVal) ? newVal.symbol : '';
                });
                scope.$on('containerResize', function(event, data) {
                    if (_.isObject(chart)) {
                        chart.flush();
                        chart.resize();
                        if(_.isObject(data)){
                            if(data.showChartLegend) {
                                chart.legend.show();
                            }else{
                                chart.legend.hide();
                            }
                            $timeout(function(){
                                _updateLegendAfterZoom();
                            },300);
                        }
                    }
                });
            }
            function _createChart(data){
                if(!scope.columnSelectionVisible){
                    switch (scope.chartType.toUpperCase()) {
                        case 'PIE':
                            chart = _drawPieChart(data);
                            break;
                        case 'BAR':
                            chart = _drawBarLineChart(data, 'bar');
                            break;
                        default:
                            chart = _drawBarLineChart(data, 'line');
                    }
                    $timeout(function(){
                        // This doesn't seem to trigger without an explicit interval. The chart has a render throttle
                        if (_.isObject(chart)) {
                            chart.flush();
                            chart.resize();
                        }
                    }, 100);
                    _updateHighlighting();
                }
            }
            function _drawPieChart(data){
                var xAxisLabel = _.get(scope.chartColumns, 'xAxis[0].displayName'),
                    yAxisLabel = _.get(scope.chartColumns, 'yAxis[0].displayName');
                if (yAxisLabel) {
                    scope.chartTitle = yAxisLabel + ' ' + I18nUtil.getI18nString('LBL_BY', 'by')  + ' ' + xAxisLabel;
                } else {
                    scope.chartTitle = xAxisLabel;
                }
                return c3.generate({
                    bindto: '#' + attrs.id + ' .chart-area',
                    data: {
                        json: data,
                        type: 'pie',
                        onclick: _filterGridData,
                        onmouseover: _preventHandlerOnSelection,
                        onmouseout: _preventHandlerOnSelection,
                        selection:{
                            enabled: true
                        }
                    },
                    tooltip:{
                        contents: function(d, defaultTitleFormat, defaultValueFormat, color){
                            var object = d[0],
                                name = object.name,
                                totalLabel = I18nUtil.getI18nString('LBL_TOTAL', 'Total'),
                                title = defaultTitleFormat(object.name),
                                value = defaultValueFormat(object.value, object.ratio, object.id, object.index),
                                bgColor = color(object.id),
                                formattedValue = _.isFinite(object.value) ? _getNumberFormat(object.value, object.index) : object.value;

                            return  '<table class="c3-tooltip">' +
                                '   <tr><th colspan="2">' + title + '</th></tr>' +
                                '   <tr class="c3-tooltip-name-' + object.id + '">' +
                                '       <td class="name"><span style="background-color: '+ bgColor +'"></span>'+ name +'</td>' +
                                '       <td class="value">'+ value + '</td>' +
                                '   </tr>' +
                                '   <tr class="c3-tooltip-name-' + object.id + '">' +
                                '       <td class="name"><span style="background-color: '+ bgColor +'"></span>' + totalLabel + '</td>' +
                                '       <td class="value">'+ formattedValue  + '</td>' +
                                '   </tr>' +
                                '</table>';
                        }
                    },
                    legend: {
                        hide:scope.chartLegendToggle===undefined?false:!scope.chartLegendToggle,
                        position: 'right',
                        item: {
                            onclick: _preventHandlerOnSelection,
                            onmouseover: _preventHandlerOnSelection,
                            onmouseout: _preventHandlerOnSelection
                        }
                    },
                    oninit: _updateLegend
                });
            }
            function _drawBarLineChart(data, type) {
                var xAxisLabel = _.get(scope.chartColumns, 'xAxis[0].displayName'),
                    yAxisLabel = _.get(scope.chartColumns, 'yAxis[0].displayName');
                scope.chartTitle = yAxisLabel;
                if (xAxisLabel) {
                    scope.chartTitle += ' ' + I18nUtil.getI18nString('LBL_BY', 'by')  + ' ' + xAxisLabel;
                }
                return c3.generate({
                    bindto: '#' + attrs.id + ' .chart-area',
                    data: {
                        json: data,
                        type: type,
                        onclick: _filterGridData,
                        x: _.isObject(scope.chartColumns.xAxis[0]) ? 'x' : undefined,
                        color: _getColorScale
                    },
                    axis:{
                        y: {
                            tick: {
                                format: _getNumberFormat
                            }
                        },
                        x: {
                            type: 'category'
                        }
                    },
                    tooltip:{
                        contents: function(d, defaultTitleFormat, defaultValueFormat, color){
                            var object = d[0],
                                name = object.name,
                                title = defaultTitleFormat(object.name),
                                bgColor = color(object.id),
                                formattedValue = _.isFinite(object.value) ? _getNumberFormat(object.value, object.index) : object.value;

                            return  '<table class="c3-tooltip">' +
                                '   <tr><th colspan="2">' + title + '</th></tr>' +
                                '   <tr class="c3-tooltip-name-' + object.id + '">' +
                                '       <td class="name"><span style="background-color: '+ bgColor +'"></span>'+ name +'</td>' +
                                '       <td class="value">'+ formattedValue + '</td>' +
                                '   </tr>' +
                                '</table>';
                        }
                    },
                    legend:{
                        hide: true
                    },
                    padding: {
                        right: 20
                    }
                });
            }
            function _getColorScale(color, item){
                var colors = d3.scale.category10();
                colors.domain(d3.range(0,10));

                return colors(item.index);
            }
            function _preventHandlerOnSelection(){
                if(!_.isEmpty(chart.selected())){
                    chart.focus(!_.isEmpty(scope.selectedTermId) ? scope.selectedTermId : chart.selected()[0].id);
                }
            }
            function _getValueFromAssoc(chartData, index, filterParam, targetColumn) {
                var value = astCtrl.chartData[index][filterParam];
                if(value === undefined) {
                    return astCtrl.chartData[index][targetColumn.recordTypeId][filterParam];
                } else
                    return value;
            }

            function _getFieldValueFromAssoc(i) {
                var yColumn = scope.chartColumns.yAxis[0],
                    fieldValue;
                fieldValue = _.get(astCtrl.chartData, '[' + i + '].' + yColumn.fieldTypeId);
                if(fieldValue  === undefined) {
                    fieldValue = _getValueFromAssoc(astCtrl.chartData, i, yColumn.fieldTypeId, yColumn);
                }
                return fieldValue;
            }

            function _filterGridData(d) {
                scope.clearMasterFilter();
                var targetColumn = scope.chartColumns.xAxis[0],
                    selectedAssocRecordTypeId=targetColumn.recordTypeId,
                    filterParam, filterValue, filterOperator;
                if (_.isObject(targetColumn)) {
                    filterParam = targetColumn.fieldTypeId;
                    filterValue = RecordDataUtil.getTermValueFromFieldValue(_getValueFromAssoc(astCtrl.chartData, d.index, filterParam, targetColumn), targetColumn.fieldMetadata.dataType);
                } else {
                    filterParam = scope.chartDefaultFilterParam;
                    filterValue = RecordDataUtil.getTermValueFromFieldValue(astCtrl.chartData[d.index][filterParam], scope.chartDefaultFilterDataType);
                    if(!filterValue){
                        filterValue = RecordDataUtil.getTermValueFromFieldValue(astCtrl.chartData[d.index][selectedAssocRecordTypeId][filterParam], targetColumn.fieldMetadata.dataType);
                        filterParam=[selectedAssocRecordTypeId, filterParam];
                    }
                }
                filterOperator = _.isString(filterValue) ? 'EQUALS' : 'NO_VALUE';
                astCtrl.setFilter(filterParam, filterOperator, filterValue);
                scope.isDrillDown = true;
                if (_.isObject(chart)) {
                    scope.selectedTermId = d.id;
                    chart.unselect();
                    chart.select(d.id);
                    chart.focus(d.id);
                }
            }
            function _getNumberFormat(data, i) {
                var yColumn = scope.chartColumns.yAxis[0],
                    fieldMetadata = yColumn ? yColumn.fieldMetadata : {dataType: 'Number'},
                    fieldValue;
                if (yColumn) {
                    if (_.isFinite(i)) {
                        fieldValue = _getFieldValueFromAssoc(i);
                    } else {
                        switch(yColumn.fieldMetadata.dataType) {
                            case 'Currency':
                                fieldValue = {
                                    amount: data,
                                    baseCurrencySymbol: _getCurrencySymbolForAllResults(yColumn.fieldTypeId,yColumn.recordTypeId)
                                };
                                break;
                            case 'SummarizedCurrency':
                                fieldValue = {
                                    total: data,
                                    baseCurrencySymbol: _getCurrencySymbolForAllResults(yColumn.fieldTypeId,yColumn.recordTypeId)
                                };
                                break;
                            case 'Number':
                                fieldValue = data;
                                break;
                        }
                    }
                } else {
                    fieldValue = data;
                }
                return RecordDataUtil.getDisplayValueForField(fieldValue, fieldMetadata);
            }
            function _getCurrencySymbolForAllResults(fieldTypeId, recordTypeId) {
                var currencySymbols = _.chain(astCtrl.chartData)
                    .filter(function(result) {
                        if(result[fieldTypeId] === undefined)
                            return _.isObject(result[recordTypeId][fieldTypeId]);
                        return _.isObject(result[fieldTypeId]);
                    })
                    .uniqBy(function(result) {
                        if(result[fieldTypeId] === undefined)
                            return result[recordTypeId][fieldTypeId].baseCurrency;
                        return result[fieldTypeId].baseCurrency
                    })
                    .map(function(result) {
                        if(result[fieldTypeId] === undefined)
                            return result[recordTypeId][fieldTypeId].baseCurrencySymbol;
                        return result[fieldTypeId].baseCurrencySymbol;
                    })
                    .value();
                return currencySymbols.length === 1 ? currencySymbols[0] : null;
            }
            function _updateHighlighting(){
                var columns = _.has(scope.chartColumns, 'yAxis') ? _.union(scope.chartColumns.yAxis, scope.chartColumns.xAxis) : scope.chartColumns;
                astCtrl.unhighlightColumns();
                astCtrl.highlightColumns(_.map(columns, 'fieldTypeId'));
            }
            function _updateLegend(){
                var firstLegend = d3.select('#'+ scope.containerId + ' .c3-legend-item'),
                    legendCon = d3.select(firstLegend.node().parentNode),
                    legendY = parseInt(firstLegend.select('text').attr('y'));

                    legendCon
                        .append('text')
                        .text(scope.chartTitle)
                        .attr('y', legendY - 20)
                        .attr('class','chart-legend-title');
            }
            function _updateLegendAfterZoom(){
                var firstLegend = d3.select('#'+ scope.containerId + ' .c3-legend-item'),
                    legendCon = d3.select('#'+ scope.containerId + ' .chart-legend-title'),
                    legendY = parseInt(firstLegend.select('text').attr('y'));
                    legendCon.attr('y', legendY - 20);
            }
        }
    }
})();
