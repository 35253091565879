(function() {
    'use strict';

    /**
     * Adds pagination with a summary to a grid. When Smart Table
     * is used, this is intended to be inside of the st-pagination
     * element created by the alpha-smart-table-footer directive.
     *
     * @method alphaSmartTableFooterInner
     *
     * @example
     *      HTML:
     *      <alpha-smart-table-footer-inner></alpha-smart-table-footer-inner>
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableFooterInner', alphaSmartTableFooterInner);

    function alphaSmartTableFooterInner() {
        return {
            link: link,
            restrict: 'E',
            require: ['^alphaSmartTable', '^alphaSmartTableFooter', '?^stTable'],
            scope: true
        };
        function link(scope, element, attrs, controllers) {
            var astCtrl = controllers[0],
                astfCtrl = controllers[1],
                stTableCtrl = controllers[2];

            // Scope methods

            scope.setItemsPerPage = setItemsPerPage;

            // Scope properties

            scope.showPageCount = astfCtrl.getShowPageCount();
            scope.itemsPerPageOptions = astfCtrl.getPageSizeOptions();
            scope.containerId = element.closest('alpha-smart-table-footer').attr('id');
            scope.stRange = {};
            scope.pageFlag = false;

            // Initialization

            if (astCtrl.serverSideMode) {
                scope.$watch(function() {
                    return _.isObject(astCtrl.currentState) ? astCtrl.currentState.currentPageSize : undefined;
                }, function() {
                    if (_.isObject(astCtrl.currentState) && !_.isEmpty(astCtrl.currentState)) {
                        if (_.has(astCtrl.currentState, 'currentPageSize')) {
                            scope.itemsPerPage = astCtrl.currentState.currentPageSize;
                        }
                    }
                });
            } else {
                scope.$watchGroup(['currentPage', 'totalItemCount', 'stItemsByPage'], function() {
                    _updateRange();
                    _resetView();
                });
                scope.$watch('stItemsByPage', function(value) {
                    var stItemsByPage = parseInt(value);
                    if (stItemsByPage >= 0) {
                        astfCtrl.setPageSize(stItemsByPage);
                        scope.itemsPerPage = stItemsByPage;
                    }
                });
                scope.$watch('currentPage', function(value) {
                    scope.pageFlag = true;
                });
            }

            var unbindEvent = scope.$on('updatePageSizeOptions', function (event, data) {
                if (_.isArray(data)) {
                    scope.itemsPerPageOptions = data;
                }
            });
            scope.$on('$destroy', unbindEvent);

            // Public methods

            function setItemsPerPage() {
                if(scope.isGridInEditMode){
                    scope.dirtyConfirmation(function (){
                        scope.cancelEditableGrid(false);
                        _setItemsPerPage();
                    }, true);
                } else {
                    _setItemsPerPage();
                }

                function _setItemsPerPage(){
                    if (astCtrl.serverSideMode) {
                        astCtrl.setPageSize(scope.itemsPerPage);
                    } else {
                        astfCtrl.setPageSize(scope.itemsPerPage);
                    }
                    _resetView();
                }
            }

            // Private functions

            function _updateRange(){
                var pagination = stTableCtrl.tableState().pagination;
                scope.stRange.from = (pagination.totalItemCount > 0 ? pagination.start + 1 : 0);
                if (scope.currentPage === pagination.numberOfPages) {
                    scope.stRange.to = pagination.totalItemCount;
                } else {
                    scope.stRange.to = scope.stRange.from + pagination.number - 1;
                }
            }
            function _resetView() {
                var girdId = element.closest('[alpha-smart-table]').attr('id');
                element.closest('[alpha-smart-table]').find('.alpha-table-body').animate({scrollTop: 0});
                var ele = $('#' + girdId).position();
                var grid = $('#' + girdId);
                if(ele && scope.pageFlag && grid && !grid.hasClass("detailed-MTO-popovers")) {
                    var topoffset = $(".content-pane").scrollTop() + $('#' + girdId).position().top;
                    $("div.content-pane").scrollTop(topoffset - 80);
                    scope.pageFlag = false;
                }
            }
        }
    }
})();
