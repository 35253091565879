(function() {
    'use strict';

    /**
     * A date/time input that utilizes Bootstrap 3 Datepicker.
     * Date and time are stored on the model as a single number representing UTC time.
     *
     * Note: enable-time simply adds time information along with the date information. time-only
     * makes it so the widget ONLY shows time information, no date information.
     *
     * Note:Position has been changed for the datepicker as it was going extreme out of the right page.
     * Hence, made this change : horizontal: attrs.horizontalPosition || 'right'
     *
     * @method alphaDateTimeInput
     *
     * @example
     *      HTML:
     *      <alpha-date-time-input
     *          id="some_id"
     *          record-type="some_record_type"
     *          event-context="some_context"
     *          event-record-type="some_record_type"
     *          event-field-type="some_field_type"
     *          enable-time="true"
     *          max-date="moment data object"
     *          time-only="true"
     *          read-only-mode="true"
     *          placeholder-text="Today"
     *          input-class="form-control"
     *          ng-model="modelVar"
     *          readonly></alpha-date-time-input>
     *
     * @param {Object} ng-model Model to bind this widget to (a number representing UTC time).
     * @param {String} [placeholder-text] Text to show in the input when there is no date selected.
     * @param {String} [input-class] Class(es) to set on the input element instead of the defaults.
     * @param {Boolean} [enable-time] Allow the user to enter a time with the date.
     * @param {Boolean} [read-only-mode] Makes the input permanently read only even if a rule makes it writable.
     */

    angular
        .module('alpha.common.directives.alphaDateTimeInput', [
            'alpha.utils.I18n',
            'alpha.utils.RequiredFieldUtils',
            'alpha.common.services.ruleExecutionHelper'
        ])
        .directive('alphaDateTimeInput', alphaDateTimeInput);

    alphaDateTimeInput.$inject = ['I18nUtil', 'RequiredFieldService', 'RuleExecutionHelper', 'RecordDataUtil'];

    function alphaDateTimeInput(I18nUtil, RequiredFieldService, RuleExecutionHelper, RecordDataUtil) {
        var DATE_POPUP_HEIGHT = 320;
        return {
            link: link,
            restrict: 'E',
            require: 'ngModel',
            template: template,
            scope: {
                containerId: '@id',
                enableTime: '=',
                dateModelFormat: '=',
                scrollableContainer: '=?',
                forceScroll: '=?',
                displayOnly:'=',
                placeholderText: '@',
                ignoreTimezone: '=',
                setViewOnUniqueDate: '=',
                maxDate: '&',
                minDate: '&',
                showCalenderIcon: '=?',
                disabledInput: '=disabledinput',
                tooltipText: '@',
                displayValue : '&?',
                blurEventHandler : '&?',
                enterKeyEventHandler: '&?'
            }
        };
        function link(scope, element, attrs, ngModelCtrl) {
            scope.showDatePicker = showDatePicker;
            scope.getDateDisplayValue = getDateDisplayValue;
            var readOnlyClass = 'readonly',
                dateInput = element.find('input'),
                timeOnly = scope.$eval(attrs.timeOnly),
                verticalPosition = 'bottom',
                validCharacters, readOnlyMode, readOnlyState, dateTimePicker;
            if(attrs.verticalPosition) {
                verticalPosition = attrs.verticalPosition;
            }
            dateInput.datetimepicker();
            dateTimePicker = dateInput.data('DateTimePicker');
            dateTimePicker.options({
                maxDate: scope.maxDate ? scope.maxDate() : false,
                minDate: scope.minDate ? scope.minDate() : false,
                format: getDateFormat(),
                useCurrent: false,
                widgetParent: dateInput.parent(),
                widgetPositioning: {vertical: verticalPosition}
               
            });
            function showDatePicker() {
                dateTimePicker.show();
            }
            bindInputValidation();
            bindInputToModel();
            bindModelToInput();
            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setRequired', 'setReadOnly'], attrs, eventHandler);
            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    readOnlyMode = true;
                    enableReadOnly();
                } else {
                    readOnlyMode = false;
                    if (!readOnlyState) {
                        disableReadOnly();
                    }
                }
            });

            attrs.$observe('applyRedaction', function(newVal){
                if(newVal === 'true'){
                    scope.applyRedaction='true';
                    dateInput.val(_formatter(dateInput.val()));
                }
            });

            scope.$watch(I18nUtil.getLocale, updateLocale);
            // Create the binding to update the input when the main model is changed
            function bindInputToModel() {
                ngModelCtrl.$render = getModelFromParent;
                if (attrs.applyRedaction === 'true' || attrs.applyRedaction === true) {
                    ngModelCtrl.$render = function() {
                        if(ngModelCtrl.$viewValue != null && !_.isNaN(ngModelCtrl.$viewValue)){
                            var formattedDate = moment(ngModelCtrl.$viewValue).format(getDateFormat());
                            dateInput.val(_formatter(formattedDate));
                        }else {
                            dateInput.val(null);
                        }
                    };
                }
                ngModelCtrl.$render();
            }
            // Create the binding to update the main model when the input is changed
            function bindModelToInput() {
                dateInput.on('dp.change', setModelToParent);
                dateInput.on('dp.hide', restoreContainerScrollbar);
                dateInput.on('dp.show', removeContainerScrollbar);
            }
            function bindInputValidation(){
                dateInput.on('keypress', function (event) {
                    if (!_.includes(validCharacters, event.key.toLowerCase())) {
                        event.preventDefault();
                    }
                });

                if (_.isFunction(scope.enterKeyEventHandler)) {
                    dateInput.on('keydown', function (event) {
                        if (event.keyCode === 13) {
                            scope.enterKeyEventHandler();
                        }
                    });
                }

                if (_.isFunction(scope.blurEventHandler)) {
                    dateInput.on('blur', function (event) {
                        scope.blurEventHandler();
                    });
                }

                dateInput.on('paste', function (event) {
                    var pastedText;
                    if(_.isObject(event.originalEvent.clipboardData)){
                        pastedText = event.originalEvent.clipboardData.getData('text/plain');
                        dateInput.val(stripInvalidCharacters(pastedText)).trigger('change');
                        event.preventDefault();
                    }
                });
            }
            function eventHandler(data, event){
                switch (event.topic) {
                    case 'setReadOnly':
                        setReadOnly();
                        break;
                    case 'setRequired':
                        setRequired();
                        break;
                }
                function setReadOnly() {
                    if (data.value === true) {
                        readOnlyState = true;
                        enableReadOnly();
                    } else if (data.value === false) {
                        readOnlyState = false;
                        if (!readOnlyMode) {
                            disableReadOnly();
                        }
                    }
                }
                function setRequired() {
                    if (data.value === true) {
                        enableRequired();
                    } else if (data.value === false) {
                        disableRequired();
                    }
                }
            }
            function enableReadOnly() {
                dateInput.addClass(readOnlyClass);
                dateTimePicker.disable();
            }
            function disableReadOnly() {
                dateInput.removeClass(readOnlyClass);
                dateTimePicker.enable();
            }
            function enableRequired() {
                ngModelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
            }
            function disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }
            // Sets the date input display format using a format string
            function getDateFormat() {
                if (scope.enableTime) {
                    return I18nUtil.getMomentFormat('DateTime');
                } else if (timeOnly) {
                    return I18nUtil.getMomentFormat('Time');
                } else {
                    return I18nUtil.getMomentFormat('Date');
                }
            }
            function updateLocale() {
                if (scope.enableTime) {
                    validCharacters = I18nUtil.getDisplayCharacters('DateTime');
                } else if (timeOnly) {
                    validCharacters = I18nUtil.getDisplayCharacters('Time');
                } else {
                    validCharacters = I18nUtil.getDisplayCharacters('Date');
                }
                dateTimePicker.options({
                    format: getDateFormat(),
                    locale: moment.locale(),
                    tooltips: {
                        today: I18nUtil.getI18nString('LBL_GO_TO_TODAY', 'Go to today'),
                        clear: I18nUtil.getI18nString('LBL_CLEAR', 'Clear'),
                        close: I18nUtil.getI18nString('LBL_CLOSE', 'Close'),
                        selectMonth: I18nUtil.getI18nString('LBL_SELECT_MONTH', 'Select Month'),
                        prevMonth: I18nUtil.getI18nString('LBL_PREV_MONTH', 'Previous Month'),
                        nextMonth: I18nUtil.getI18nString('LBL_NEXT_MONTH', 'Next Month'),
                        selectYear: I18nUtil.getI18nString('LBL_SELECT_YEAR', 'Select Year'),
                        prevYear: I18nUtil.getI18nString('LBL_PREV_YEAR', 'Previous Year'),
                        nextYear: I18nUtil.getI18nString('LBL_NEXT_YEAR', 'Next Year'),
                        selectDecade: I18nUtil.getI18nString('LBL_SELECT_DECADE', 'Select Decade'),
                        prevDecade: I18nUtil.getI18nString('LBL_PREV_DECADE', 'Previous Decade'),
                        nextDecade: I18nUtil.getI18nString('LBL_NEXT_DECADE', 'Next Decade'),
                        prevCentury: I18nUtil.getI18nString('LBL_PREV_CENTURY', 'Previous Century'),
                        nextCentury: I18nUtil.getI18nString('LBL_NEXT_CENTURY', 'Next Century')
                    },
                    parseInputDate: parseInputValue
                });
            }

            function parseInputValue(inputVal){
                if (!moment.isMoment(inputVal) || inputVal instanceof Date) {
                    inputVal = dateTimePicker.getMoment(inputVal);
                    var inputYear = inputVal.year();
                    if(inputYear < 100){
                        inputYear = moment.parseTwoDigitYear(inputYear);
                        inputVal.year(inputYear);
                    }
                }
                return inputVal;
            }

            function stripInvalidCharacters(val) {
                return _.filter(_.split(val, ''), function(char) {
                    return _.includes(validCharacters, char.toLowerCase());
                }).join('');
            }
            // Applies a new value to the main model
            function setModelToParent() {
                var utc = getUtcFromInput(),
                    val = _.isUndefined(utc) ? _getEmptyVal() : utc;

                if (_.isFunction(scope.displayValue)) {
                    scope.displayValue({ dateDisplayValue: getDateDisplayValue() });
                }

                if(!scope.displayOnly && !_isSameDate()){
                    ngModelCtrl.$setViewValue(val);
                }
                function _getEmptyVal() {
                    return _.isUndefined(ngModelCtrl.$viewValue) ? undefined : null;
                }
                function _isSameDate(){
                    // To prevent dirty state from triggering for a Date format widget when the formats are inconsistent (Timestamp to String)
                    var dateFormats;
                    if(scope.setViewOnUniqueDate && scope.dateModelFormat){
                        dateFormats = [scope.dateModelFormat, 'x'];
                        return moment(ngModelCtrl.$viewValue, dateFormats, 'en-US').format(scope.dateModelFormat) === moment(utc, dateFormats, 'en-US').format(scope.dateModelFormat);
                    }else{
                        return false;
                    }
                }
            }
            // Retrieves and applies the value from the main model
            function getModelFromParent() {
                var modelVal = ngModelCtrl.$viewValue;
                if (modelVal === null || _.isUndefined(modelVal)) {
                    clearInput();
                } else if (_.isString(modelVal) && _.isString(scope.dateModelFormat)){
                    dateTimePicker.date(moment(modelVal, scope.dateModelFormat, 'en-US'));
                } else if (isFinite(modelVal)) {
                    setInputTo(modelVal);
                    ngModelCtrl.$dirty = false;
                }
            }

            /* When the container has a scrollbar, most likely due to fixed header nature of the table, date popup do not
            get displayed beyond the container boundary unless the scrollable ability is removed.*/
            function removeContainerScrollbar(){
                var node = _getScrollableContainer();
                if(registerPopup(node) > 1) {
                    return; //Already the scrollbar is removed.
                }
                if((node && node.height() < DATE_POPUP_HEIGHT) || scope.forceScroll){
                    var scrollLeft = node.scrollLeft();
                    scrollLeft = -1 * scrollLeft;
                    node.css({'overflow-x' : 'unset', 'left': scrollLeft + 'px', 'position':'relative' });
                    node = node.parent();
                    node.css({'overflow-x' : 'clip'});
                }

            }

            /* When the date picker popup is closed, restore the horizontal scrollbar of the container. */
            function restoreContainerScrollbar(){
                var node = _getScrollableContainer();
                if(deregisterPopup(node) > 0){
                    return; //Another popup got opened before we close this one.
                }
                if((node && node.height() < DATE_POPUP_HEIGHT) || scope.forceScroll){
                    node.css({'overflow-x' : 'auto', 'left' : 0});
                    node = node.parent();
                    node.css({'overflow-x' : 'unset'});
                }
            }

            function registerPopup(container){
                if(!container){
                    return 0;
                }
                var popupCount = container.attr('popup-count');
                popupCount = popupCount >= 0 ? ++popupCount : 1;
                container.attr('popup-count', popupCount);
                return popupCount;
            }

            function deregisterPopup(container){
                if(!container){
                    return 1;
                }
                var popupCount = container.attr('popup-count');
                popupCount = popupCount > 0 ? --popupCount : 0;
                container.attr('popup-count', popupCount);
                return popupCount;
            }

            function _getScrollableContainer(){
                var node = null;
                if(scope.scrollableContainer) {
                    node = element.parent();
                    while (node && !node.hasClass(scope.scrollableContainer)) {
                        node = node.parent();
                        if(node.length == 0){
                            return null;
                        }
                    }
                }
                return node;
            }

            /*  Sets the model value for the input to a provided UTC number
             When time is enabled:
             Sets the date and time to the local equivalent of the UTC time
             When time is disabled:
             Sets the date to an adjusted local date that matches the UTC date */
            function setInputTo(utc) {
                var date = (scope.enableTime || timeOnly) ? new Date(utc) : getDateMatchingUtc(utc);
                dateTimePicker.date(date);
            }
            // Clears the value from the input
            function clearInput() {
                dateInput.off('dp.change');
                dateTimePicker.date(new Date())
                    .clear();
                dateInput.on('dp.change', setModelToParent);
            }
            /*  Returns a UTC number based on user input
             When time is enabled:
             Returns the UTC number of the selected date with the time applied
             When time is disabled:
             Returns the UTC number at 00:00:00 UTC on the selected date
             When there is no date:
             Returns undefined */
            function getUtcFromInput() {
                var date = getDateFromInput(),
                    utc;
                if (date instanceof Date) {
                    if(_.isString(scope.dateModelFormat)){
                        utc = moment(date).locale('en-US').format(scope.dateModelFormat);
                    } else if (scope.enableTime || timeOnly) {
                        utc = date.getTime();
                    } else {
                        date.setHours(0, 0, 0, 0);
                        utc = getUtcMatchingDate(date);
                    }
                }
                return utc;
            }
            // Returns a copy of the date from the date input
            function getDateFromInput() {
                var inputVal = dateTimePicker.date();
                return _.isObject(inputVal) ? angular.copy(inputVal._d) : undefined;
            }
            // Returns a UTC number that matches a local date by undoing the timezone offset
            function getUtcMatchingDate(date) {
                var utc = scope.ignoreTimezone ? date.getTime() : (date.getTime() - getTimezoneOffset(date));
                return utc;
            }
            // Returns a local date that matches a UTC number by undoing the timezone offset
            function getDateMatchingUtc(utc) {
                var date = scope.ignoreTimezone ? new Date(utc) : new Date(utc + getTimezoneOffset(utc));
                return date;
            }
            // Returns the timezone offset of a date or UTC number
            function getTimezoneOffset(val) {
                var offset;
                if (val instanceof Date) {
                    offset = val.getTimezoneOffset() * 60000;
                } else {
                    offset = new Date(val).getTimezoneOffset() * 60000;
                }
                return offset;
            }

            function getDateDisplayValue(){
                return dateInput.val();
            }

            function _formatter(modelValue) {
                var result = modelValue;
                if(attrs.applyRedaction === 'true' || attrs.applyRedaction === true){
                    //\p{N} matches with numbers of any language. \p{L} matches with any letters /gu matches unicode globally (all over the string)
                    result = modelValue ? modelValue.replace(/\p{N}|\p{L}/gu, '*') : modelValue;
                }
                return result;
            }
        }
        function template(element, attrs) {
            var htmlTemplate = '' +
                '<div class="alpha-date-time-input-wrapper">' +
                '<span ng-click="showDatePicker()" ng-if="showCalenderIcon" class="glyphicon glyphicon-calendar alpha-date-time-input-calendar-icon"></span>' +
                '<input id="{{containerId}}_date_input"' +
                'ng-disabled="disabledInput || applyRedaction"' +
                '    class="' + (attrs.inputClass || 'input-field form-control string-box string-bgd string-font string-pos select2-choice') + '"' +
                '    placeholder="{{placeholderText}}"' +
                '    ng-class="enableTime ? \'input-field-date-time input-field-date-padding-left\' : \'input-field-date input-field-date-padding-left\'"'+
                '    autocomplete="off"';

            if (attrs.tooltipText) {
                htmlTemplate += 'uib-tooltip="{{tooltipText}}"' +
                    'tooltip-append-to-body="true"';
            } else if (attrs.showTooltip) {
                htmlTemplate += ' uib-tooltip="{{getDateDisplayValue()}}"' +
                    'tooltip-append-to-body="true" ';
            }
            htmlTemplate += '></div>';
            return htmlTemplate;
        }
    }
})();
