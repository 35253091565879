(function() {
    'use strict';

    /**
     *
     */
    angular
        .module('alpha.common.directives.alphaPreviewPopup', [])
        .directive('alphaPreviewPopup', alphaPreviewPopup);

    alphaPreviewPopup.$inject = [
        'I18nUtil',
        'LoaderService',
        'UserPreferences',
        'PreviewService',
        'Utils',
        '$sanitize'
    ];

    function alphaPreviewPopup(
        I18nUtil,
        LoaderService,
        UserPreferences,
        PreviewService,
        Utils,
        $sanitize
    ) {
        return {
            restrict: 'E',
            replace: true,
            template: template,
            link: link
        };
        function link(scope, element, attrs) {

            scope.clientId = "";

            scope.viewAttachments = viewAttachments;
            scope.downloadFile = downloadFile;
            scope.isPreviewEnabled = isPreviewEnabled;

            UserPreferences.getClientId().then(function(clientId) {
                scope.clientId = clientId;
            });

            function downloadFile(rows, column) {
                var row = (rows[column.name])? rows : rows[column.recordTypeId]
                if(row[column.name]) {
                    LoaderService.startLoading();
                    var fileName = row[column.name][0]['fileName'],
                        fieldTypeId = (column.fieldTypeId)? column.fieldTypeId : column._fieldType.id,
                        filePath = applicationContextRoot + '/dp/file/' + scope.clientId + '/' + row['_entity_'] + '/' + row['_id'] + '/'+fieldTypeId+'/' + row[column.name][0]['fileId'];

                    PreviewService.getAttachmentFile(filePath)
                        .then(function (response) {
                            Utils.downloadFile(decodeURIComponent(fileName), response.data);
                        })
                        .catch(function (error) {
                            console.log("ERROR");
                            console.log(error);
                        })
                        .finally(function () {
                            LoaderService.stopLoading();
                        });
                }
            }

            function isPreviewEnabled(rows, column){
                var fileType, mimeType = 'Not Supported',
                    dataType = (column.fieldMetadata)? column.fieldMetadata.dataType : column._fieldType.dataType,
                    row = (rows[column.name])? rows : rows[column.recordTypeId];
                if(dataType === 'Attachment' && row[column.name]) {
                    fileType = PreviewService.getFileType(row[column.name][0]['fileName']);
                    mimeType = PreviewService.getMimeType(fileType);
                }
                return mimeType !== 'Not Supported';
            }

            function viewAttachments(rows, column, index) {
                LoaderService.startLoading();
                var fileType, mimeType = "Not Supported",
                    fileDataUrl, fileBlobData, fileBlobURL,
                    columnName = column.name,
                    fieldTypeId = (column.fieldTypeId)? column.fieldTypeId : column._fieldType.id,
                    row = (rows[column.name])? rows : rows[column.recordTypeId];

                if(row[columnName])
                {
                    fileType = PreviewService.getFileType(row[columnName][0]['fileName']);
                    mimeType = PreviewService.getMimeType(fileType);
                    fileDataUrl = applicationContextRoot + '/dp/file/' + scope.clientId + '/' + row['_entity_'] + '/' + row['_id'] + '/'+fieldTypeId+'/' + row[columnName][0]['fileId'];
                }
                if(mimeType !== "Not Supported") {

                    PreviewService.getAttachmentFile(fileDataUrl)
                        .then(function(response){
                            fileBlobData = new Blob([response.data], {type: mimeType});
                            if(mimeType==="text/html") {
                                var reader = new FileReader();
                                reader.onload = function (e) {
                                    var readerData = $sanitize(reader.result);
                                    fileBlobData = new Blob([readerData], {type: mimeType});
                                    fileBlobURL = window.URL.createObjectURL(fileBlobData);
                                    angular.element('#' + index + '_viewDocFrameObject').attr("data", fileBlobURL);
                                    angular.element('#' + index + '_viewDocFrameObject').attr("type", mimeType);
                                    angular.element('#' + index + '_viewAttachmentsModel').modal();
                                    setTimeout(function () {
                                        angular.element('.modal-backdrop').remove();
                                    }, 300);
                                }
                                reader.readAsText(fileBlobData);
                            }else {
                                fileBlobURL = window.URL.createObjectURL(fileBlobData);
                                if (mimeType === "image/jpeg") {
                                    mimeType = "image/jpg";
                                }
                                angular.element('#' + index + '_viewDocFrameObject').attr("data", fileBlobURL);
                                angular.element('#' + index + '_viewDocFrameObject').attr("type", mimeType);
                                angular.element('#' + index + '_viewAttachmentsModel').modal();
                                setTimeout(function () {
                                    angular.element('.modal-backdrop').remove();
                                }, 300);
                            }
                        })
                        .catch(function(error){
                            console.log("ERROR");
                            console.log(error);
                        })
                        .finally(function(){
                            LoaderService.stopLoading();
                        });
                } else {
                    angular.element('#'+index+'_viewDocFrameObject').attr("data", "Not Supported!!!");
                    angular.element('#'+index+'_viewDocFrameObject').attr("type", "Not Supported");
                    angular.element('#'+index+'_viewAttachmentsModel').modal();
                    LoaderService.stopLoading();
                    setTimeout(function () {
                        angular.element('.modal-backdrop').remove();
                    },300);
                }
            }
        }
        function template(element, attrs){
            return '<span>' +
                        '<span ng-if="isPreviewEnabled(row, column)"' +
                                'class="glyphicon glyphicon-new-window openViewAttachmentsModel" ng-click="viewAttachments(row, column, rowIndex)"></span>' +
                           '<div class="modal fade view-attachment-modal" id="{{rowIndex}}_viewAttachmentsModel" tabindex="-1" role="dialog">' +
                                '<div class="modal-dialog view-attachment-dialog">' +
                                    '<div class="modal-content"> ' +
                                        '<div class="modal-body" id="{{rowIndex}}_viewDocFrameContainer">' +
                                            '<object id="{{rowIndex}}_viewDocFrameObject" class="view-attachment-body"  frameborder="0"> </object>' +
                                        '</div>' +
                                        '<div class="modal-footer">' +
                                            '<button type="button" class="btn btn-default" data-dismiss="modal" translate="BTN_CLOSE">Close</button>' +
                                            '<button type="button" id="{{rowIndex}}_viewAttachmentDownloadButton" ' +
                                                    'ng-click="downloadFile(row, column)"' +
                                                    'class="btn btn-primary" data-dismiss="modal" translate="BTN_DOWNLOAD">Download</button>' +
                                        '</div>' +
                                    '</div>' +
                                '</div>' +
                           '</div>' +
                   '</span>';
        }
    }
})();