(function() {
    'use strict';

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableEditableRow', alphaSmartTableEditableRow);

    alphaSmartTableEditableRow.$inject = [
        'Events'
    ]

    function alphaSmartTableEditableRow(Events) {
        return {
            link: link,
            restrict: 'AE',
            templateUrl: applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/alphaSmartTableEditableRow.directive.html',
            scope: true
        };

        function link(scope, element, attrs) {
            scope.isReadOnlyField = isReadOnlyField;
            scope.getCurrencyValue = getCurrencyValue;
            scope.isStringField = isStringField;
            scope.getInputMask = getInputMask;
            scope.publishRecordChangeEvent = publishRecordChangeEvent;
            scope.isRecordTypeColumn = isRecordTypeColumn;
            scope.applyRedaction = applyRedaction;

            function isReadOnlyField(column) {
                var readOnly = false;
                //In case of entire record is not editable
                if(!canEditRecordInGrid()) {
                    return true;
                }
                if (column.fieldMetadata && (column.fieldMetadata.dataType === 'Location' || scope.isAssociatedLayoutIsCorrelated(column.fieldMetadata))) {
                    return true;
                }
                if(scope.data._rowLevelSecurity && scope.data._rowLevelSecurity.finalAccess < 3){
                    readOnly = true;
                } else if (_.get(column.fieldMetadata, 'enableReadOnlyBrRule') || (_.get(column.fieldMetadata, 'formulaType') && _.get(column.fieldMetadata, 'formulaType') !== 'NonFormula')) {
                    readOnly = true;
                } else if(applyRedaction(column)){
                    readOnly = true;
                } else if(column.fieldMetadata._metadata){
                    //Check for security override
                    var value = column.fieldMetadata._metadata [scope.data._entity_];
                    if(_.isObject(value)){
                        if(value.Unavailable === null || value.ReadOnly === null){
                            readOnly = true;
                        }
                    } else { //Check grid field level security for the grid record type.
                        value = column.fieldMetadata._metadata [column.fieldMetadata.gridRecordTypeId];
                        if(_.isObject(value)){
                            if(value.Unavailable === null || value.ReadOnly === null){
                                readOnly = true;
                            }
                        }
                    }
                }
                return readOnly;
            }

            function getCurrencyValue(data, column) {
                var rowData = data[column.name];
                if(column.fieldMetadata.dataType === 'SummarizedCurrency'){
                    rowData.amount = rowData.total;
                }
                return rowData;
            }

            function isStringField(column) {
                return column.fieldMetadata && (column.fieldMetadata.dataType === 'String' || column.fieldMetadata.dataType === 'LargeTextField'
                       || column.fieldMetadata.dataType === 'Location' || column.fieldMetadata.dataType === 'CurrencyType'
                       || column.fieldMetadata.id === '_entity_' || column.fieldMetadata.id === '_updatedByUser'
                       || column.fieldMetadata.id === '_createdByUser' || column.fieldMetadata.id === '_entityDescription')
                        && (column.fieldMetadata.widgetType !== 'DocumentLink' && column.fieldMetadata.widgetType !== 'DigitalLink'
                        && column.fieldMetadata.widgetType !== 'Hyperlink');
            }

            function canEditRecordInGrid() {
                return !scope.data.anonymised;
            }

            function getInputMask(fieldType) {
                switch (fieldType.widgetType) {
                    case 'SSN':
                        return '999-99-9999';
                    case 'Phone':
                        return '(999) 999-9999';
                    case 'Zip':
                        return '99999';
                    case 'CustomMask':
                        return fieldType.customStringMask ;
                    default:
                        return "";
                }
            }

            function publishRecordChangeEvent() {
                Events.publish('editableGrid', 'editableGridModified', scope.data);
            }

            function isRecordTypeColumn(column) {
                return column.name === '_entity_';
            }

            function applyRedaction(column){
                return _.get(column.fieldMetadata, 'pii_mask') === null;
            }

            scope.$watch(attrs.columns, function (newVal) {
                scope.columns = newVal;
            });

            scope.$watch(attrs.recordData, function (newVal) {
                scope.data = newVal;
            });
        }

    }

})();
