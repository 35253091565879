(function() {
    'use strict';

    /**
     * Show the configured ancestor of the lookup value given by the alphaLookupFieldValuePath attribute.
     *
     * Note:  The alphaLookupFieldValuePath attribute is a one-way binding.
     *
     * @method alphaLookupTreeAncestor
     *
     * @example
     *      HTML:
     *      <input alpha-lookup-tree-ancestor
     *             id="some_id"
     *             alpha-lookup-tree-ancestor
     *             alpha-lookup-library="RecordTypeFields"
     *             alpha-lookup-library-record-type="some_record_type"
     *             alpha-lookup-library-field-type="some_field_type"
     *             alpha-lookup-parent-level="3"
     *             alpha-lookup-field-value-path="someCtrl.modelValue">
     */

    angular
        .module('alpha.common.alphaLookupTree')
        .directive('alphaLookupTreeAncestor', alphaLookupTreeAncestor);

    alphaLookupTreeAncestor.$inject = ['AlphaLookupTreeService', '$log'];

    function alphaLookupTreeAncestor(AlphaLookupTreeService, $log) {
        var readOnlyClass = 'readonly';
        return {
            link: link,
            restrict: 'A',
            alphaLookupFieldValuePath: '<'
        };
        function link(scope, element, attrs) {
            scope.$watch(attrs.alphaLookupFieldValuePath, function(newVal, oldVal) {
                if(_.has(newVal, 'id')) {
                    _fetchLookups(newVal);
                } else { // User has cleared the lookup selection in alphaLookupTree directive field
                    _setValue(null);
                }
            });

            _enableReadOnly();

            function _enableReadOnly() {
                element.addClass(readOnlyClass).prop('readonly', true).prop('disabled', true);
            }

            function _setValue(value) {
                element.val(value)
            }

            function _fetchLookups(data) {
                AlphaLookupTreeService.getRoot(
                    attrs.alphaLookupLibrary,
                    attrs.alphaLookupLibraryRecordType,
                    attrs.alphaLookupLibraryFieldType,
                    data.id,
                    true,
                    false
                )
                .then(function(library) {
                    scope.lookupValues = library;
                    _setValue(_getLookupParent(data).value)
                })
                .catch(function(reason) {
                    $log.log(reason);
                });

                // Get the configured ancestor level based on alphaLookupParentLevel
                function _getLookupParent(data) {
                    var pathToRoot = [],
                        lookupNode,
                        index;

                    _augmentNodes(scope.lookupValues.children);
                    _populatePathToRoot(lookupNode);

                    // Clamp the index.  If alphaLookupParentLevel is 3 but the lookup value selected
                    // for alphaLookupFieldValuePath by the sister field is at level 2, this will give
                    // index out of bounds/undefined since pathToRoot array will not have enough elements.
                    index = attrs.alphaLookupParentLevel - 1;
                    if(index > (pathToRoot.length-1)) {
                        index = pathToRoot.length-1;
                    }

                    return pathToRoot.reverse()[index];

                    // set parent of each node to make finding parent node easier
                    function _augmentNodes(nodes) {
                        _.forEach(nodes, function(node) {
                            if (node.id === data.id) {
                                lookupNode = node;
                            }
                            if (node.children) {
                                _.forEach(node.children, function(childNode) {
                                    childNode.parent = node;
                                });
                                node.expanded = _augmentNodes(node.children);
                            }
                        });
                    }

                    function _populatePathToRoot(node) {
                        if(!_.isUndefined(node)) {
                            pathToRoot.push(node);
                            _populatePathToRoot(node.parent);
                        }
                    }
                }
            }
        }
    }
})();
