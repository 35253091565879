(function() {
    'use strict';

    /**
     * Blocks an element from the user
     *
     * @method alpha-blocked
     *
     * @example
     *      HTML:
     *      <div alpha-blocked="someBoolean"></div>
     *
     * @param {Boolean} alpha-blocked Whether the element is blocked
     */

    angular
        .module('alpha.common.directives.alphaBlocked', [])
        .directive('alphaBlocked', alphaBlocked);

    function alphaBlocked() {
        return {
            restrict: 'A',
            link: link
        };
        function link(scope, element, attrs) {
            scope.$watch(attrs.alphaBlocked, function(val) {
                if (val === true) {
                    element.block({message: null});
                } else if (val === false) {
                    element.unblock();
                }
            });
        }
    }
})();
