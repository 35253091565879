(function () {
    'use strict';

    angular
        .module('alpha.common.services.alphaDocPreviewDownload',[])
        .factory('AlphaDocPreviewDownloadService', AlphaDocPreviewDownloadService);

    AlphaDocPreviewDownloadService.$inject = [
        '$http',
        'Utils',
        '$q',
        'AlphaLocalStorageService'
    ];

    function AlphaDocPreviewDownloadService(
        $http,
        Utils,
        $q,
        AlphaLocalStorageService
    ) {
        return {
            getFileType: getFileType,
            getMimeType: getMimeType,
            getAttachmentFile: getAttachmentFile,
            getAttachmentFilePreview: getAttachmentFilePreview,
            getAllDisplayAbleExtensions: getAllDisplayAbleExtensions,
            loadAllDisplayAbleExtensionsList: loadAllDisplayAbleExtensionsList,
            isBrowserNativeFileType: isBrowserNativeFileType,
        };

        function getFileType(fileName) {
            if(fileName) {
                var fileType =  fileName.split(".").pop();
                return fileType.toLowerCase();
            }
        }

        function getMimeType(fileType) {
            var file_type = fileType.toLowerCase();
            switch(file_type) {
                case "pdf":
                    return "application/pdf";
                case "jpg":
                case "jpeg":
                    return "image/jpeg";
                case "png":
                    return "image/png";
                case "htm":
                case "html":
                    return "text/html";
                case "java":
                case "sql":
                case "txt":
                case "log":
                case "asc":
                case "csv":
                    return "text/plain";
                case "doc":
                case "docx":
                case "ppt":
                case "tif":
                case "tiff":
                case "xls":
                case "zip":
                case "rar":
                case "msg":
                case "xlsm":
                case "xlsx":
                case "rtf":
                case "pptx":
                case "vsd":
                case "mpp":
                case "dotx":
                case "xlt":
                case "vss":
                case "xltx":
                case "tar":
                case "gz":
                case "pub":
                case "ods":
                    return "application/pdf";
                default:
                    return 'Not Supported';
            }
        }

        function loadAllDisplayAbleExtensionsList() {
            var allDisplayableExtensions = AlphaLocalStorageService.getDataFromStorage('alphaDisplayableExtensions');
            if (_.isEmpty(allDisplayableExtensions)) {
                $http.get(applicationContextRoot + '/dp/file/getAllDisplayableExtensions').then(function (response) {
                    AlphaLocalStorageService.setDataInStorage('alphaDisplayableExtensions', response.data);
                });
            }
        }

        function getAllDisplayAbleExtensions() {
            var defered = $q.defer();
            var allDisplayableExtensions = AlphaLocalStorageService.getDataFromStorage('alphaDisplayableExtensions');
            if (_.isEmpty(allDisplayableExtensions)) {
                $http.get(applicationContextRoot + '/dp/file/getAllDisplayableExtensions').then(function (response) {
                    AlphaLocalStorageService.setDataInStorage('alphaDisplayableExtensions', response.data);
                    defered.resolve(response.data);
                });
            } else {
                defered.resolve(allDisplayableExtensions);
            }

            return defered.promise;
        }

        function getAttachmentFile(filePath, fileType){
            var deferred = $q.defer();
            $http(_getConfig(filePath, false, fileType))
                .then(function(response){
                    deferred.resolve(response);
                })
                .catch(function(error){
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function getAttachmentFilePreview(filePath, fileType){
            var deferred = $q.defer();
            $http(_getConfig(filePath, true, fileType))
                .then(function(response){
                    deferred.resolve(response);
                })
                .catch(function(error){
                    deferred.reject(error);
                });
            return deferred.promise;
        }

        function isBrowserNativeFileType(fileType) {
            var browserNativeFileTypes = ["csv", "htm", "html", "java", "jpg", "jpeg", "png", "sql", "txt", "pdf", "log", "asc"];

            return !_.isEmpty(fileType) && browserNativeFileTypes.indexOf(fileType) > -1;
        }

        function _getConfig(filePath, preview, fileType) {
            return {
                method: 'GET',
                url: filePath + '?preview=' + preview + '&ext=' + fileType,
                responseType: 'blob'
            };
        }
    }
})();