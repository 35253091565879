(function() {
    'use strict';
    angular
        .module('alpha.login.Login')
        .directive('totpLogin', totpLogin);

        var template = '' +
            '<div ng-if="holder.qrcode" id="qrcodediv" id="qrcode" class="qrcode">' +
                '<a><img ng-src="{{holder.qrcode}}"></a>' +
            '</div>';

        totpLogin.$inject = [
            'LoginService'
        ];

        function totpLogin(
            LoginService
        ) {
            return {
                restrict: 'E',
                template: template,
                link: link,
                scope: {
                    holder: '='
                }
            };

            function link(scope) {
                LoginService.getTotpDetail()
                    .then(function (data) {
                        scope.holder.qrcode = data.qrcodeUrl;
                    });
            }
        }
})();
