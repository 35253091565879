(function () {
    'use strict';
    angular
        .module('alpha.common.alphaSmartTable')
        .controller('ColumnSelectionController', ColumnSelectionController);

    ColumnSelectionController.$inject = ['AlphaSmartTableService', '$element'];

    function ColumnSelectionController(AlphaSmartTableService, $element) {
        var self = this;
        self.$onInit = function() {
            controllerInit(self, AlphaSmartTableService, $element);
        };
    }

    function controllerInit(vm, AlphaSmartTableService, $element) {
        var astCtrl = $element.controller('alphaSmartTable');
        vm.fieldForMeasureOptions = AlphaSmartTableService.getMappedFieldForMeasureColumns(vm.columns);
        vm.measureOptions = AlphaSmartTableService.getMeasureOptions(vm.columns);
        vm.seriesOptions = AlphaSmartTableService.getMappedSeriesColumns(vm.columns);
        vm.values = AlphaSmartTableService.getNumberTypeColumns(vm.columns);
        vm.categories = AlphaSmartTableService.getNumberAndDateTypeColumns(vm.columns);
        vm.run = run;
        vm.cancel = cancel;
        vm.isColumnSelected = isColumnSelected;
        vm.isVisible = isVisible;
        vm.isXAxisColumnSelected = isXAxisColumnSelected;
        vm.isYAxisColumnSelected = isYAxisColumnSelected;
        vm.isPie = isPie;
        vm.isValidSelection = isValidSelection;
        vm.isFieldForMeasureVisible = isFieldForMeasureVisible;

        _init();

        function run(){
            if(isPie()){
                vm.chartColumns = {
                    xAxis: AlphaSmartTableService.getSeriesColumn(vm.columns, vm.seriesColumn),
                    yAxis: vm.measure === 'COUNT' ? [] : AlphaSmartTableService.getFieldForMeasureColumn(vm.columns, vm.fieldForMeasureColumn)
                };
            }else{
                vm.chartColumns = {
                    xAxis: AlphaSmartTableService.getXAxisSelectedColumns(vm.columns),
                    yAxis: AlphaSmartTableService.getYAxisSelectedColumns(vm.columns)
                };
                if(_.isUndefined(vm.measure) || _.isNull(vm.measure)) {
                    vm.measure = _.isObject(vm.chartColumns.xAxis[0]) ? 'SUM' : null;
                }
            }
            vm.columnSelectionVisible = false;
            if (astCtrl.serverSideMode) {
                astCtrl.setChartConfig({
                    chartType: vm.type.toUpperCase(),
                    aggregateType: vm.measure || null,
                    columnsSelected: {
                        xAxis: _.get(vm.chartColumns, 'xAxis[0].fieldTypeId') || null,
                        yAxis: _.get(vm.chartColumns, 'yAxis[0].fieldTypeId') || null
                    }
                });
            }
        }
        function cancel(){
            vm.columnSelectionVisible = false;
        }
        function isPie(){
            return AlphaSmartTableService.isPieChart(vm.type);
        }
        function isColumnSelected(){
            return AlphaSmartTableService.isChartColumnSelected(vm.seriesColumn);
        }
        function isValidSelection(){
            return AlphaSmartTableService.isValidColumnSelection(vm.type, vm.columns, vm.seriesColumn, vm.measure, vm.fieldForMeasureColumn);
        }
        function isXAxisColumnSelected(){
            return AlphaSmartTableService.isXAxisChartColumnSelected(vm.columns);
        }
        function isYAxisColumnSelected(){
            return AlphaSmartTableService.isYAxisChartColumnSelected(vm.columns);
        }
        function isVisible(column){
            return AlphaSmartTableService.isChartColumn(column);
        }
        function isFieldForMeasureVisible(){
            return isColumnSelected() && AlphaSmartTableService.isFieldForMeasureVisible(vm.type, vm.measure, vm.fieldForMeasureOptions);
        }
        function _init(){
            if(isPie()){
                vm.seriesColumn = !_.isEmpty(vm.chartColumns.xAxis) ? vm.chartColumns.xAxis[0].fieldTypeId : '';
                vm.fieldForMeasureColumn =  !_.isEmpty(vm.chartColumns.yAxis) ? vm.chartColumns.yAxis[0].fieldTypeId : '';
            }else if(AlphaSmartTableService.isBarOrLineChart(vm.type)){
                _.forEach(vm.chartColumns.xAxis, function(chartColumn){
                    var column = _.find(vm.categories, {fieldTypeId: chartColumn.fieldTypeId});
                    if(_.isObject(column)){
                        column.xSelected = true;
                    }
                });
                _.forEach(vm.chartColumns.yAxis, function(chartColumn){
                    var column = _.find(vm.values, {fieldTypeId: chartColumn.fieldTypeId});
                    if(_.isObject(column)){
                        column.ySelected = true;
                    }
                });
            }
        }
    }
})();
