(function () {
    'use strict';
    angular
        .module('alpha.common.alphaSmartTable')
        .controller('AlphaSmartTableMasterDetailSearchController', AlphaSmartTableMasterDetailSearchController)
        .filter('filterableColumns', filterableColumns);


    AlphaSmartTableMasterDetailSearchController.$inject = [
        '$uibModalInstance',
        'SavedSearchService',
        '$scope',
        'queryName',
        'searchColumnsList',
        'filters',
        'callback',
        'cancelDialog',
        'currentMasterFilters'
    ];

    function AlphaSmartTableMasterDetailSearchController(
        $uibModalInstance,
        SavedSearchService,
        $scope,
        queryName,
        searchColumnsList,
        filters,
        callback,
        cancelDialog,
        currentMasterFilters
        ) {
        var vm = this;
        vm.filters = filters;
        vm.queryName = queryName;
        vm.searchColumnsList = searchColumnsList;
        vm.callback = callback;
        vm.cancelDialog = cancelDialog;
        vm.currentMasterFilters = currentMasterFilters;
        vm.applyFilters = applyFilters;
        vm.isOperatorMultiple = isOperatorMultiple;
        vm.filterableColumns = filterableColumns;
        vm.getOperatorOptions = getOperatorOptions;
        vm.options = {};

        function markDefault(key){
            setTimeout(function() {
                var defaultOperator = SavedSearchService.getSelectDefaultOperator(vm.filters[key]._fieldType);
                $('#__' + key +'__operator_input').val(defaultOperator.id).trigger('change');
                $('#s2id___' + key +'__operator_input .select2-chosen').text(defaultOperator.value);
                $('#s2id___' + key + '__operator_input .select2-search-choice-close').addClass('show-element');
                $('#s2id___' + key +'__operator_input .select2-chosen').css('color', '#555')
            });
        }
        for(var key in filters){
            vm.options[key] = {};
            vm.options[key]['list'] = SavedSearchService.getOperatorOptions(filters[key]._fieldType);
            if (filters[key]._fieldType.lookupType === 'Tree') {
                vm.options[key]['list'].splice(2, 2);
            }
            markDefault(key);
        }
        vm.operatorHasValue = operatorHasValue;
        vm.operatorHasRange = operatorHasRange;
        vm.operatorHasLiteralValue = operatorHasLiteralValue;
        vm.ruleHasLevelOperator = ruleHasLevelOperator;
        vm.isRecordTypeFilter = isRecordTypeFilter;
        vm.operatorHasStringValue = operatorHasStringValue;
        vm.getInputDataType = getInputDataType;
        vm.setDefaultValueForFilter = setDefaultValueForFilter;
        vm.cancelMasterSearch = cancelMasterSearch;

        function cancelMasterSearch() {
            $uibModalInstance.close();
            vm.cancelDialog();
        }


        function isOperatorMultiple(operator) {
            return SavedSearchService.isOperatorMultiple(operator);
        }

        function getOperatorOptions(fieldType) {
            var operators = SavedSearchService.getOperatorOptions(fieldType);
            if (fieldType.lookupType === 'Tree') {
                operators.splice(2, 2);
            }
            return operators;
        }

        function operatorHasValue(operator) {
            return SavedSearchService.operatorHasValue(operator)
        }

        function operatorHasLiteralValue(operator) {
            return SavedSearchService.operatorHasLiteralValue(operator);
        }

        function ruleHasLevelOperator(operator) {
            return SavedSearchService.ruleHasLevelOperator(operator);
        }

        function isRecordTypeFilter(filter) {
            return SavedSearchService.isRecordTypeFilter(filter);
        }

        function operatorHasStringValue(operator) {
            return SavedSearchService.operatorHasStringValue(operator);
        }

        function getInputDataType(filter) {
            return SavedSearchService.getInputDataType(filter);
        }

        function operatorHasRange(operator) {
            return SavedSearchService.operatorHasRange(operator);
        }

        function setDefaultValueForFilter(filter) {
            if (filter != undefined) {
                if (!operatorHasValue(filter.operator)) {
                    delete filter.value;
                } else if (operatorHasLiteralValue(filter.operator)){
                    if(filter.value != undefined && isString(filter.value) && filter._fieldType.lookupType === 'Tree'){
                        delete filter.value;
                    }
                    else if(!isString(filter.value) && filter._fieldType.lookupType === 'Tree'){
                        if(!(filter.operator.id === 'NOT_EQUALS' || filter.operator.id === 'EQUALS')){
                            delete filter.value
                        }
                    }
                }
                else if (!operatorHasRange(filter.operator)){
                    if(!(isString(filter.value))){
                        delete filter.value
                    }
                    delete filter.maxValue;
                }
            }
        }

        function isString(value){
            return (typeof value == "string" || (typeof value == "object" && value.constructor === String));
        }

        function applyFilters() {
            _applyMasterFilter();
        }

        function _applyMasterFilter() {
            var filters = [];
            _.forOwn(vm.filters, function (filter, columnName) {
                    var column = _.find(vm.searchColumnsList, {name: columnName});
                    var value = filter.value;
                    var entityArray = [];
                    if (column != undefined) {
                        if (column.fieldTypeId === '_entity_' && !_.isEmpty(value)) {
                            if (filter.operator && (filter.operator.id === 'IN' || filter.operator.id === 'NOT_IN')) {
                                _.forEach(value, function (val) {
                                    entityArray.push(val.id);
                                });
                            } else {
                                entityArray.push(value.id);
                            }
                        }
                        var term = SavedSearchService.getTermFromFilterWithIncludeOptions(filter, column, entityArray, false, true);
                        if(term) {
                            filters.push(term);
                        }
                    }
                }
            );
            vm.currentMasterFilters(vm.filters, vm.queryName);
            var changes = {terms: filters, queryName: vm.queryName}
            $uibModalInstance.close();
            _masterCallback(changes);
        }

        function _masterCallback(changes) {
            if(changes && changes.terms) {
                vm.callback(changes);
            }
        }
    }

    function filterableColumns() {
        return function (columns) {
            return _.filter(columns, function (column) {
                return column.fieldMetadata && _.includes(['String', 'LargeTextField', 'LookupLibrary', 'Number', 'SummarizedNumber', 'Currency', 'SummarizedCurrency', 'Attachment'], column.fieldMetadata.dataType);
            });
        };
    }

})();
