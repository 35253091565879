(function () {
    'use strict';
    angular
        .module('alpha.common.services.authentication', [
            'AlphaApi',
            'ui.bootstrap',
            'alpha.utils.LogOutAction',
            'alpha.common.services.alphaTabManager',
            'alpha.utils.I18n'
        ])
        .factory('AuthenticationService', AuthenticationService);

    AuthenticationService.$inject = [
        '$q',
        'SecurityInterface',
        '$templateRequest',
        '$uibModal',
        'DataModelDesignInterface',
        'AlphaTabManagerService',
        'I18nUtil',
        '$http',
        'LoaderService'
    ];

    function AuthenticationService(
        $q,
        SecurityInterface,
        $templateRequest,
        $uibModal,
        DataModelDesignInterface,
        AlphaTabManagerService,
        I18nUtil,
        $http,
        LoaderService
    ) {
        var _digitalProperties;
        return {
            authenticateUser : authenticateUser,
            checkUserAuthority: checkUserAuthority,
            openAuthenticatedModal : openAuthenticatedModal,
            isAuthenticationExternal: isAuthenticationExternal,
            isAuthenticationSSO: isAuthenticationSSO,
            openDigitalLinkWithToken: openDigitalLinkWithToken,
            openDigitalLink: openDigitalLink
        };

        function authenticateUser(){
            var deferred = $q.defer();
            SecurityInterface.getAuthorizedUser(function success(response){
                deferred.resolve(response);
            }, function error(response){
                deferred.reject(response.data.errorMessage);
            });
            return deferred.promise;
        }
        function openAuthenticatedModal(options) {
            var deferred = $q.defer();
            authenticateUser()
                .then(function () {
                    deferred.resolve($uibModal.open(options).result);
                })
                .catch(function (reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function checkUserAuthority(client, authority){
            var deferred = $q.defer();
            SecurityInterface.hasAuthority(
                client,
                authority,
                function success(authority) {
                    deferred.resolve(authority);
                }, function error(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function isAuthenticationExternal() {
            var deferred = $q.defer();
            authenticateUser()
                .then(function(user) {
                    deferred.resolve(user.userType!=="ExternalUser");
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }
        function isAuthenticationSSO() {
            var deferred = $q.defer();
            authenticateUser()
                .then(function(user) {
                    deferred.resolve([user.userType==="SSO", user.ventivOneUser === true]);
                })
                .catch(function(reason) {
                    deferred.reject(reason);
                });
            return deferred.promise;
        }

        function openDigitalLinkWithToken(url, fldId) {
            //if Digital properties already loaded we can re-use that
            if(_digitalProperties == null || _.isEmpty(_digitalProperties.token)) {
                getDigitalProperties().then(function () {
                    generateDigitalAuthToken().then(function (token) {
                        openDigitalUrl(url, fldId, token);
                    })
                        .catch(function (reason) {
                            $uibModal.open(reason);
                        });
                });
            } else {
                generateDigitalAuthToken().then(function (token) {
                    openDigitalUrl(url, fldId, token);
                })
                    .catch(function (reason) {
                        $uibModal.open(reason);
                    });
            }
        }

        function openDigitalLink(row, col) {
            var digitalLink = col.getter ? col.getter(row) : row[col.name];
            var fldId = row._id + "" + col.fieldTypeId;
            if(!digitalLink) {
                $uibModal.open(I18nUtil.getI18nString("DIGITAL_URL_MISSING"));
                return;
            }
            var digital = {};
            digital.url = digitalLink;
            digital.fldId = fldId;
            LoaderService.startLoading();
            openDigitalLinkWithToken(digital.url, digital.fldId);
            LoaderService.stopLoading();
        }

        function openDigitalUrl(url, fldId, token) {
            if(url != null) {
                var finalUrl = url;
                if(finalUrl.includes("?")) {
                    finalUrl = finalUrl + "&irm_token=" + token;
                } else {
                    finalUrl = finalUrl + "?irm_token=" + token;
                }
                var tabId = "" + fldId;
                var i18nTitle = I18nUtil.getI18nString('DIGITAL_LINK', 'Digital Link');
                AlphaTabManagerService.addTab(tabId, i18nTitle, finalUrl);
            }
        }
        function getDigitalProperties() {
            var deferred = $q.defer();
            DataModelDesignInterface.getDigitalProperties(
                function success(response) {
                    _digitalProperties = response;
                    deferred.resolve(response);
                },
                function error(response) {
                    deferred.reject(response.data.errorMessage);
                }
            );
            return deferred.promise;
        }

        function generateDigitalAuthToken() {
            var deferred = $q.defer();
            $http.post(_digitalProperties.hostname + '/auth/irm', {token: _digitalProperties.token}, {headers: {'Content-Type': 'application/json'}})
                .then(function(response) {
                    deferred.resolve(response.data.token);
                })
                .catch(function(response) {
                    deferred.reject(response);
                });
            return deferred.promise;
        }
    }
})();
