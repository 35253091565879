/**
 * @module alpha.directives
 */

/**
 * Project-level generic directives
 *
 * @class alphaDirectives
 */
angular.module('alpha.directives', ['alpha.directives.messageHolder',
                                    'alpha.directives.aonId',
                                    'alpha.directives.ngModelUpdateOnblur',
                                    'alpha.directives.aonLookup',
                                    'alpha.directives.aonRadioGroup',
                                    'alpha.directives.recordTree',
                                    'alpha.directives.ventivMaskedInput',
                                    'alpha.directives.ventivSelectValue',
                                    'alpha.directives.ventivRestrictedInput',
                                    'alpha.directives.ventivTooltip',
                                    'alpha.directives.aonLookupCheckbox',
                                    'alpha.directives.ventivFileDownload',
                                    'alpha.external.directives',
                                    'alpha.directives.isolateForm']);

angular.module('alpha.external.directives', ['pascalprecht.translate']);

/**
 * Helper directive for identifying when tags have been processed by angular.  Created specifically to
 * assist automated tests to wait for ui-view and other UI elements to be present.  When angular
 * processes the element, the 'id' attribute will be set to the 'aon-id' attribute.
 *
 * Place one of these directives at the bottom of every page, ui-view, or partial that a test will need
 * to look at.
 *
 * @method aonId
 *
 * @example
 *      <div aon-id="pageX"></div>
 *
 * @param {Object} aonId the value to set the element's id to during processing
 */
angular.module('alpha.directives.aonId', []).directive('aonId', ['$timeout', function($timeout) {
    return {
        priority: -999,
        restrict: 'A',
        link: function(scope, element, attrs) {
            if (!attrs.aonId) {
                console.warn("An aonId element did not provide a value in the attr, creating a NOOP situation.  Search the source for empty aon-id attrs.");
            };
            if (attrs.id) {
                console.warn("Skipping processing of aon-id='" + attrs.aonId + "' because an id is already present ('" + attrs.id + "')");
            };

            $timeout(function() {
                attrs.$set('id', attrs.aonId);
            }, 0);
        }
    };
}]);

/**
 * Displays messages
 *
 * @method messageHolder
 *
 * @example
 *      <message-holder holder="aMessageHolder"></message-holder>
 *
 * @param {Object} holder the holder of messages
 *      @param {Array} holder.messages list of messages
 *          @param {Object} holder.messages.message a message
 *              @param {String} holder.messages.message.messageType the message type [Error|Warning|Info]
 *              @param {String} holder.messages.message.text the message text
 */
angular.module('alpha.directives.messageHolder', ['ui.bootstrap.alert']).directive('messageHolder', function() {
    return {
        restrict:'EA',
        template: '<div ng-repeat="message in holder.messages">' +
        '    <alert type="message.messageType.toLowerCase()" close="holder.messages.splice($index, 1)">{{message.text}}</alert>' +
        '</div>',
        replace:true,
        scope: {
            holder: '='
        }
    };
});

/**
 * Using this attribute causes the backing model to be updated during on blur events rather than on keydown
 *
 * @method ngModelUpdateOnblur
 *
 * @example
 *      <input ng-model="modelVar" ng-model-update-onblur/>
 *
 * @example
 *      <input ng-model="modelVar" ng-model-update-onblur="someAction()")/>
 *
 * @param {Object} ng-model
 * @param {Expression} [ngModelUpdateOnblur] expression to evaluate (using scope.$eval()) during blur events
 */
angular.module('alpha.directives.ngModelUpdateOnblur', []).directive('ngModelUpdateOnblur', function() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, element, attrs, controller) {
            if (attrs.type === 'radio' || attrs.type === 'checkbox') return;

            element.unbind('input').unbind('keydown').unbind('change');
            element.bind('blur', function() {
                scope.$apply(function() {
                    controller.$setViewValue(element.val());
                    if (attrs.ngModelUpdateOnblur !== '') {
                        scope.$eval(attrs.ngModelUpdateOnblur);
                    };
                });
            });
        }
    };
});

/**
 * A directive wrapper for radio buttons that are bound to the Lookup Library concept.
 *
 * @method aon-radio-group
 *
 * @example
 *      HTML:
 *      <div id="FieldList" style="width: 100%"
 *             aon-radio-group
 *             aon-radio-group-library='RecordTypeFields'
 *             aon-radio-group-horizontal="true"
 *             aon-radio-group-placeholder="Please Select..."
 *             aon-radio-group-classes=' radio-box radio-font ...'
 *             aon-radio-group-filter="recordTypeId"
 *             aon-radio-group-filter-model="searchRequest.recordTypes"
 *             aon-radio-group-client-id="QA__Beta_Client"
 *             aon-radio-group-sortable="true"
 *             aon-radio-group-tree-flatten="false"
 *             aon-radio-group-scope-set-transformer="fieldTransformer"
 *             aon-radio-group-sort="ASC"
 *             ng-model="searchRequest.fieldList"/>
 *
 * @param {String}   ng-model Model to bind this radio to.
 * @param {String}   aon-radio-group-library ID of the Lookup Library that contains the data for this field.
 * @param {Boolean}  [aon-radio-group-horizontal] True for a horizontal layout. False for vertical. Defaults to true.
 * @param {String}   [aon-radio-group-classes] the class property for the individual radio fields.
 * @param {String}   [aon-radio-group-client-id] if specified, overrides default from user preferences. Value can be interpolated, e.g. "{{myModel.clientId}}"
 * @param {Function} [aon-radio-group-formatter] defaults to using the value property on each item
 * @param {String}   [aon-radio-group-placeholder] Placeholder text for this radios.  Defaults to "Loading..."
 * @param {Boolean}  [aon-radio-group-tree-flatten] Should the tree be flattened when searching / filtering this dropdown, or should the path to the root of the tree be included.  Defaults to true.
 * @param {String}   [aon-radio-group-scope-set-transformer] A method that will transform the values that are selected before setting into the scope ng-model.
 * @param {String}   [aon-radio-group-sort] If supplied, either 'ASC' or 'DESC'.  Otherwise values are returned in whatever order the DB returns them in.
 * @param {String}   [aon-radio-group-placeholder] Placeholder text when there is no value selected.
 * @param {String}   [enable-exclusive-objects] Should exclusiveObjects filter be sent in query.
 * @param {String}   [enable-lookup-security] Should lookup security filter be sent in query.
 */
angular.module('alpha.directives.aonRadioGroup', ['AlphaApi', 'UserPreferences', 'alpha.utils.Utils', 'alpha.utils.RequiredFieldUtils', 'alpha.common.services.ruleExecutionHelper'])
    .directive('aonRadioGroup', ['$compile', '$location', 'LookupInterface', 'UserPreferences', 'Utils', 'RequiredFieldService', 'RuleExecutionHelper', function($compile, $location, LookupInterface, UserPreferences, Utils, RequiredFieldService, RuleExecutionHelper) {
    var DEFAULT_FORMATTER = function(object) { return object.value; };
    var userPreferences = {};
    UserPreferences.getCurrentPreferences().then(function success(data) {
        userPreferences = data;
    });
    var readOnlyClass = 'readonly';
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            containerId: '@id'
        },
        template: template,
        link: function(scope, element, attrs, ngModelCtrl) {
            var list, _readOnlyMode, _readOnlyState;
            scope.lookupValue = {};
            scope.dataReady = false;
            scope.radioFormatter = scope.$eval(attrs.aonRadioGroupFormatter) ||  DEFAULT_FORMATTER;
            scope.lookupResults = [];
            scope.placeHolderText = attrs.aonRadioGroupPlaceHolder || 'Loading...';
            scope.updateLookupValue = updateLookupValue;
            scope.isLookupReadOnly = isLookupReadOnly;
            ngModelCtrl.$render = _render;
            ngModelCtrl.$formatters.unshift(_formatter);
            // The model value is an object, so it needs to be deep watched
            scope.$watch(function() {
                return ngModelCtrl.$modelValue;
            }, _getModelValue, true);
            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setReadOnly', 'setRequired'], attrs, _eventHandler);
            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    _readOnlyMode = true;
                    _enableReadOnly();
                } else {
                    _readOnlyMode = false;
                    if (!_readOnlyState) {
                        _disableReadOnly();
                    }
                }
            });
            function _eventHandler(data, event) {
                switch (event.topic) {
                    case 'setReadOnly':
                        _setReadOnly();
                        break;
                    case 'setRequired':
                        _setRequired();
                        break;
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
                function _setRequired() {
                    if (data.value === true) {
                        _enableRequired();
                    } else if (data.value === false) {
                        _disableRequired();
                    }
                }
            }
            function _enableReadOnly() {
                element.addClass(readOnlyClass);
                scope.isReadOnly = true;
            }
            function _disableReadOnly() {
                element.removeClass(readOnlyClass);
                scope.isReadOnly = false;
            }
            function _enableRequired() {
                ngModelCtrl.$validators.required = RequiredFieldService.isRequiredFieldValid;
                ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
            }
            function _disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }
            function _getModelValue() {
                ngModelCtrl.$modelValue = 0; // force the $formatters pipeline to run
            }
            function _formatter(modelValue){
                /*  On initial load, modelValue is a LookupValue object.
                    When the user clicks a radio button, modelValue is the 'value' of the radio input (which is the id of a lookupvalue, e.g. "Some_LookupValue_Servicename")
                */
                var viewValue;
                if(!_.isEmpty(modelValue)){
                    if (_.isString(modelValue)) {
                        viewValue = {
                            id: modelValue,
                            lookupId: null
                        };
                    } else if (_.isObject(modelValue)) {
                        viewValue = {
                            id: modelValue.id,
                            lookupId: modelValue.lookupId
                        };
                    }
                }
                return viewValue;
            }
            function updateLookupValue(lookupValue){
                ngModelCtrl.$setViewValue(angular.copy(lookupValue));
            }

            
            attrs.$observe('aonRadioGroupLibraryFieldType', function() {
                var filterVars = Utils.getLookupFilterVariablesAndValues(attrs.enableLookupSecurity, attrs.enableExclusiveObjects, attrs.aonRadioGroupLibraryFieldType);
                LookupInterface.getLookupLibraryDetailsFromCache(
                    attrs.aonRadioGroupClientId || userPreferences.client.id,
                    attrs.aonRadioGroupLibrary,
                    '',
                    filterVars.variables, // eg. ['fieldType', 'exclusiveObjects'],
                    filterVars.values, // eg. ['Record.FieldType', true],
                    attrs.aonRadioGroupTreeFlatten === 'false' ? false : true,
                    attrs.aonRadioGroupSort,
                    null,
                    false,
                    false,
                    _successCallback,
                    _errorCallback
                );
                function _successCallback(lookupResource) {
                    if (lookupResource.lookupLibrary.lookupType === 'List') {
                        scope.lookupResults = lookupResource.lookupLibrary.value;
                    } else if (lookupResource.lookupLibrary.lookupType === 'Tree') {
                        list = [];
                        _.forEach(lookupResource.lookupLibrary.value, function(element) {
                            if (element.level === 1) {
                               getLLChildren(element, list);
                            }
                        });
                        scope.lookupResults = list;
                    }
                    scope.dataReady = true;
                    _render();

                }
                function _errorCallback() {
                    scope.validationMessages = ['Fatal error, could not retrieve record data.'];
                }
            });

            function getLLChildren(obj, list) {
                list.push(obj);
                if(obj.children) {
                    _.forEach(obj.children, function(element) {
                        getLLChildren(element, list);
                    });
                }
            }

            function isLookupReadOnly(lookup){
                return (_.has(lookup, 'readLookupAuthority') && !lookup.readLookupAuthority) || (_.has(lookup,'updateLookupAuthority') && !lookup.updateLookupAuthority);
            }

            function _render() {
                if (scope.dataReady) {
                    if (_.isObject(ngModelCtrl.$viewValue)) {
                        scope.lookupValue = {
                            id: ngModelCtrl.$viewValue.id,
                            lookupId: ngModelCtrl.$viewValue.lookupId
                        };
                    } else {
                        element.find('input').attr('checked', false);
                    }
                }
            }
        }
    };
    function template(element, attrs){
        return  '<span>' +
                '   <span ng-if="lookupResults.length === 0" ng-bind="placeHolderText"></span>' +
                '   <span ng-repeat="item in lookupResults track by item.id">' +
                '       <br ng-if="$index > 0 && '+ attrs.aonRadioGroupHorizontal + ' === false" />' +
                '           <label class="radio-label">' +
                '               <input  id="{{containerId}}__{{item.id}}"   ' +
                '                       type="radio"                        ' +
                '                       class="input-field radio-font"      ' +
                '                       ng-disabled="isReadOnly || isLookupReadOnly(item)" ' +
                '                       ng-click="updateLookupValue(item)" ' +
                '                       ng-model="lookupValue.id"           ' +
                '                       ng-value="item.id">{{radioFormatter(item)}}</label>' +
                '   </span>' +
                '</span>';
    }
}]);

/**** TODO: This portion of the file follows the new style guide -- everything should take on this format eventually ****/

(function() {
    'use strict';

    // TODO: Documentation

    angular
        .module('alpha.directives.recordTree', ['AlphaApi', 'alpha.utils.Events', 'alpha.utils.DynaTree'])
        .directive('recordTree', recordTree);

    recordTree.$inject = ['Events', 'DynaTreeService'];

    function recordTree(Events, DynaTreeService) {
        return {
            link: link,
            restrict: 'A',
            require: '?ngModel'
        };
        function link(scope, element, attrs, controller) {
            var DEFAULT_ON_ACTIVATE = onActivate,
                DEFAULT_ON_CREATE = onCreate,
                onInitFn = attrs.onInit ? scope.$eval(attrs.onInit) : angular.noop;
            function setIds(node, nodeSpan) {
                var id = node.data.key;
                var expander = $(nodeSpan).children('.dynatree-expander');
                var link = $(nodeSpan).children('.dynatree-title');
                expander.attr('id', element.attr('id') + '-dynatree-expander-' + id);
                link.attr('id', element.attr('id') + '-dynatree-link-' + id);
            }
            function initDynaTreeService(node){
                var payload     = {};
                payload.divId   = element.attr('id');
                payload.node    = node;
                Events.publish('recordTree', 'init', payload);
                DynaTreeService.setTreeForId(payload);
                onInitFn(node);
            }
            function processNodeSelection(id, node){
                // this is called onActivate, the onActivate behavior is set in the controllers
                var payload     = {};
                payload.divId   = id;
                payload.node    = node;
                // publish node selection change
                Events.publish('recordTree', 'changed', payload);
                DynaTreeService.setTreeForId(payload);
            }
            function onActivate(node){
                processNodeSelection(attrs.id, node);
                scope.$apply(function (){
                    controller.$setViewValue(node.data.recordType);
                    controller.$render();
                });
            }
            function onCreate(node){
                var includeRoot = true,
                    tree = element.dynatree('getTree');
                initDynaTreeService(node);
                tree.visit(_setIdForAllNodes, includeRoot);

                function _setIdForAllNodes(currentNode) {
                    if (currentNode.span) {
                        setIds(currentNode, currentNode.span);
                    }
                }
            }
            scope.$watch(attrs.config, function() {
                var config = scope.$eval(attrs.config);
                if (_.isObject(config) && config.children) {
                    config.onActivate = config.onActivate || DEFAULT_ON_ACTIVATE;
                    config.onSelectItem = function(id, node){processNodeSelection(id, node);};
                    config.onCreate = config.onCreate || DEFAULT_ON_CREATE;
                    config.onDeactivate = function(node) { setIds(node,node.span); };
                    config.idPrefix = element.attr('id') + '-dynatree-';
                    config.debugLevel = 0;
                    config.classNames = { container: 'dynatree-container treeContainer' };
                    config.generateIds = true;
                    element.dynatree(config);
                    element.dynatree('getTree').reload();
                };
            }, true);
        }
    }

})();

(function() {
    'use strict';

    angular
        .module('alpha.config.ventivMaskedInput', [])
        .value('ventivMaskConfig', {
            'maskDefinitions': {
                '9': /\d/,
                'A': /[a-zA-Z]/,
                '*': /[a-zA-Z0-9]/
            }
        });

    angular.module('alpha.directives.ventivMaskedInput', ['alpha.config.ventivMaskedInput'])
        .directive('ventivMaskedInput', ventivMaskedInput);

    ventivMaskedInput.$inject = ['ventivMaskConfig', '$parse'];

    /**
     * This directive applies a mask to a form input.
     *
     *
     * @method ventivMaskedInput
     *
     * @example
     *      <input type="text"
     *             ventiv-masked-input="{{mask}}"
     *             placeholder="{{placeholder}}"
     *             class="my-class"
     *             ng-model="modelVar"
     *             maxlength="12"
     *             use-raw="false">
     *
     * @example
     *      <input ng-model="modelVar" ng-model-update-onblur="someAction()")/>
     *
     * @param {} [varname] [description]
     * @param {Object} ng-model
     * @param {Expression} [ngModelUpdateOnblur] expression to evaluate (using scope.$eval()) during blur events
     * @param {Boolean} [useRaw] Determines whether the raw or masked value is bound to $scope
     */
    function ventivMaskedInput(ventivMaskConfig, $parse) {
        return {
            priority: 100,
            require: 'ngModel',
            restrict: 'A',
            compile: compile
        };

        function compile() {
            var options = ventivMaskConfig;
            return function link(scope, element, attrs, controller) {
                var ie11 = !!window.MSInputMethodContext && !!document.documentMode,
                    maskProcessed = false,
                    eventsBound = false,
                    maskCaretMap, maskPatterns, maskPlaceholder, maskComponents,
                // Minimum required length of the value to be considered valid
                    minRequiredLength,
                    value, valueMasked, isValid,
                // Vars for initializing/uninitializing
                    originalPlaceholder = attrs.placeholder,
                    originalMaxlength = attrs.maxlength,
                // Vars used exclusively in eventHandler()
                    oldValue, oldValueUnmasked, oldCaretPosition, oldSelectionLength,
                // Vars for defining wether to return parsed value or viewValue
                    useRawValue,
                    linkOptions = {};

                checkOptions();
                observeAndWatch();
                element.bind('mousedown mouseup', mouseDownUpHandler);

                function initialize(maskAttr) {
                    if (!angular.isDefined(maskAttr)) {
                        return uninitialize();
                    }
                    processRawMask(maskAttr);
                    if (!maskProcessed) {
                        return uninitialize();
                    }
                    initializeElement();
                    bindEventListeners();
                    return true;
                }

                function initPlaceholder(placeholderAttr) {
                    if (!angular.isDefined(placeholderAttr)) {
                        return;
                    }

                    maskPlaceholder = placeholderAttr;

                    // If the mask is processed, then we need to update the value
                    if (maskProcessed) {
                        eventHandler();
                    }
                }

                function formatter(fromModelValue) {
                    if (!maskProcessed) {
                        return fromModelValue;
                    }
                    value = unmaskValue(fromModelValue || '');
                    isValid = validateValue(value);
                    controller.$setValidity('mask', isValid);
                    return isValid && value.length ? maskValue(value) : undefined;
                }

                function parser(fromViewValue) {
                    if (!maskProcessed) {
                        return fromViewValue;
                    }
                    value = unmaskValue(fromViewValue || '');
                    isValid = validateValue(value);
                    // We have to set viewValue manually as the reformatting of the input
                    // value performed by eventHandler() doesn't happen until after
                    // this parser is called, which causes what the user sees in the input
                    // to be out-of-sync with what the controller's $viewValue is set to.
                    controller.$viewValue = value.length ? maskValue(value) : '';
                    controller.$setValidity('mask', isValid);

                    // Transform the value to be the viewValue
                    if (useRawValue) {
                        value = value.length ? maskValue(value) : '';
                    }
                    return isValid ? value : undefined;
                }

                function checkOptions() {
                    if (attrs.uiOptions) {
                        linkOptions = scope.$eval('[' + attrs.uiOptions + ']');
                        if (angular.isObject(linkOptions[0])) {
                            // we can't use angular.copy nor angular.extend, they lack the power to do a deep merge
                            linkOptions = (function(original, current) {
                                for (var i in original) {
                                    if (Object.prototype.hasOwnProperty.call(original, i)) {
                                        if (!current[i]) {
                                            current[i] = angular.copy(original[i]);
                                        } else {
                                            angular.extend(current[i], original[i]);
                                        }
                                    }
                                }
                                return current;
                            })(options, linkOptions[0]);
                        }
                    } else {
                        linkOptions = options;
                    }
                }

                attrs.$observe('ventivMaskedInput', initialize);
                attrs.$observe('useRaw', function(useRawVal) {
                    useRawValue = useRawVal === 'false';
                });
                attrs.$observe('placeholder', initPlaceholder);
                var modelViewValue = false;
                attrs.$observe('modelViewValue', function(val) {
                    if (val === 'true') {
                        modelViewValue = true;
                    }
                });
                scope.$watch(attrs.ngModel, function(val) {
                    if (modelViewValue && val) {
                        var model = $parse(attrs.ngModel);
                        model.assign(scope, controller.$viewValue);
                    }
                });
                controller.$formatters.push(formatter);
                controller.$parsers.push(parser);

                function observeAndWatch() {

                }

                function uninitialize() {
                    maskProcessed = false;
                    unbindEventListeners();

                    if (!ie11) {
                        if (angular.isDefined(originalPlaceholder)) {
                            element.attr('placeholder', originalPlaceholder);
                        } else {
                            element.removeAttr('placeholder');
                        }
                    }

                    if (angular.isDefined(originalMaxlength)) {
                        element.attr('maxlength', originalMaxlength);
                    } else {
                        element.removeAttr('maxlength');
                    }

                    element.val(controller.$modelValue);
                    controller.$viewValue = controller.$modelValue;
                    return false;
                }

                function initializeElement() {
                    value = oldValueUnmasked = unmaskValue(controller.$modelValue || '');
                    valueMasked = oldValue = maskValue(value);
                    isValid = validateValue(value);
                    var viewValue = isValid && value.length ? valueMasked : '';
                    if (attrs.maxlength) { // Double maxlength to allow pasting new val at end of mask
                        element.attr('maxlength', maskCaretMap[maskCaretMap.length - 1] * 2);
                    }
                    if (!ie11) {
                        element.attr('placeholder', maskPlaceholder);
                    }
                    element.val(viewValue);
                    controller.$viewValue = viewValue;
                    // Not using $setViewValue so we don't clobber the model value and dirty the form
                    // without any kind of user interaction.
                }

                function bindEventListeners() {
                    if (eventsBound) {
                        return;
                    }
                    element.bind('blur', blurHandler);
                    element.bind('mousedown mouseup', mouseDownUpHandler);
                    element.bind('input keyup click focus', eventHandler);
                    eventsBound = true;
                }

                function unbindEventListeners() {
                    if (!eventsBound) {
                        return;
                    }
                    element.unbind('blur', blurHandler);
                    element.unbind('mousedown', mouseDownUpHandler);
                    element.unbind('mouseup', mouseDownUpHandler);
                    element.unbind('input', eventHandler);
                    element.unbind('keyup', eventHandler);
                    element.unbind('click', eventHandler);
                    element.unbind('focus', eventHandler);
                    eventsBound = false;
                }

                function validateValue(val) {
                    // Zero-length value validity is ngRequired's determination
                    return val.length ? val.length >= minRequiredLength : true;
                }

                function unmaskValue(val) {
                    var valueUnmasked = '',
                        maskPatternsCopy = maskPatterns.slice();
                    // Preprocess by stripping mask components from value
                    val = val.toString();
                    angular.forEach(maskComponents, function(component) {
                        val = val.replace(component, '');
                    });
                    angular.forEach(val.split(''), function(chr) {
                        if (maskPatternsCopy.length && maskPatternsCopy[0].test(chr)) {
                            valueUnmasked += chr;
                            maskPatternsCopy.shift();
                        }
                    });
                    return valueUnmasked;
                }

                function maskValue(unmaskedValue) {
                    var valMasked = '',
                        maskCaretMapCopy = maskCaretMap.slice();

                    angular.forEach(maskPlaceholder.split(''), function(chr, i) {
                        if (unmaskedValue.length && i === maskCaretMapCopy[0]) {
                            valMasked += unmaskedValue.charAt(0) || '_';
                            unmaskedValue = unmaskedValue.substr(1);
                            maskCaretMapCopy.shift();
                        } else {
                            valMasked += chr;
                        }
                    });
                    return valMasked;
                }

                function getPlaceholderChar(i) {
                    var placeholder = attrs.placeholder;

                    if (typeof placeholder !== 'undefined' && placeholder[i]) {
                        return placeholder[i];
                    } else {
                        return '_';
                    }
                }

                // Generate array of mask components that will be stripped from a masked value
                // before processing to prevent mask components from being added to the unmasked value.
                // E.g., a mask pattern of '+7 9999' won't have the 7 bleed into the unmasked value.
                // If a maskable char is followed by a mask char and has a mask
                // char behind it, we'll split it into it's own component so if
                // a user is aggressively deleting in the input and a char ahead
                // of the maskable char gets deleted, we'll still be able to strip
                // it in the unmaskValue() preprocessing.
                function getMaskComponents() {
                    return maskPlaceholder
                        .replace(/[_]+/g, '_')
                        .replace(/([^_]+)([a-zA-Z0-9])([^_])/g, '$1$2_$3')
                        .split('_');
                }

                function processRawMask(mask) {
                    var characterCount = 0;

                    maskCaretMap = [];
                    maskPatterns = [];
                    maskPlaceholder = '';

                    if (typeof mask === 'string') {
                        minRequiredLength = 0;

                        var isOptional = false,
                            splitMask = mask.split('');

                        angular.forEach(splitMask, function(chr, i) {
                            if (linkOptions.maskDefinitions[chr]) {

                                maskCaretMap.push(characterCount);

                                maskPlaceholder += getPlaceholderChar(i);
                                maskPatterns.push(linkOptions.maskDefinitions[chr]);

                                characterCount++;
                                if (!isOptional) {
                                    minRequiredLength++;
                                }
                            } else if (chr === '?') {
                                isOptional = true;
                            } else {
                                maskPlaceholder += chr;
                                characterCount++;
                            }
                        });
                    }
                    // Caret position immediately following last position is valid.
                    maskCaretMap.push(maskCaretMap.slice().pop() + 1);

                    maskComponents = getMaskComponents();
                    maskProcessed = maskCaretMap.length > 1 ? true : false;
                }

                function blurHandler() {
                    oldCaretPosition = 0;
                    oldSelectionLength = 0;
                    if (!isValid || value.length === 0) {
                        valueMasked = '';
                        element.val('');
                        scope.$apply(function() {
                            controller.$setViewValue('');
                        });
                    }
                }

                function mouseDownUpHandler(e) {
                    if (e.type === 'mousedown') {
                        element.bind('mouseout', mouseoutHandler);
                    } else {
                        element.unbind('mouseout', mouseoutHandler);
                    }
                }

                function mouseoutHandler() {
                    /*jshint validthis: true */
                    oldSelectionLength = getSelectionLength(this);
                    element.unbind('mouseout', mouseoutHandler);
                }

                function eventHandler(e) {
                    /*jshint validthis: true */
                    e = e || {};
                    // Allows more efficient minification
                    var eventWhich = e.which,
                        eventType = e.type;
                    // Prevent shift and ctrl from mucking with old values
                    if (eventWhich === 16 || eventWhich === 91) {
                        return;
                    }

                    var self = this,
                        val = element.val(),
                        valOld = oldValue,
                        valMasked,
                        valUnmasked = unmaskValue(val),
                        valUnmaskedOld,
                        valAltered,
                        caretPos,
                        caretPosOld,
                        caretPosDelta,
                        caretPosMin,
                        caretPosMax,
                        selectionLenOld,
                        isSelected,
                        wasSelected,
                        isAddition,
                        isDeletion,
                        isSelection,
                        isKeyLeftArrow,
                        isKeyBackspace,
                        isKeyDelete,
                    // Handles cases where caret is moved and placed
                    // in front of invalid maskCaretMap position. Logic below
                    // ensures that, on click or leftward caret placement,
                    // caret is moved leftward until directly right of
                    // non-mask character. Also applied to click since users
                    // are (arguably) more likely to backspace
                    // a character when clicking within a filled input.
                        caretBumpBack = (isKeyLeftArrow || isKeyBackspace || eventType === 'click') &&
                            caretPos > caretPosMin;
                    // Initialize variables. NOTE: this was encapsulated to
                    // reduce cyclomatic complexity.
                    init();
                    // These events don't require any action
                    if (isSelection || (isSelected && (eventType === 'click' || eventType === 'keyup'))) {
                        return;
                    }
                    // User attempted to delete but raw value was unaffected. Correct this grievous offense
                    updateRawValue();
                    updateValues();
                    repositionCaret();

                    /*
                     * Function Declarations
                     */

                    function init() {
                        valUnmaskedOld = oldValueUnmasked;
                        valAltered = false;
                        caretPos = getCaretPosition(self) || 0;
                        caretPosOld = oldCaretPosition || 0;
                        caretPosDelta = caretPos - caretPosOld;
                        caretPosMin = maskCaretMap[0];
                        caretPosMax = maskCaretMap[valUnmasked.length] || maskCaretMap.slice().shift();
                        selectionLenOld = oldSelectionLength || 0;
                        isSelected = getSelectionLength(self) > 0;
                        wasSelected = selectionLenOld > 0;
                        // Case: Typing a character to overwrite a selection
                        isAddition = (val.length > valOld.length) ||
                            (selectionLenOld && val.length > valOld.length - selectionLenOld);
                        // Case: Delete and backspace behave identically on a selection
                        isDeletion = (val.length < valOld.length) ||
                            (selectionLenOld && val.length === valOld.length - selectionLenOld);
                        isSelection = (eventWhich >= 37 && eventWhich <= 40) && e.shiftKey; // Arrow key codes
                        isKeyLeftArrow = eventWhich === 37;
                        // Necessary due to "input" event not providing a key code
                        isKeyBackspace = eventWhich === 8 ||
                            (eventType !== 'keyup' && isDeletion && (caretPosDelta === -1));
                        isKeyDelete = eventWhich === 46 ||
                            (eventType !== 'keyup' && isDeletion && (caretPosDelta === 0) && !wasSelected);
                        oldSelectionLength = getSelectionLength(self);
                    }

                    function updateRawValue() {
                        if ((eventType === 'input') &&
                            isDeletion &&
                            !wasSelected &&
                            valUnmasked === valUnmaskedOld) {
                            while (isKeyBackspace && caretPos > caretPosMin && !isValidCaretPosition(caretPos)) {
                                caretPos--;
                            }
                            while (isKeyDelete && caretPos < caretPosMax && maskCaretMap.indexOf(caretPos) === -1) {
                                caretPos++;
                            }
                            var charIndex = maskCaretMap.indexOf(caretPos);
                            // Strip out non-mask character that user would have
                            // deleted if mask hadn't been in the way.
                            valUnmasked = valUnmasked.substring(0, charIndex) +
                                valUnmasked.substring(charIndex + 1);
                            valAltered = true;
                        }
                    }

                    function updateValues() {
                        valMasked = maskValue(valUnmasked);
                        oldValue = valMasked;
                        oldValueUnmasked = valUnmasked;
                        element.val(valMasked);
                        if (valAltered) {
                            // We've altered the raw value after it's been $digest'ed,
                            // we need to $apply the new value.
                            scope.$apply(function() {
                                controller.$setViewValue(valUnmasked);
                            });
                        }
                    }

                    function repositionCaret() {
                        // Ensure that typing always places caret ahead of
                        // typed character in cases where the first char of
                        // the input is a mask char and the caret is placed at the 0 position.
                        if (isAddition && (caretPos <= caretPosMin)) {
                            caretPos = caretPosMin + 1;
                        }
                        if (caretBumpBack) {
                            caretPos--;
                        }
                        // Make sure caret is within min and max position limits
                        caretPos = caretPos >
                        caretPosMax ? caretPosMax : caretPos < caretPosMin ? caretPosMin : caretPos;
                        // Scoot the caret back or forth until it's in a
                        // non-mask position and within min/max position limits
                        while (!isValidCaretPosition(caretPos) && caretPos >
                        caretPosMin && caretPos < caretPosMax) {
                            caretPos += caretBumpBack ? -1 : 1;
                        }
                        if ((caretBumpBack && caretPos < caretPosMax) ||
                            (isAddition && !isValidCaretPosition(caretPosOld))) {
                            caretPos++;
                        }
                        oldCaretPosition = caretPos;
                        setCaretPosition(self, caretPos);
                    }
                }

                function isValidCaretPosition(pos) {
                    return maskCaretMap.indexOf(pos) > -1;
                }

                function getCaretPosition(input) {
                    if (!input) {
                        return 0;
                    }
                    if (input.selectionStart !== undefined) {
                        return input.selectionStart;
                    } else if (document.selection) {
                        // Curse you IE
                        input.focus();
                        var selection = document.selection.createRange();
                        selection.moveStart('character', -input.value.length);
                        return selection.text.length;
                    }
                    return 0;
                }

                function setCaretPosition(input, pos) {
                    if (!input) {
                        return 0;
                    }
                    if (input.offsetWidth === 0 || input.offsetHeight === 0) {
                        return; // Input's hidden
                    }
                    if (input.setSelectionRange) {
                        input.focus();
                        input.setSelectionRange(pos, pos);
                    } else if (input.createTextRange) {
                        // Curse you IE
                        var range = input.createTextRange();
                        range.collapse(true);
                        range.moveEnd('character', pos);
                        range.moveStart('character', pos);
                        range.select();
                    }
                }

                function getSelectionLength(input) {
                    if (!input) {
                        return 0;
                    }
                    if (input.selectionStart !== undefined) {
                        return (input.selectionEnd - input.selectionStart);
                    }
                    if (document.selection) {
                        return (document.selection.createRange().text.length);
                    }
                    return 0;
                }
            };
        }
    }
})();

(function() {
    'use strict';

    /**
     * A static select input that utilizes Select2.
     *
     * @method ventivSelectValue
     *
     * @example
     *      HTML for sequential numbers:
     *      <input ventiv-select-value
     *             min="2010"
     *             max="2015"
     *             ng-model="modelVar">
     *
     *      HTML for any other values:
     *      <input ventiv-select-value
     *             values="valuesVar"
     *             ng-model="modelVar">
     *
     *      Values as strings:
     *           valuesVar = ['one', 'two'];
     *
     *      Values as objects:
     *           valuesVar = [{id: '1', text: 'one'}, {id: '2', text: 'two'}];
     *
     * @param {String} ng-model Model to bind this widget to.
     * @param {String} [allow-clear] Whether to allow the value to be cleared, "true" to enable.
     * @param {Object} [extra-options] Extra select2 options to add to the configuration
     * @param {Number} [min] The minimum value (when using sequential numbers).
     * @param {Number} [max] The maximum value (when using sequential numbers).
     * @param {Array} [values] An array of strings representing the select values or objects with id/text properties.
     * @param {ValueFormatter} [format-selection] Formats  values for display in the selection.
     * @param {ValueFormatter} [format-result] Formats  values for display in the results
     */

    angular
        .module('alpha.directives.ventivSelectValue', ['ngSanitize','alpha.utils.Utils'])
        .directive('ventivSelectValue', ventivSelectValue);

    ventivSelectValue.$inject = [
        '$timeout',
        'Utils'
    ];

    function ventivSelectValue($timeout,Utils) {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: link
        };
        function link(scope, element, attrs, ngModelCtrl) {
            var model = attrs.ngModel || '',
                extraSelect2Options = _.omit(scope.$eval(attrs.extraOptions),['formatSelection','formatResult']) || {},
                data = [];

            ngModelCtrl.$parsers.unshift(parser);
            $timeout(function() { // allow dynamic ID to compile before initializing
                bindSelectToModel();
                bindPlaceholderWatcher();
                if (attrs.values) {
                    bindValuesWatcher();
                } else {
                    loadNumbers(parseInt(attrs.min) || 1, parseInt(attrs.max) || 10);
                    initSelect2();
                }
            });
            function loadValues(values) {
                data.length = 0;
                _.forEach(values, function(value) {
                    if (_.isString(value)) {
                        value = Utils.sanitize(value);
                        data.push({ id: value, text: value });
                    } else if (_.isObject(value) && value.id && value.text) {
                        value.text = Utils.sanitize(value.text);
                        data.push(value);
                    }
                });
            }
            function loadNumbers(min, max) {
                data.length = 0;
                for (var i = min; i <= max; i++) {
                    data.push({ id: i.toString(), text: i.toString() });
                }
            }
            function bindSelectToModel() {
                scope.$watch(model, function(val) {
                    element.select2('val', val);
                });
            }
            function bindValuesWatcher(){
                scope.$watch(attrs.values, function(values) {
                    loadValues(values);
                    initSelect2();
                });
            }
            function bindPlaceholderWatcher() {
                attrs.$observe('placeholder', function() {
                    initSelect2();
                });
            }
            function initSelect2() {
                element.select2(getSelect2Opts());
                element.select2('val', scope.$eval(attrs.ngModel));
            }
            function getSelect2Opts() {
                var allowClear = attrs.allowClear === 'true',
                    emptyPlaceholder = allowClear ? ' ' : ''; // Something must be defined for clear to work
                return angular.extend({
                    multiple: false,
                    placeholder: attrs.placeholder || emptyPlaceholder,
                    formatSelection: _getSelectionFormatter(),
                    formatResult: _getResultFormatter(),
                    allowClear: allowClear,
                    data: data
                }, extraSelect2Options);
            }
            function parser(viewValue) {
                return _.isEmpty(viewValue) ? null : viewValue;
            }
            function _getSelectionFormatter() {
                var optionalFormatter = scope.$eval(attrs.selectionFormatter);
                if (_.isFunction(optionalFormatter)) {
                    return function(value) {
                        return Utils.sanitize(optionalFormatter(value));
                    }
                }
            }
            function _getResultFormatter() {
                var optionalFormatter = scope.$eval(attrs.resultFormatter);
                if (_.isFunction(optionalFormatter)) {
                    return function(value) {
                        return Utils.sanitize(optionalFormatter(value));
                    }
                }
            }
        }
    }
})();

(function () {
    'use strict';

    /**
     * A simple input that restricts the value.
     *
     * @method ventivRestrictedInput
     *
     * @example
     *      HTML for sequential numbers:
     *      <input ventiv-restricted-value
     *             restrict="[^a-zA-Z0-9_]"
     *             ng-model="modelVar"/>
     *
     *
     * @param {String} ng-model Model to bind this widget to.
     * @param {String} [restrict] The regeluar expression to restrict the input to.
     */

    angular
        .module('alpha.directives.ventivRestrictedInput', [])
        .directive('ventivRestrictedInput', ventivRestrictedInput);

    ventivRestrictedInput.$inject=['$log'];

    function ventivRestrictedInput(){
        return {
            restrict : 'A',
            require: 'ngModel',
            link : link
        };

        function link(scope, element, attrs, controller){

            var restrict = attrs.restrict || '';
            init();

            function parser(value){
                var parsedInput = _replace(value);
                if(parsedInput !== value){
                    controller.$setViewValue(parsedInput);
                    controller.$render();
                }
                return parsedInput;
            }

            function _replace(value){
                return value ? value.replace(new RegExp(restrict, 'g'), '') : value;
            }

            function init(){
                controller.$parsers.unshift(parser);
            }
        }
    }

})();

(function () {
    'use strict';

    /**
     * A tooltip that works in many atypical situations.
     * Place on an element with the "ventivTooltip" property.
     * Displayed text comes from the "tooltipText"  attribute.
     */

    angular
        .module('alpha.directives.ventivTooltip', [])
        .directive('ventivTooltip', function() {
            return {

                restrict: 'A',
                link: function(scope, element, attrs, controller) {
                    //exit out if there's no text
                    if (!attrs.tooltipText) {
                        return;
                    }

                    //ele may be a different dom element if the element has the disabled tag.
                    //it is the element that is acted on.
                    var origEle = angular.element(element[0]);
                    var ele = origEle;

                    //mouse functions for active elements.
                    var mouseoutFxn = function() {
                        removeTooltip();
                    };

                    var mouseoverFxn = function(ev) {
                        ev.stopPropagation();
                        removeTooltip();
                        handleDisabled();
                        addTooltip($(ele)[0]);
                        sizeTooltip($('.ventivTooltip')[0]);
                        positionTooltip($(ele)[0], $('.ventivTooltip')[0]);
                        tooltipInViewport($(ele)[0], $('.ventivTooltip')[0]);
                    };

                    $(origEle).mouseover(mouseoverFxn);
                    $(origEle).mouseout(mouseoutFxn);

                    //dom element initialization
                    origEle.addClass('ventivTooltipParent');

                    //build and place placeholders for disbaled elements
                    if (origEle.prop('disabled')) {
                        handleDisabled();
                        setTimeout(placeDisabled,0);
                    }
                    //watch for elements to become disabled or enabled
                    scope.$watch(function() {
                        return origEle.prop('disabled');
                    }, function(newValue) {
                        handleDisabled();
                    });

                    //move the disabled placeholder when the window is resized
                    $(window).resize(function() {
                        placeDisabled();
                    });

                    //ensure the tooltip is in the viewport, and if it is not put it under and or behind the element
                    function tooltipInViewport(el, tt) {
                        var top = $(tt).offset().top;
                        var left = $(tt).offset().left;
                        var width = tt.offsetWidth;

                        var elTop = $(el).offset().top;
                        var elHeight = el.offsetHeight;

                        var inTop = top > window.pageYOffset;
                        var inRight = Math.ceil(left + width) < $(window).width();

                        if (!inTop) {
                            $(tt).css({top: elTop + elHeight + 2});
                        }

                        if (!inRight) {
                            $(tt).css({left: 'auto'});
                            $(tt).css({right: '5px'});
                        }
                    }
                    //initial tooltip placement based on elementposition and size
                    function positionTooltip(el, tt) {
                        var top = $(el).offset().top;
                        var left = $(el).offset().left;
                        var height = tt.offsetHeight;

                        $('.ventivTooltip').css({height:  $('.ventivTooltip').height()});
                        $('.ventivTooltip').css({
                            top: top - height - 2,
                            left: left + 10
                        });
                    }
                    function addTooltip(el) {
                        $('body').append('<div class="ventivTooltip">' + attrs.tooltipText + '</div>');
                    }
                    function removeTooltip() {
                        $('.ventivTooltip').remove();
                    }
                    function sizeTooltip(tt) {
                        var width = tt.offsetWidth;
                        var height = tt.offsetHeight;
                        if (height * 2 > width) {
                            $(tt).css({'width': height * 2 + 'px'}); //maxes out at the tooltip's maxWidth)
                        }
                        if (height > width) {
                            $(tt).css({'max-width': '500px'}); //increases the tooltip's maxWidth)
                        }
                    }
                    //place the placeholder on top of it's disabled element
                    function placeDisabled() {
                        if (origEle.prop('disabled') && origEle.attr('disabled-tooltip')) {
                            var position = $(origEle).position();
                            var x = position.left;
                            x -= ($(ele).outerWidth() - $(origEle).outerWidth());
                            $(ele).css({
                                top: position.top,
                                left: x,
                                width: $(origEle).outerWidth(),
                                height: $(origEle).outerHeight()
                            });
                        }
                    }

                    function handleDisabled() {
                        //check for a disabled state and build a absolute positioned placeholder on top of the original element
                        if (origEle.prop('disabled')) {
                            if (!origEle.attr('disabled-tooltip')) {
                                //build the absolutely poisitioned element
                                ele = angular.element(
                                    '<div style="position:absolute;" class="ventivTooltipParent" disabled-tooltip-placeholder/>'
                                );
                                $(origEle).parent().append(ele);
                                //add this attribute to mark the element as disabled and already handled.
                                origEle.attr('disabled-tooltip', true);
                                placeDisabled();

                                $(ele).mouseover(mouseoverFxn);
                                $(ele).mouseout(mouseoutFxn);
                            }

                        } else { //remove disabled state if it has changed
                            if ($(ele)[0].hasAttribute('disabled-tooltip-placeholder')) {
                                $(ele).remove();
                                $(origEle).removeAttr('disabled-tooltip');
                                ele = origEle;
                            }
                        }
                    }

                }
            };
        });

})();
(function () {
    'use strict';

    /**
     * A directive to download a file.
     *
     * @method ventivFileDownload
     *
     * @example
     *      HTML for file download directive:
     *      <ventiv-file-download
     *              client-id="clientId"
     *              record-type="recordType"
     *              record-id="recordId"
     *              field-type="fieldType"
     *              file-name="fileName"></ventiv-file-download>
     *
     *
     * @param {String} [clientId] The clientId of the file.
     * @param {String} [recordType] The recordType of the file.
     * @param {Number} [recordId] The recordId of the file.
     * @param {String} [fieldType] The fieldType of the file.
     * @param {String} [fileName] The file name of the file.
     */
    angular.module('alpha.directives.ventivFileDownload',[])
        .directive('ventivFileDownload', ventivFileDownload);

    ventivFileDownload.$inject = ['$window', '$http', '$document', 'Utils'];

    function ventivFileDownload($window, $http, $document, Utils){
        return {
            restrict: 'AE',
            transclude: true,
            link: link
        };
        function link(scope, element, attrs){
            var clientId = attrs.clientId,
                recordType = attrs.recordType,
                recordId = attrs.recordId,
                fieldType = attrs.fieldType,
                fileId = attrs.fileId,
                fileName = attrs.fileName;
            var config = {
                method: 'GET',
                url: constructUrl(),
                responseType: 'blob'
            };

            init();

            function init(){
                bindHandler();
            }
            function bindHandler(){
                element.bind('click', downloadFile);
            }
            function downloadFile(){
                $http(config)
                    .then(function(response){
                        Utils.downloadFile(decodeURIComponent(fileName), response.data, 'data:attachment/text;charset=utf-8');
                    })
                    .catch(function(message){
                        console.error('Failed to retrieve file:' + message);
                    });
            }
            function constructUrl(){
                return [
                    applicationContextRoot,
                    '/dp/file/',
                    clientId,
                    '/',
                    recordType,
                    '/' ,
                    recordId,
                    '/',
                    fieldType,
                    '/',
                    fileId,
                    '?download'
                ].join('');
            }
        }
    }
})();

(function () {
    'use strict';

    /**
     * @param {String}   [aon-lookup-library-field-type] The recordTypeId.fieldTypeId for the lookup security query filter value.
     * @param {String}   [enable-exclusive-objects] Should exclusiveObjects filter be sent in query.
     * @param {String}   [enable-lookup-security] Should lookup security filter be sent in query.
     */
    angular
        .module('alpha.directives.aonLookupCheckbox', ['AlphaApi', 'UserPreferences', 'alpha.utils.Utils', 'alpha.utils.RequiredFieldUtils',
            'alpha.common.services.ruleExecutionHelper'])
        .directive('aonLookupCheckbox', aonLookupCheckbox);

    aonLookupCheckbox.$inject=['LookupInterface', 'UserPreferences', 'Utils', 'RequiredFieldService', 'RuleExecutionHelper'];
    var userPreferences = {};
    function aonLookupCheckbox(LookupInterface, UserPreferences, Utils, RequiredFieldService, RuleExecutionHelper){
        var readOnlyClass = 'readonly';
        return {
            restrict : 'A',
            require: 'ngModel',
            link : link,
            scope: true
        };

        function link(scope, element, attrs, ngModelCtrl){
            var checkboxEle = element[0].firstChild, //the input checkbox element
                trueVal, falseVal, _readOnlyMode, _readOnlyState;
            scope.dataReady = false;
            scope.disabledLookupSecurity = false;
            ngModelCtrl.$render = _render

            RuleExecutionHelper.subscribeToFieldEvents(scope, ['setReadOnly', 'setRequired'], attrs, _eventHandler);
            attrs.$observe('readOnlyMode', function(newVal) {
                if (newVal === 'true') {
                    _readOnlyMode = true;
                    _enableReadOnly();
                } else {
                    _readOnlyMode = false;
                    if (!_readOnlyState) {
                        _disableReadOnly();
                    }
                }
            });
            function _eventHandler(data, event) {
                switch (event.topic) {
                    case 'setReadOnly':
                        _setReadOnly();
                        break;
                    case 'setRequired':
                        _setRequired();
                        break;
                }
                function _setReadOnly() {
                    if (data.value === true) {
                        _readOnlyState = true;
                        _enableReadOnly();
                    } else if (data.value === false) {
                        _readOnlyState = false;
                        if (!_readOnlyMode) {
                            _disableReadOnly();
                        }
                    }
                }
                function _setRequired() {
                    if (data.value === true) {
                        _enableRequired();
                    } else if (data.value === false) {
                        _disableRequired();
                    }
                }
            }
            function _enableReadOnly() {
                element.find('input[type=checkbox]').addClass(readOnlyClass).prop('readonly', true).prop('disabled', true);
            }
            function _disableReadOnly() {
                element.find('input[type=checkbox]').removeClass(readOnlyClass).prop('readonly', false).prop('disabled', false);
            }
            function _enableRequired() {
                ngModelCtrl.$validators.required = function (value) {
                    return !(_.isUndefined(value) || _.isNull(value) || _isEmptyString(value) || _isEmptyObject(value) || _isYesNo(value));

                    function _isEmptyString(val){
                        return _.isString(val) && (_.isEmpty(val) || _.isEmpty(val.trim()));
                    }
                    function _isEmptyObject(val) {
                        return _.isObject(val) && _.isEmpty(val);
                    }
                    function _isYesNo(val){
                        return val.value === "No" ?true:false;
                    }
                };
                ngModelCtrl.$setValidity('required', RequiredFieldService.isRequiredFieldValid(ngModelCtrl.$modelValue));
            }
            function _disableRequired() {
                ngModelCtrl.$setValidity('required', true);
                delete ngModelCtrl.$validators.required;
            }
            UserPreferences.getCurrentPreferences().then(function success(data) {
                userPreferences = data;
            });

            function booleanToLookupVal(e){
                ngModelCtrl.$setViewValue(e.target.checked?trueVal:falseVal);
            }
            checkboxEle.addEventListener("change", booleanToLookupVal);

            var filterVars = Utils.getLookupFilterVariablesAndValues(attrs.enableLookupSecurity, attrs.enableExclusiveObjects, attrs.aonLookupLibraryFieldType);

            LookupInterface.getLookupLibraryDetailsFromCache(
                attrs.aonLookupClientId || userPreferences.client.id,
                attrs.aonLookupLibrary,
                '',
                filterVars.variables,
                filterVars.values,
                true,
                'ASC',
                null,
                false,
                false,
                function(lookupResource) {
                    trueVal =  _.find(lookupResource.lookupLibrary.value, {id: "Yes"});
                    falseVal =  _.find(lookupResource.lookupLibrary.value, {id: "No"});
                    if(_.some(lookupResource.lookupLibrary.value, {updateLookupAuthority: false})){
                        scope.disabledLookupSecurity = true;
                        _enableReadOnly();
                    }
                    scope.dataReady = true;
                    _render();
                },
                function() {
                    scope.validationMessages = ['Fatal error, could not retrieve record data.'];
                }
            );
            function _render() {
                if (scope.dataReady) {
                    if (_.isObject(ngModelCtrl.$viewValue)) {
                        checkboxEle.checked = ngModelCtrl.$viewValue.lookupId === trueVal.lookupId;
                    } else {
                        checkboxEle.checked = false;
                    }
                }
            }
        }
    }

})();

(function () {
    'use strict';

    /**
     * Isolates a form so its parent forms won't be affected by
     * its validity and dirty state.
     *
     * @method isolateForm
     *
     * @example
     *      HTML for sequential numbers:
     *      <ng-form name="someCtrl.form" isolate-form></ng-form>
     */

    angular
        .module('alpha.directives.isolateForm', [])
        .directive('isolateForm', isolateForm);

    isolateForm.$inject = [];

    function isolateForm() {
        return {
            restrict: 'A',
            require: 'form',
            link: link
        };

        function link(scope, element, attrs, formCtrl) {
            if (formCtrl.$$parentForm) {
                formCtrl.$$parentForm.$removeControl(formCtrl);
            }
        }
    }
})();
