(function () {
    'use strict';

    angular
        .module('UserPreferences', [
            'AlphaApi',
            'ngCookies'
        ])
        .run(userPreferencesInitializer)
        .factory('UserPreferences', UserPreferences)
        .constant({
            ACTIVE_WINDOW_STORAGE: 'activeWindows',
            RECENT_CLIENT_STORAGE: 'mostRecentClients'
        });

    UserPreferences.$inject = [
        'ACTIVE_WINDOW_STORAGE',
        'RECENT_CLIENT_STORAGE',
        'UserDetailsInterface',
        'UserPreferencesInterface',
        '$q',
        '$log',
        '$cookies',
        '$window',
        'LicenceAgreementInterface'
    ];

    function UserPreferences(
        ACTIVE_WINDOW_STORAGE,
        RECENT_CLIENT_STORAGE,
        UserDetailsInterface,
        UserPreferencesInterface,
        $q,
        $log,
        $cookies,
        $window,
        LicenceAgreementInterface
    ) {

        var _windowId = _generateUuid();

        return {
            getCurrentPreferences: getCurrentPreferences,
            getLatestUserPreferences:getLatestUserPreferences,
            updateUserPreferences: updateUserPreferences,
            getAvatar: getAvatar,
            getClientId: getClientId,
            getIsSuperAdmin: getIsSuperAdmin,
            changeClient: changeClient,
            activateWindow: activateWindow,
            deactivateWindow: deactivateWindow,
            getActiveWindows: getActiveWindows,
            setMostRecentClient: setMostRecentClient,
            getMostRecentClient: getMostRecentClient,
            getMostRecentClients: getMostRecentClients,
            cognosLogout: cognosLogout,
            dataScienceLogout: dataScienceLogout,
            deleteCognosCookies:deleteCognosCookies,
            generateCognosCookie:generateCognosCookie,
            generateDataScienceCookie: generateDataScienceCookie,
            updateUserEnrollmentStatus:updateUserEnrollmentStatus,
            isQRCodeButtonDisable:isQRCodeButtonDisable,
            checkLicenseAgreementPresent:checkLicenseAgreementPresent,
            clearActiveWindows:clearActiveWindows,
            checkOtherActiveWindows:checkOtherActiveWindows,
            keepAliveCognosSession: keepAliveCognosSession,
            canDeleteCognosCookie: canDeleteCognosCookie
        };

        // Public methods

        function getCurrentPreferences() {
            var deferred = $q.defer();
            UserPreferencesInterface.getUserPreferencesFromCache(
                function success(data) {
                    deferred.resolve(data.userPreferences);
                }, function error(err) {
                    $log.error('Error retrieving user preferences');
                    deferred.reject(err);
                });
           return deferred.promise;
        }

        function getLatestUserPreferences() {
            var deferred = $q.defer();
            UserPreferencesInterface.getUserPreferences(
                function success(data) {
                    deferred.resolve(data.userPreferences);
                }, function error(err) {
                    $log.error('Error retrieving user preferences');
                    deferred.reject(err);
                });
            return deferred.promise;
        }
        
        function updateUserPreferences(newPreferences) {
            var deferred = $q.defer();
            UserPreferencesInterface.updateUserPreferences(newPreferences, function success(data) {
                deferred.resolve(data.userPreferences);
            }, function error(err) {
                $log.error('Error updating user preferences.');
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function getAvatar(user, successCallback, errorCallback){
            return UserDetailsInterface.getAvatar(
                user,
                successCallback,
                errorCallback
            );
            // This return works fine from an HTTP get using a responseType of 'arraybuffer'.
            // return $http.get("http://localhost:8080/Alpha/security/users/SOME_USER/avatar", {responseType:'arraybuffer'});
        }

        function getLanguageId() {
            var deferred = $q.defer();
            getCurrentPreferences()
                .then(function(prefs) {
                    if ((prefs.client.language !== null) && (prefs.client.language!== undefined)) {
                        deferred.resolve(prefs.client.language);
                    } else {
                        deferred.resolve('Unknown');
                    }
                })
                .catch(function(errorMessage) {
                    deferred.reject(errorMessage);
                });
            return deferred.promise;
        }


        function getClientId() {
            var deferred = $q.defer();
            getCurrentPreferences()
                .then(function(prefs) {
                    if ((prefs.client.id !== null) && (prefs.client.id!== undefined)) {
                        deferred.resolve(prefs.client.id);
                    } else {
                        deferred.resolve('Unknown');
                    }
                })
                .catch(function(errorMessage) {
                    deferred.reject(errorMessage);
                });
            return deferred.promise;
        }

        function getIsSuperAdmin() {
            var deferred = $q.defer();
            getCurrentPreferences()
                .then(function(prefs) {
                    deferred.resolve(prefs.isSuperAdmin);
                })
                .catch(function(errorMessage) {
                    deferred.reject(errorMessage);
                });
            return deferred.promise;
        }

        function changeClient(client,result) {
            var deferred = $q.defer(),
                self = this;
            if (_.reject(self.getActiveWindows(), {uuid: _windowId}).length) {
                deferred.reject('OTHER_WINDOW');
            } else {
                self.getCurrentPreferences()
                    .then(function(prefs) {
                        self.updateUserPreferences(angular.extend({}, prefs, {clientId: client.id, client: client}))
                            .then(function() {
                                $cookies.remove('usersessionid');
                                if(result){
                                    setTimeout(function () {
                                        $window.location.reload();
                                    }, 2000);
                                }
                                deferred.resolve();
                            })
                            .catch(function(reason) {
                                deferred.reject(reason);
                            });
                    })
                    .catch(function(reason) {
                        deferred.reject(reason);
                    });
            }
            return deferred.promise;
        }
        function activateWindow() {
            var self = this;
            self.getClientId()
                .then(function(clientId) {
                    var activeWindows = self.getActiveWindows();
                    if (!_.find(activeWindows, {uuid: _windowId})) {
                        activeWindows.push({
                            uuid: _windowId,
                            clientId: clientId
                        });
                        $window.localStorage.setItem(ACTIVE_WINDOW_STORAGE, JSON.stringify(activeWindows));
                    }
                });
        }
        function deactivateWindow() {
            var activeWindows = this.getActiveWindows();
            _.remove(activeWindows, {uuid: _windowId});
            if (activeWindows.length) {
                $window.localStorage.setItem(ACTIVE_WINDOW_STORAGE, JSON.stringify(activeWindows));
            } else {
                $window.localStorage.removeItem(ACTIVE_WINDOW_STORAGE);
            }
        }
        function getActiveWindows() {
            var activeWindowStorage = $window.localStorage.getItem(ACTIVE_WINDOW_STORAGE),
                activeWindows = activeWindowStorage ? JSON.parse(activeWindowStorage) : [];
            return _.isArray(activeWindows) ? activeWindows : [];
        }
        function clearActiveWindows(){
            var activeWindows = this.getActiveWindows();
            if (activeWindows.length) {
                $window.localStorage.removeItem(ACTIVE_WINDOW_STORAGE);
            }
        }
        function checkOtherActiveWindows(){
            return _.reject(this.getActiveWindows(), {uuid: _windowId}).length;
        }
        function setMostRecentClient(userName, clientId) {
            var mostRecentClients;
            if (userName) {
                mostRecentClients = this.getMostRecentClients();
                mostRecentClients[userName.toLowerCase()] = clientId;
                $window.localStorage.setItem(RECENT_CLIENT_STORAGE, JSON.stringify(mostRecentClients));
            }
        }
        function getMostRecentClient(userName) {
            if (userName) {
                return _.get(this.getMostRecentClients(), userName.toLowerCase());
            }
        }
        function getMostRecentClients() {
            var mostRecentClientStorage = $window.localStorage.getItem(RECENT_CLIENT_STORAGE),
                mostRecentClients = mostRecentClientStorage ? JSON.parse(mostRecentClientStorage) : {};
            return _.isObject(mostRecentClients) ? mostRecentClients : {};
        }
        function cognosLogout(){
            var biURL,ifrmObj;
                biURL= _.get(document.getElementById('cognosURL'),'value') || (window.parent && _.get(window.parent.document.getElementById('cognosURL'),'value'));
            if(!_.isEmpty(biURL)) {
                ifrmObj = document.getElementById('cognosLogOut') || (window.parent &&window.parent.document.getElementById('cognosLogOut'));
                ifrmObj.src = biURL + 'v1/disp?b_action=xts.run&m=portal/logoff.xts&h_CAM_action=logoff';
            }
        }

        function dataScienceLogout(logoutURL){
            //Cognos and datascience use the same domain.
            var cognosDomain = _.get(document.getElementById('cognosDomain'),'value') || (window.parent && _.get(window.parent.document.getElementById('cognosDomain'),'value')) ;
            if(cognosDomain){
                $cookies.remove('RCADATASCIENCE', {'domain': '.' + cognosDomain, path: '/'});
            }
            var iframeObject;
            if(!_.isEmpty(logoutURL)){
                iframeObject = document.getElementById('dataScienceLogOut') || (window.parent &&window.parent.document.getElementById('dataScienceLogOut'));
                iframeObject.src = logoutURL;
            }
        }

        function deleteCognosCookies(){
            var cognosDomain = _.get(document.getElementById('cognosDomain'),'value') || (window.parent && _.get(window.parent.document.getElementById('cognosDomain'),'value')) ;
            if(!_.isEmpty(cognosDomain)) {
                $cookies.remove('RCACOGNOSKEY', {'domain': '.' + cognosDomain, path: _getCognosPath()});
                $cookies.remove('usersessionid');
                $cookies.remove('cam_passport');
                $cookies.remove('cea-ssa');
                $cookies.remove('up');
                $cookies.remove('userCapabilities');
                $cookies.remove('userCapabilitiesEx');
            }
            $cookies.remove('REMEMBER_ME_USERID')
        }
        function generateCognosCookie() {
            var deferred = $q.defer();
            UserPreferencesInterface.generateCognosCookie(function success(data) {
                deferred.resolve(data);
            }, function error(err) {
                $log.error('Error updating user preferences.');
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function generateDataScienceCookie() {
            var deferred = $q.defer();
            UserPreferencesInterface.generateDataScienceCookie(function success(data) {
                deferred.resolve(data);
            }, function error(err) {
                $log.error('Error generating the data science cookie.');
                deferred.reject(err);
            });
            return deferred.promise;
        }

        function keepAliveCognosSession()  {
            var biURL,ifrmObj;
            biURL= _.get(document.getElementById('cognosURL'),'value') || (window.parent && _.get(window.parent.document.getElementById('cognosURL'),'value'));
            if(!_.isEmpty(biURL)) {
                ifrmObj = document.getElementById('cognosLogOut') || (window.parent &&window.parent.document.getElementById('cognosLogOut'));
                ifrmObj.src = biURL;
            }
        }

        function canDeleteCognosCookie() {
            var activeWindows = getActiveWindows(),
                canDeletedCognosCookie = true,
                currentActiveWindow,
                activeWindowsWithSameClient;

            if (activeWindows && activeWindows.length > 1) {
                currentActiveWindow = _.find(activeWindows, { uuid: _windowId });
                if (currentActiveWindow) {
                    activeWindowsWithSameClient = _.find(activeWindows, function (activeWindow) {
                        return activeWindow.clientId === currentActiveWindow.clientId && activeWindow.uuid !== currentActiveWindow.uuid;
                    });
                    if (activeWindowsWithSameClient) {
                        canDeletedCognosCookie = false;
                    }
                }
            }

            return canDeletedCognosCookie;
        }

        // Private functions

        function _generateUuid() {
            function S4() {
                return Math.floor(Math.random() * 0x10000).toString(16);
            }
            return S4() + S4() + '-' + S4() + '-' + S4() + '-' + S4() + '-' + S4() + S4() + S4();
        }
        function _getCognosPath() {
            var biURL,paths,isFound;
            biURL = _.get(document.getElementById('cognosURL'),'value') || (window.parent && _.get(window.parent.document.getElementById('cognosURL'),'value'));
            if(!_.isEmpty(biURL)){
                isFound = biURL.indexOf("://");
                if(isFound > -1) {
                    biURL = biURL.substring(isFound + 3);
                }
                paths = biURL.split("/")
                if(paths.length == 1)
                    return "/"
                else {
                    return "/" + paths[1] + "/"
                }
            }
        }

        function updateUserEnrollmentStatus(userName, enrollmentStatus){
            var deferred = $q.defer();
            var self = this;
            self.getClientId()
                .then(function(clientId) {
                    UserDetailsInterface.updateUserEnrollmentStatus(userName, enrollmentStatus, clientId,function success(data) {
                            deferred.resolve(data);
                        },
                        function error(reason) {
                            deferred.reject(reason);
                        }
                    );
                })
            return deferred.promise;
        }

        function isQRCodeButtonDisable(userName) {
            var deferred = $q.defer();
            UserDetailsInterface.getMFAUserDetails(userName,
                function success(data){
                    deferred.resolve(data);
                },
                function error(reason){
                    deferred.reject(reason);
                }
            );
            return deferred.promise;
        }

        function checkLicenseAgreementPresent(client){
            var deferred = $q.defer();
            var self = this;
            LicenceAgreementInterface.checkLicenseAgreementPresent(client.id,
                function success(response){
                    self.changeClient(client,response.result).then(function () {
                        deferred.resolve();
                        if(!response.result){
                            $window.location.href = applicationContextRoot + '/dynamic/license-agreement.jsp';
                        }
                    }) .catch(function (reason) {
                        deferred.reject(reason)
                    })
                },
                function error(response){
                    deferred.reject(response.data.errorMessage);
                }
            );
            return deferred.promise;
        }
    }

    /**
     * This initializer loads preferences immediately so the most recent
     * client ID will always be stored.
     *
     * It also stores the active window for tracking purposes.
     */

    userPreferencesInitializer.$inject = [
        'UserPreferences',
        '$window'
    ];

    function userPreferencesInitializer(
        UserPreferences,
        $window
    ) {
        UserPreferences.getCurrentPreferences()
            .then(function(prefs) {
                if (prefs && prefs.clientId) {
                    UserPreferences.setMostRecentClient(prefs.userName, prefs.clientId);
                    if (!_windowIsIframe()) {
                        UserPreferences.activateWindow();
                        $window.addEventListener('beforeunload', function() {
                            var canDeleteCognosCookie = UserPreferences.canDeleteCognosCookie();
                            UserPreferences.deactivateWindow();
                            if (canDeleteCognosCookie) {
                                UserPreferences.cognosLogout();
                                UserPreferences.deleteCognosCookies();
                            }
                        });
                    }
                }
            });
        function _windowIsIframe() {
            try {
                return $window.self !== $window.top;
            } catch (e) {
                return true; // Security exceptions imply this is an iframe
            }
        }
    }
})();
