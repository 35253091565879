(function() {
    'use strict';

    /**
     * This service is to be used for Web Storage/Local Storage.
     * It will provide ability to store / retrieve key/value pairs in local storage
     */

    angular
        .module('alpha.common.services.alphaLocalStorage', [
            'ngStorage'])
        .factory('AlphaLocalStorageService', AlphaLocalStorageService);

    AlphaLocalStorageService.$inject = ['$localStorage'];
    function AlphaLocalStorageService($localStorage) {
        return {
            getDataFromStorage:getDataFromStorage,
            setDataInStorage:setDataInStorage,
            deleteDataFromStorage:deleteDataFromStorage,
            clearAllStorage:clearAllStorage
        };
        // Public methods

        /**
         * Used for getting data from local storage.
         *
         * @method getDataFromStorage
         *
         * @param {String} key for retrieving data
         */
        function getDataFromStorage(key){
            return $localStorage[key];
        }

        /**
         * Used for setting data into local storage.
         *
         * @method setDataInStorage
         *
         * @param {String} key for retrieving data
         * @param {Object} value to stored against data
         */
        function setDataInStorage(key,value){
            $localStorage[key]=value;
        }
        /**
         * Used for deleting the data from local storage using key
         *
         * @method deleteDataFromStorage
         *
         * @param {String} key for which you want to delete data
         *
         */
        function deleteDataFromStorage(key){
            if($localStorage[key]) {
                delete $localStorage[key];
            }
        }

        /**
         * Used for clearing all local storage data
         *
         * @method clearAllStorage
         *
         */
        function clearAllStorage(){
            $localStorage.$reset();
        }

    }

})();
