(function () {
    'use strict';
    angular
        .module('alpha.common.directives.alphaFileUpload', ['alpha.utils.Utils', 'alpha.utils.I18n','alpha.utils.Modals','UserPreferences'])
        .directive('alphaFileUpload', alphaFileUpload);

    alphaFileUpload.$inject = ['$timeout', 'Utils', 'I18nUtil', '$q', 'ModalService'];


    function alphaFileUpload($timeout, Utils, I18nUtil, $q, ModalService){
        return {
            restrict: 'A',
            link: link,
            require: '?ngModel',
            scope:{
                upload : '&'
            }
        };

        function link(scope, element, attrs, ngModelCtrl){
            if (_.isObject(ngModelCtrl)) {
                scope.$watch(function () {
                   return ngModelCtrl.$modelValue;
                }, function(newVal) {
                    if (!newVal) {
                        _clearInput();
                    }
                });
            }
            element.on('change', function(event){
                var file = event.target.files[0],
                    fileExtension,
                    invalidFileTypeLbl = I18nUtil.getI18nString('LBL_FILE_TYPE_INVALID', 'Invalid file type'),
                    invalidFileTypedMsg = I18nUtil.getI18nString('MSG_FILE_TYPE_INVALID', 'Invalid file type'),
                    invalidFileNoExtensionMsg = I18nUtil.getI18nString('MSG_FILE_EXTENSION_REQUIRED', 'File must have an extension'),
                    errorMessage;

                if(file) {
                    fileExtension = _getExtension(file.name);
                    _checkValidFileType(fileExtension, event).then(function () {
                        $timeout(function () {
                            var formData = new FormData();
                            formData.append('file', file);
                            scope.upload({
                                formData: formData,
                                fileSize: _.get(element, '[0].files[0].size')
                            }).then(_clearInput)
                                .catch(function (data) {
                                    ModalService.openAlert(invalidFileTypeLbl, invalidFileTypedMsg);
                                });
                        });
                    }).catch(function () {
                        _clearInput();
                        if (_.isUndefined(fileExtension) || fileExtension.length === 0) {
                            errorMessage = invalidFileNoExtensionMsg;
                        } else {
                            errorMessage = invalidFileTypedMsg + ': ' + fileExtension;
                        }
                        ModalService.openAlert(invalidFileTypeLbl, errorMessage);
                    });
                } else {
                    if (_.isObject(ngModelCtrl)) {
                        ngModelCtrl.$setViewValue(null);
                    }
                }
                if (_.isObject(ngModelCtrl)) {
                    ngModelCtrl.$setDirty();
                }

                function _getExtension(filename) {
                    var index;

                    if(_.isUndefined(filename)) {
                        return '';
                    }
                    index = filename.lastIndexOf('.');
                    if(index !== -1) {
                        return filename.substr(index + 1, filename.length - 1);
                    } else {
                        return '';
                    }
                }
            });
            function _clearInput() {
                element.val('');
            }
        }


        function _checkValidFileType(fileExtension, event){
            var deferred = $q.defer(),
                acceptFileTypes;
            if(event.currentTarget && event.currentTarget.getAttribute('accept') &&
                event.currentTarget.getAttribute('accept') != 'image/*'){
                setTimeout(function(){
                    if(_isFileTypeAccepted(event.currentTarget, '.'+fileExtension)){
                        deferred.resolve();
                    } else {
                        deferred.reject();
                    }}, 100);
            } else {
                Utils.getFileTypeWhiteList().then(function(data) {
                    acceptFileTypes = new RegExp('(' + data.value.split(',').join('|') + ')', 'i');
                    if(acceptFileTypes.test(fileExtension)) {
                        deferred.resolve();
                    } else {
                        deferred.reject();
                    }
                });
            }
            return deferred.promise;
        }

        function _isFileTypeAccepted(fileInput, type){
            var result = false;
           if(fileInput){
               var acceptedTypes = fileInput.getAttribute('accept');
               if(acceptedTypes){
                   var typeArray = acceptedTypes.split(',');
                   result = _.indexOf(typeArray, type) != -1;
               }
           }
           return result;
        }
    }
})();