(function() {
    'use strict';
    angular
        .module('alpha.login.Login')
        .constant('USER_ID','userId')
        .controller('LoginModalController', LoginModalController);

    LoginModalController.$inject = [
        'LoaderService',
        'LoginService',
        '$uibModalInstance',
        'I18nUtil',
        '$timeout',
        '$cookies',
        '$window',
        'USER_ID',
        'LOGIN_PAGE_PATH',
        'AlphaLocalStorageService'
    ];

    function LoginModalController(
        LoaderService,
        LoginService,
        $uibModalInstance,
        I18nUtil,
        $timeout,
        $cookies,
        $window,
        USER_ID,
        LOGIN_PAGE_PATH,
        AlphaLocalStorageService
    ) {
        var vm = this;

        vm.doLogin = doLogin;
        vm.closeModal = closeModal;
        vm.validateTotp = validateTotp;
        vm.showLoginForm = showLoginForm;
        vm.showTotpForm = showTotpForm;
        vm.forgotPassword = forgotPassword;
        vm.showForgotPassword = showForgotPassword;

        // Initialization

        vm.login = {
            name: AlphaLocalStorageService.getDataFromStorage(USER_ID),
            password: null,
            rememberMe: LoginService.getDefaultRememberMe()
        };
        _loadLanguageOptions();
        vm.showLoginForm();

        // Public methods

        function closeModal() {
            $uibModalInstance.close();
        }

        function doLogin() {
            LoaderService.startLoading();
            LoginService.renewCsrfToken()
                .then(function() {
                    return LoginService.logIn(vm.login.name, vm.login.password, vm.login.rememberMe, vm.login.preferredLanguage,'Y');
                })
                .then(function(data) {
                    if ($uibModalInstance && LoginService.setDefaultName(vm.login.name)) {
                        if (data.IS_MFA) {
                            vm.showTotpForm();
                        } else {
                            $uibModalInstance.close();
                        }
                    }
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }

        function forgotPassword() {
            $window.location = applicationContextRoot + '/dynamic/password-management.jsp';
        }

        function showForgotPassword() {
            var customLoginAlias = localStorage.getItem('SHOW_FORGOT_PASSWORD');
            return customLoginAlias !== 'N';
        }

        function validateTotp() {
            LoaderService.startLoading();
            LoginService.validateTotp(vm.login.totpCode, vm.login.trustCookies, vm.login.preferredLanguage)
                .then(function(redirectionUrl) {
                    if (_.endsWith(redirectionUrl, LOGIN_PAGE_PATH)) {
                        vm.showLoginForm();
                    } else {
                        LoginService.getClient()
                            .then(function(clientData) {
                                LoginService.setCookiePolicy(clientData);
                                vm.closeModal();
                            });
                    }
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }

        function showLoginForm() {
            vm.login.messages = [];
            vm.showTotp = false;
            $timeout(_focusInput);
        }

        function showTotpForm() {
            vm.login.totpCode = null;
            vm.login.messages = [];
            vm.showTotp = true;
        }

        // Private functions

        function _showError(message) {
            vm.login.messages = [{
                text: message,
                messageType: 'Error'
            }];
        }

        function _focusInput() {
            if (vm.login.name) {
                angular.element('#login_modal_password').focus();
            } else {
                angular.element('#login_modal_username').focus();
            }
        }

        function _loadLanguageOptions() {
            LoaderService.startLoading();
            LoginService.getLanguageOptions()
                .then(function(options) {
                    vm.languageOptions = options;
                    vm.login.preferredLanguage = LoginService.getDefaultLanguage(options);
                })
                .catch(_showError)
                .finally(LoaderService.stopLoading);
        }
    }
})();
