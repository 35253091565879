(function () {
    'use strict';
    angular
        .module('alpha.common.directives.alphaCommonBannerModal', ['alpha.utils.Events'])
        .directive('alphaCommonBannerModal', AlphaCommonBannerModal);

    AlphaCommonBannerModal.$inject = ['$timeout', 'AlphaCommonBannerModalService', 'Events'];
    function AlphaCommonBannerModal($timeout, AlphaCommonBannerModalService, Events) {
        return {
            restrict: 'E',
            link: link,
            scope: {},
            templateUrl: applicationContextRoot + '/static/custom/common/app/partials/directives/alphaCommonBannerModal.directive.html'
        };

        function link(scope) {
            scope.proceed = proceed;
            scope.cancel = cancel;
            scope.show = false;
            scope.showOverlay = false;
            scope.showButtons = true;

            Events.subscribe('BannerModalSubscriptionOpen', scope, 'BannerModal', 'open', _showHandler);
            Events.subscribe('BannerModalSubscriptionCancel', scope, 'BannerModal', 'cancel', _cancelHandler);

            function _showHandler(data, event) {
                scope.show = true;
                scope.message = data.message;
                scope.type = data.type;
                scope.messageType = data.messageType;
                scope.showButtons = data.showButtons;

                if (data.displayTime) {
                    $timeout(function () {
                        scope.show = false;
                    }, data.displayTime);
                }
            }

            function _cancelHandler(data, event) {
                cancel();
            }

            function proceed() {
                AlphaCommonBannerModalService.resolve();
                scope.show = false;
            }

            function cancel() {
                AlphaCommonBannerModalService.reject();
                scope.show = false;
            }
        }
    }

})();
