(function () {
    'use strict';

    angular
        .module('alpha.common.alphaSmartTable')
        .controller('AlphaSmartTableDownloadController', AlphaSmartTableDownloadController);

    AlphaSmartTableDownloadController.$inject = ['$uibModalInstance', 'queryName','downLoadType'];

    function AlphaSmartTableDownloadController($uibModalInstance, queryName,downLoadType){
      var vm = this;
      vm.download = download;
      vm.cancel = cancel;
      vm.fileName = queryName;
      vm.downLoadType = downLoadType;


      function download(){
          $uibModalInstance.close({name: vm.fileName});
      }
      function cancel(){
          $uibModalInstance.dismiss();
      }
    }
})();