(function() {
    'use strict';

    /**
     * This light service helps with the execution of business rules and formulas.
     * It was separated from the main rules module so it can be included in the many
     * parts of the application where input directives that support rules are used.
     */

    angular
        .module('alpha.common.services.ruleExecutionHelper', [])
        .factory('RuleExecutionHelper', RuleExecutionHelper);

    RuleExecutionHelper.$inject = [];

    function RuleExecutionHelper() {
        var _subscribers = {},
            _recentFieldEvents = [],
            _associationListeners = [];

        return {
            isInContext: isInContext,
            subscribeToFieldEvents: subscribeToFieldEvents,
            subscribeToChangeEvent: subscribeToChangeEvent,
            publishFieldEvent: publishFieldEvent,
            listenToChange: listenToChange,
            listenToAssociationChange: listenToAssociationChange,
            clearAssociationListeners: clearAssociationListeners,
            triggerAssociationChange: triggerAssociationChange
        };

        // Public methods

        /**
         * Returns true if a full path falls within the context of a partial path.
         * This is used to control the precision of rule execution.
         *
         * @method isInContext
         *
         * @param {String} partialPath Partial record type path to verify
         * @param {String} fullPath Full record type path to test against
         *
         * @returns {Boolean} Whether the full path ends with the partial path
         */
        function isInContext(partialPath, fullPath) {
            if (partialPath === fullPath) {
                return true;
            } else if (_.isString(partialPath) && _.isString(fullPath)) {
                return fullPath.charAt(fullPath.length - partialPath.length - 1) === '.' && _.endsWith(fullPath, partialPath);
            } else {
                return false;
            }
        }
        /**
         * Event handlers are invoked with the same arguments as those from the
         * Events service; that lets components use a single handler for general
         * system events and those related to rule execution.
         *
         * @callback eventHandler
         *
         * @param {Object} data Custom data payload
         * @param {*} data.value The value that was published
         * @param {String} data.context Record type or record type path that was published
         * @param {Object} event Native event payload
         * @param {String} event.topic
         */
        /**
         * Processes a standardized set of element attributes and subscribes to
         * the appropriate event channels. The handler callback will be invoked
         * whenever an event context applies to the input. Each handler will be
         * invoked immediately if a relevant event was published in the past.
         *
         * @method subscribeToFieldEvents
         *
         * @param {Object} scope The scope of an input directive
         * @param {String[]} topics The topics to subscribe to
         * @param {Object} attrs Some attributes from the input element
         * @param {String} [attrs.eventRecordType] The record type of the input
         * @param {String} [attrs.eventFieldType] The field type of the input
         * @param {String} [attrs.eventContext] The context of the input
         * @param {eventHandler} handler The event handler to be invoked
         *
         * @returns {String[]} Event channels that should be subscribed to
         */
        function subscribeToFieldEvents(scope, topics, attrs, handler) {
            _.forEach(_getEventChannels(attrs), function(channel) {
                _subscribe(scope, channel, topics, function(data, event) {
                    if (isInContext(data.context, attrs.eventContext)) {
                        handler(data, event);
                    }
                });
                _.forEach(_getRecentFieldEvents(channel, topics), function(recentEvent) {
                    if (isInContext(recentEvent.data.context, attrs.eventContext)) {
                        handler(recentEvent.data, recentEvent.event);
                    }
                });
            });
            function _getEventChannels(attrs) {
                var channels = [];
                if (!_.isEmpty(attrs.eventRecordType)) {
                    channels.push(attrs.eventRecordType);
                    if (!_.isEmpty(attrs.eventFieldType)) {
                        channels.push(attrs.eventRecordType + '.' + attrs.eventFieldType);
                    }
                }
                return channels;
            }
            function _getRecentFieldEvents(channel, topics) {
                return _.filter(_recentFieldEvents, function(recentEvent) {
                    return recentEvent.channel === channel && _.includes(topics, recentEvent.event.topic);
                });
            }
        }
        /**
         * Adds change listeners to a provided scope and returns them in a collection
         * to be stored in whatever component invoked this method. For optimization,
         * only one Angular watch will be added for each field, although any number of
         * callbacks can be associated with that watch.
         *
         * Fields will only be watched if the context in the event data falls within
         * the context of the record in question.
         *
         * Callbacks are executed with the new value and old value as arguments. They
         * will be executed at least once when their listener is added.
         *
         * @method subscribeToChangeEvent
         *
         * @param {Object} scope The scope on which the record data can be watched
         * @param {String} scopePath The dot-separated path to the record data on the scope
         * @param {String} context Record type or record type path to the record being watched
         *
         * @returns {Object[]} Collection of new change listeners
         */
        function subscribeToChangeEvent(scope, scopePath, context) {
            var changeListeners = [];
            _subscribe(scope, 'RuleExecution', ['listenToChange'], function(data) {
                if (_.isFunction(data.callback)) {
                    if (_.isArray(data.field)) {
                        _.forEach(data.field, function(field, i) {
                            var eventContext = _.isArray(data.context) ? data.context[i] : data.context;
                            if (isInContext(eventContext, context)) {
                                _listenToChange(data.field[i], data.callback);
                            }
                        });
                    } else if (isInContext(data.context, context) && _.isString(data.field)) {
                        _listenToChange(data.field, data.callback);
                    }
                }
            });
            function _listenToChange(field, callback) {
                // Optimizes listeners by only adding one watch for each field
                var listener = _.find(changeListeners, {field: field}),
                    currentValue;
                if (_.isObject(listener)) {
                    listener.callbacks.push(callback);
                    currentValue = _.get(scope, scopePath + '.' + field);
                    callback(currentValue, currentValue); // simulate the initial scope.$watch callback
                } else {
                    listener = {
                        field: field,
                        callbacks: [callback]
                    };
                    listener.clearWatch = scope.$watch(scopePath + '.' + field, function(newVal, oldVal) {
                        _.forEach(listener.callbacks, function(callback) {
                            callback(newVal, oldVal);
                        });
                    });
                    changeListeners.push(listener);
                }
            }
            return changeListeners;
        }
        /**
         * Publishes an event to set the state of a field. Recent events are cached to
         * be applied to any fields that subscribe to them after they are published.
         *
         * @method publishFieldEvent
         *
         * @param {String} context Record type or record type path to the record the event is for
         * @param {String} fieldType Field type the event is for
         * @param {Object} topic Topic of the event
         * @param {*} value Value of the event
         */
        function publishFieldEvent(context, fieldType, topic, value) {
            var channel = _getEventChannel(context, fieldType),
                data = {value: value, context: context};
            _publish(channel, topic, data);
            _.remove(_recentFieldEvents, function(event) {
                return event.channel === channel && event.data.context === context && event.event.topic === topic;
            });
            _recentFieldEvents.push({
                channel: channel,
                data: data,
                event: {topic: topic}
            });
            function _getEventChannel(context, fieldType) {
                if (_.isEmpty(fieldType)) {
                    return _getRecordTypeFromContext(context);
                } else {
                    return _getRecordTypeFromContext(context) + '.' + fieldType;
                }
            }
            function _getRecordTypeFromContext(context) {
                return _.includes(context, '.') ? _.last(context.split('.')) : context;
            }
        }
        /**
         * Callbacks are invoked with values similar to a $scope.$watch().
         *
         * @callback changeCallback
         *
         * @param {*} newVal The new raw value of the field
         * @param {*} oldVal The old raw value of the field
         */
        /**
         * Publishes an event to listen to value changes for a given field.
         *
         * @method listenToChange
         *
         * @param {String} context Record type or record type path to the record the event is for
         * @param {String|String[]} fieldType Field type(s) the event is for
         * @param {changeCallback} callback Callback to be invoked with the new value and old value
         */
        function listenToChange(context, fieldType, callback) {
            _publish('RuleExecution', 'listenToChange', {
                context: context,
                field: fieldType,
                callback: callback
            });
        }
        /**
         * Adds a listener for a change of an associated record.
         *
         * TODO: This method does not treat context in the same way that others do.
         *
         * @method listenToAssociationChange
         *
         * @param {String} context Record type path excluding the record the event is for
         * @param {String} recordType Record type the event is for
         * @param {Function} callback Callback to be invoked
         */
        function listenToAssociationChange(context, recordType, callback) {
            _associationListeners.push({
                context: context,
                recordType: recordType,
                callback: callback
            });
        }
        /**
         * Clears a listener for a change of an associated record.
         *
         * TODO: This method needs to accept a record type to remove a specific listener.
         *
         * @method clearAssociationListeners
         *
         * @param {String} context Record type path excluding the record the event is for
         */
        function clearAssociationListeners(context) {
            _.remove(_associationListeners, function (listener) {
                return isInContext(context, listener.context);
            });
        }
        /**
         * Triggers listeners for a change of an associated record.
         *
         * TODO: This method does not treat context in the same way that others do.
         *
         * @method triggerAssociationChange
         *
         * @param {String} context Record type path excluding the record the event is for
         * @param {String} recordType Record type the event is for
         */
        function triggerAssociationChange(context, recordType) {
            _.forEach(_associationListeners, function(listener) {
                if (listener.recordType === recordType && isInContext(context, listener.context)) {
                    listener.callback();
                }
            });
        }

        // Private functions
        function _subscribe(scope, channel, topics, callback) {
            if (_.isUndefined(_subscribers[scope.$id])) {
                _subscribers[scope.$id] = [];
                scope.$on('$destroy', function() {
                    delete _subscribers[scope.$id];
                });
            }
            _.forEach(topics, function(topic) {
                _subscribers[scope.$id].push({
                    channel: channel,
                    topic: topic,
                    callback: callback
                });
            });
        }
        function _publish(channel, topic, data) {
            _.forEach(_subscribers, function(subscriberSet) {
                _.forEach(subscriberSet, function(subscriber) {
                    if (subscriber.channel === channel && subscriber.topic === topic) {
                        subscriber.callback(data, {topic: topic});
                    }
                });
            });
        }
    }
})();
