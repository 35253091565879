(function () {
    'use strict';

    angular
        .module('alpha.utils.Loader', [
            'alpha.utils.I18n',
            'alpha.common.services.alphaTabManager'
        ])
        .factory('LoaderService', LoaderService);

    LoaderService.$inject = ['I18nUtil', 'AlphaTabManagerService'];

    function LoaderService(I18nUtil, AlphaTabManagerService) {
        var count = 0,
            messageReset = {
                margin: '',
                padding: '',
                width: '',
                top: '',
                left: '',
                textAlign: '',
                color: '',
                border: '',
                backgroundColor: '',
                position: '',
                zIndex: ''
            },
            overlayReset = {
                zIndex: ''
            };

        return {
            setCount: setCount,
            getCount: getCount,
            startLoading: startLoading,
            stopLoading: stopLoading,
            startLoadingElement: startLoadingElement,
            stopLoadingElement: stopLoadingElement
        };

        function setCount(val) {
            count = val;
        }
        function getCount() {
            return count;
        }
        /**
         * Displays the loading indicator if it isn't already showing.
         *
         * @method startLoading
         *
         * @param {String} [message] Message to display instead of the vendor default
         */
        function startLoading(message) {
            count++;
            if (count === 1) {
                if (!_.isString(message)) {
                    message = I18nUtil.getI18nString('MSG_PLEASE_WAIT', 'Please wait');
                }
                jQuery.blockUI({
                    css: messageReset,
                    overlayCSS: overlayReset,
                    message: '<h1>' + message + '&hellip;</h1>'
                });
                AlphaTabManagerService.setLoadingState(AlphaTabManagerService.getTabId(), true);
            }
        }
        /**
         * Hides the loading indicator if all are starts are resolved.
         *
         * @method stopLoading
         */
        function stopLoading() {
            count--;
            if (count <= 0) {
                jQuery.unblockUI();
                AlphaTabManagerService.setLoadingState(AlphaTabManagerService.getTabId(), false);
                count = 0;
            }
        }
        /**
         * Displays the loading indicator on a specific element.
         *
         * @method startLoadingElement
         *
         * @param {Object} element jQuery element to display the loading indicator on
         * @param {String} [message] Message to display instead of the vendor default
         */
        function startLoadingElement(element, message) {
            if (!_.isString(message)) {
                message = I18nUtil.getI18nString('MSG_PLEASE_WAIT', 'Please wait');
            }
            message = _.escape( message );
            element.block({
                css: angular.extend({}, messageReset, {zIndex: '101'}),
                overlayCSS: angular.extend({}, overlayReset, {zIndex: '100'}),
                message: '<h1>' + message + '&hellip;</h1>'
            });
        }
        /**
         * Hides the loading indicator on a specific element.
         *
         * @method stopLoadingElement
         *
         * @param {Object} element jQuery element the loading indicator is displayed on
         */
        function stopLoadingElement(element) {
            element.unblock();
        }
    }
})();
