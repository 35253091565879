(function () {
    'use strict';

    /**
     * Adds fixed header to a Smart Table grid. Intended
     * to be used inside of an st-pagination element
     *
     * @method alphaSmartTableFixedHeader
     *
     * @example
     *      HTML:
     *      <alpha-smart-table-fixed-header>
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableFixedHeader', alphaSmartTableFixedHeader);

    alphaSmartTableFixedHeader.$inject = [];

    function alphaSmartTableFixedHeader(){
        var scrollLeft = 0;
        return {
            restrict: 'A',
            replace: false,
            link: link
        };
        function link(scope, element, attrs) {
            var combinedElement,
                tableHeader = element.find('.alpha-table-head'),
                tableBody = element.find('.alpha-table-body');
            if(!!scope.$eval(attrs.useDefaultClass)){
                combine(tableHeader.add(tableBody));
            }else{
                combinedElement = element.find('.alpha-table-head').addClass("scroll-height-header");
                combine(combinedElement.add(element.find('.alpha-table-body').addClass("scroll-height-body")));
                element.find('alpha-smart-table-footer').addClass('scroll-height-footer');
            }

        }
        function combine(elements){
            elements.on('scroll', function(e){
                if(e.isTrigger){
                    e.target.scrollLeft = scrollLeft;
                }else {
                    scrollLeft = e.target.scrollLeft;
                    elements.each(function (element) {
                        if( !this.isSameNode(e.target) ){
                            jQuery(this).trigger('scroll');
                        }
                    });
                }
            });
        }
    }
})();
