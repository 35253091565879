(function () {
    'use strict';
    angular
        .module('alpha.common.directives.alphaOnErrorScr', [])
        .directive('alphaOnErrorScr', function () {
        return {
            restrict: 'A',
            scope: {
                alphaOnErrorScr: '&'
            },
            link: function (scope, element, attr) {
                element.on('error', function () {
                    element.attr('src', scope.alphaOnErrorScr());
                })
            }
        }
    });
})();