(function() {
    'use strict';

    /**
     * Adds pagination with a summary to a Smart Table grid.
     *
     * @method alphaSmartTableFooter
     *
     * @example
     *      HTML:
     *      <alpha-smart-table-footer id="some_id">
     *          items-per-page="10"
     *          page-size-options="[5, 10, 15, 20]"></alpha-smart-table-footer>
     *
     * @param {Number} [items-per-page] The default number of items to display on each page.
     * @param {Array} [page-size-options] Overrides the default page size options dropdown.
     */

    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableFooter', alphaSmartTableFooter);

    function alphaSmartTableFooter() {
        return {
            restrict: 'E',
            template: template,
            scope: true,
            require: '^alphaSmartTable',
            link: link,
            controller: ['$scope', '$attrs', controller],
            controllerAs: 'astfCtrl'
        };
        function link(scope, element, attrs, astCtrl) {
            scope.isTableVisible = isTableVisible;
            scope.showPageCount = !attrs.showPageCount || attrs.showPageCount === 'true';
            scope.pageSizeOptions = attrs.pageSizeOptions ? scope.$eval(attrs.pageSizeOptions) : [25, 35, 50, 100];
            scope.pagination = {};

            attrs.$observe('itemsPerPage', function(value) {
                var itemsPerPage = parseInt(value);
                if (itemsPerPage >= 0) {
                    scope.pagination.itemsPerPage = itemsPerPage;
                }
            });

            if (attrs.pageSizeOptions) {
                attrs.$observe('pageSizeOptions', function (value) {
                    var pageSizeOptions = scope.$eval(value);
                    if (_.isArray(pageSizeOptions)) {
                        scope.pageSizeOptions = pageSizeOptions;
                        scope.$broadcast('updatePageSizeOptions', scope.pageSizeOptions);
                    }
                });
            }

            function isTableVisible(){
                return _.isBoolean(astCtrl.showTable) ? astCtrl.showTable : true;
            }
        }
        function controller($scope, $attrs) {
            var self = this;
            self.$onInit = function() {
                controllerInit(self, $scope, $attrs);
            };
        }
        function controllerInit(vm, $scope, $attrs) {

            vm.setPageSize = setPageSize;
            vm.getPageSizeOptions = getPageSizeOptions;
            vm.getShowPageCount = getShowPageCount;
            vm.isGridInEditMode = isGridInEditMode;

            function setPageSize(itemsPerPage) {
                $scope.pagination.itemsPerPage = itemsPerPage;
            }
            function getPageSizeOptions() {
                return $scope.pageSizeOptions;
            }
            function getShowPageCount() {
                return $scope.showPageCount;
            }

            function isGridInEditMode(){
                return AlphaSmartTableService.isGridInEditMode();
            }
        }
        function template() {
            return  '<ng-include ng-if="astCtrl.serverSideMode"' +
                        ' ng-show="isTableVisible()"' +
                        ' src="\'' + applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/alphaSmartTableFooter.directive.html\'"></ng-include>' +
                    '<div ng-if="!astCtrl.serverSideMode"' +
                        ' ng-show="isTableVisible()"' +
                        ' st-pagination' +
                        ' st-items-by-page="pagination.itemsPerPage"' +
                        ' st-template="' + applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/alphaSmartTableFooter.directive.html"></div>';
        }
    }
})();
