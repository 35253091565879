(function () {
    'use strict';

    angular
        .module('alpha.common.directives.alphaAddButton', [
            'alpha.utils.Events', 'alpha.common.services.security'
        ])
        .directive('alphaAddButton', alphaAddButton);

    alphaAddButton.$inject = ['Events', 'SecurityService'];

    function alphaAddButton(Events, SecurityService) {
        return {
            restrict: 'AE',
            replace: true,
            link: link,
            template: template,
            scope: true
        };

        function link($scope, $element, $attrs){
            // Scope Properties
            $scope.containerId = $attrs.alphaAddButtonIdentifier;
            $scope.rowRecordType = {};
            $scope.typeSelectorOpen = false;
            $scope.isViewMode = $attrs.alphaAddButtonIsViewMode === 'true';
            $scope.viewModeNamespace = $attrs.alphaAddButtonDetailMode === 'true' ? 'detail' : 'list';
            $scope.isDynamic = $attrs.alphaAddButtonGridIsDynamic === 'true';

            // Scope Methods
            $scope.onChangeType = onChangeType;
            $scope.addRowInViewMode = addRowInViewMode;
            $scope.showAddButton = showAddButton;
            $scope.addRowDynamicGrid = addRowDynamicGrid;

            // Public Methods
            /**
             * Handle on change event of Type dropdown
             * @param selectedType
             */
            function onChangeType(selectedType) {
                var selectedRecordTyp = selectedType;
                if(selectedRecordTyp == null) {
                    if ($scope.defaultselectedRowType && SecurityService.recordCanBeCreated($scope.defaultselectedRowType.id)) {
                        selectedRecordTyp = $scope.defaultselectedRowType;
                        $scope.selectedRowType = {value: $scope.defaultselectedRowType.id};
                    }
                }
                if (_.has(selectedRecordTyp, 'id')) {
                    var rowRecordType = _.find($scope.selectableGroups, function(group) { return _.get(group, '_recordType.id', null) === selectedRecordTyp.id; }, 0);
                    if (!_.isEmpty(rowRecordType)) {
                        $scope.rowRecordType = rowRecordType._recordType;
                    }
                    $scope.addRow($scope.rowRecordType, selectedType);
                }
            }

            /**
             * When in View mode redirect to Edit mode
             */
            function addRowInViewMode() {
                $scope.addMasterDetailRow($attrs.alphaAddButtonLayoutName, $scope.containerId);
            }

            /**
             * Show / Hide add button
             * @param {string} parentRecordType
             * @param {string} associatedRecordType
             * @return {boolean} returns true or false
             */
            function showAddButton(parentRecordType, associatedRecordType) {
                if (_.isEmpty(parentRecordType) || _.isEmpty(associatedRecordType)) {
                    return false;
                }
                return !$scope.addDisabled(parentRecordType, associatedRecordType);
            }

            /**
             * Handle dynamic grid add click.
             * If there is default row type then open new form with default row type
             */
            function addRowDynamicGrid() {
                $scope.selectRow(null, true);
                if ($scope.defaultselectedRowType && SecurityService.recordCanBeCreated($scope.defaultselectedRowType.id)) {
                    $scope.onChangeType(null);
                }
                $scope.goToTypeSelection();
            }
        }


        function template($element, $attrs){
            var htmlTemplate = '',
                listOrDetail = $attrs.alphaAddButtonDetailMode === 'true' ? 'detail' : 'list',
                className = 'btn-action-master-detail';
            if($attrs.alphaAddButtonIsViewMode === 'true') {
                htmlTemplate = '' +
                    '<button ng-show="showAddButton(\'' + $attrs.alphaAddButtonPrimaryRecordTypeName + '\', \'' + $attrs.alphaAddButtonAssocRecordTypeName + '\')"' +
                    '       ng-disabled="isAddingRow() || isEditingDisabled || recordCannotBeCreated() || isParentAnonymized"'+
                    '       disabled' +
                    '       id="' + $attrs.alphaAddButtonIdentifier + '_' + listOrDetail + '_mode_add"' +
                    '       class="' + className + '"' +
                    '       uib-tooltip="{{\'LBL_ADD\' | translate: \'Add\'}}"'+
                    '       tooltip-append-to-body="true"' +
                    '       ng-click="addRowInViewMode()">'+
                    '           <span class="glyphicon glyphicon-plus"></span>'+
                    '</button>';
            } else {
                if ($attrs.alphaAddButtonGridIsDynamic === 'true') {
                    htmlTemplate = '' +
                        '<button ng-show="showAddButton(\'' + $attrs.alphaAddButtonPrimaryRecordTypeName + '\', \'' + $attrs.alphaAddButtonAssocRecordTypeName + '\')"' +
                        '       ng-disabled="isAddingRow() || isEditingDisabled || recordCannotBeCreated() || isParentAnonymized"' +
                        '       disabled' +
                        '       id="' + $attrs.alphaAddButtonIdentifier + '_' + listOrDetail + '_mode_add"' +
                        '       class="' + className + '"' +
                        '       ng-click="addRowDynamicGrid()"' +
                        '       uib-tooltip="{{\'LBL_ADD\' | translate: \'Add\'}}"'+
                        '       tooltip-append-to-body="true"' +
                        '       uib-popover-template="\'' + applicationContextRoot + '/static/custom/common/app/partials/popovers/alphaTypeSelector.html\'"' +
                        '       popover-class="alpha-popover alpha-addbtn-popover"' +
                        '       popover-is-open="typeSelectorOpen"' +
                        '       popover-placement="' + ($attrs.alphaAddButtonDetailMode === 'true' ? 'bottom' : 'right-bottom') + '">' +
                        '           <span class="glyphicon glyphicon-plus"></span>' +
                        '</button>';
                } else {
                    htmlTemplate = '' +
                        '<button ng-show="showAddButton(\'' + $attrs.alphaAddButtonPrimaryRecordTypeName + '\', \'' + $attrs.alphaAddButtonAssocRecordTypeName + '\')"' +
                        '       ng-disabled="isAddingRow() || isEditingDisabled || recordCannotBeCreated() || isParentAnonymized"'+
                        '       disabled' +
                        '       id="' + $attrs.alphaAddButtonIdentifier + '_' + listOrDetail + '_mode_add"' +
                        '       class="' + className + '"' +
                        '       uib-tooltip="{{\'LBL_ADD\' | translate: \'Add\'}}"'+
                        '       tooltip-append-to-body="true"' +
                        '       ng-click="addRow()">'+
                        '           <span class="glyphicon glyphicon-plus"></span>'+
                        '</button>';
                }
            }
            return htmlTemplate;
        }
    }
})();
