(function () {
    'use strict';

    angular
        .module('alpha.common.alphaLookupTree', [
            'AlphaApi',
            'UserPreferences',
            'alpha.utils.Utils',
            'alpha.utils.I18n',
            'alpha.utils.recordData',
            'alpha.utils.RequiredFieldUtils',
            'alpha.common.services.alphaTabManager',
            'alpha.common.services.ruleExecutionHelper'
        ]);
})();
