(function() {
    'use strict';

    /**
     * A directive to manage and display a set of tabs and their related content.
     * External control is handled by AlphaTabManagerService, which identifies the
     * location of this directive using window.AlphaTabManagerPresent. That allows
     * these tabs to be controlled from other windows.
     *
     * If a static tab configuration is provided, a tab will be added to the bar and
     * the contents of the directive element will be moved into the first container.
     * That initial tab can never be closed, but otherwise behaves like any other.
     *
     * If a preloaded tab configuration is provided at any time, a tab will be added
     * to the bar and its contents will be loaded in a frame.
     *
     * Frames are excluded from the template to prevent redraw, which can cause them
     * to reload unexpectedly.
     *
     * Example of a static tab configuration:
     * {
     *     id: 'tcor',
     *     title: {
     *         en_US: 'Workbench Home'
     *     },
     *     icon: 'icon-ic_workbench_home',
     *     details: [{
     *         label: 'Label 1',
     *         value: 'Value 1',
     *         dataType: 'String'
     *     }, {
     *         label: 'Label 2',
     *         value: 1467316305650,
     *         dataType: 'Date'
     *     }, {
     *         label: 'Label 3',
     *         value: 1467316305650,
     *         dataType: 'DateTime'
     *     }],
     *     isDirty: false
     * }
     *
     * @method alpha-tab-manager
     *
     * @example
     *      HTML:
     *      <alpha-tab-manager static-tab="staticTabConfig" preload-tab="preloadTabConfig">
     *          <!-- Static Tab Content -->
     *      </alpha-tab-manager>
     *
     * @param {Object} [static-tab] Configuration for a static tab in the same view.
     * @param {Object} [preloaded-tab] Configuration for a remote tab to load when initialized.
     */

    angular
        .module('alpha.common.directives.alphaTabManager', [
            'alpha.utils.Events',
            'ui.sortable',
            'ui.bootstrap',
            'alpha.common.services.alphaLocalStorage',
            'alpha.common.services.authentication',
            'UserPreferences',
            'alpha.utils.Modals',
            'alpha.utils.Loader',
            'alpha.common.services.navigation'
        ])
        .directive('alphaTabManager', alphaTabManager);

    alphaTabManager.$inject = [
        'Events',
        '$window',
        '$log',
        '$timeout',
        'AlphaLocalStorageService',
        'UserPreferences',
        'ModalService',
        'LoaderService',
        'AuthenticationService',
        '$cookies',
        'NavigationService'
    ];

    function alphaTabManager(
        Events,
        $window,
        $log,
        $timeout,
        AlphaLocalStorageService,
        UserPreferences,
        ModalService,
        LoaderService,
        AuthenticationService,
        $cookies,
        NavigationService
    ) {
        var channel = 'alphaTabManager';
        return {
            restrict: 'E',
            link: link,
            templateUrl: applicationContextRoot + '/static/custom/common/app/partials/directives/alphaTabManager.directive.html',
            transclude: true,
            scope: {
                staticTab: '=',
                preloadedTab: '='
            }
        };
        function link(scope, element, attrs) {
            var initializing = true,
                selectedTabId,
                previousTabId,
                storageKey,
                windowResizeTimeout,
                current_locale = $cookies.get('LOCALE');

            // Scope methods

            scope.selectTab = selectTab;
            scope.tabIsSelected = tabIsSelected;
            scope.closeTab = closeTab;
            scope.getTabTitle = getTabTitle;

            // Initialization

            $window.AlphaTabManagerPresent = true;
            scope.applicationContextRoot = applicationContextRoot;
            scope.tabs = [];

            scope.sortableOptions = {
                'ui-floating': true,
                cancel: '.unsortable',
                items: 'li:not(.unsortable)',
                start: function() {
                },
                update: function() {
                },
                beforeStop: function() {
                },
                stop: function () {
                    setTabsInLocalStorage();
                }
            };

            if (_.isObject(scope.staticTab)) {
                addTab(angular.extend({}, scope.staticTab, {permanent: true}));
            }

            initTabs();

            Events.subscribe(
                attrs.id + '__AlphaTabManager',
                scope,
                'AlphaTabManager',
                [
                    'addTab',
                    'updateTitle',
                    'updateTitleSecurely',
                    'updateDetails',
                    'selectTab',
                    'setDirtyState',
                    'setLoadingState',
                    'showLoadingIndicator',
                    'hideLoadingIndicator',
                    'updateTabUrl',
                    'closeTab'
                ],
                eventHandler
            );
            scope.$watch('preloadedTab', function(tab) {
                if (_.isObject(tab)) {
                    addTab(tab);
                }
            });
            jQuery($window).on('resize', function() {
                clearTimeout(windowResizeTimeout);
                windowResizeTimeout = setTimeout(truncateTitles, 500);
            });

            // Public methods

            function selectTab(id) {
                if (selectedTabId !== id) {
                    previousTabId = selectedTabId;
                    selectedTabId = id;
                    showTabContent(id);
                    triggerResize(id);
                }
            }
            function tabIsSelected(id) {
                return selectedTabId === id;
            }
            function closeTab(id, $event, doNotPrompt) {
                var keyId = id +  '_sTransDate';
                var keyRecordMode = id + '_recordMode';
                sessionStorage.removeItem(keyId);
                sessionStorage.removeItem(keyRecordMode);
                var tab = getTab(id);
                if (_.isObject(tab)) {
                    if (tab.isDirty && !doNotPrompt) {
                        ModalService.openDirtyStateConfirmation(null, null, true)
                            .then(function() {
                                _closeTab();
                                updateWidths();
                            });
                    } else {
                        _closeTab();
                        element.one('mouseleave.' + channel, function() {
                            scope.$apply(function() {
                                updateWidths();
                            });
                        });
                    }
                }
                if (_.isObject($event)) {
                    $event.stopPropagation();
                }
                function _closeTab() {
                    if (scope.tabIsSelected(id)) {
                        selectOtherTab(id);
                    }
                    destroyTabContent(id);
                    _.remove(scope.tabs, function(tab) {
                        return tab.id === id;
                    });
                    setTabsInLocalStorage();
                }
            }
            function getTabTitle(tab) {
                // This can be overridden in the DOM by the truncateTitles function
                return tab.secureTitle || tab.title;
            }

            // Private functions

            function eventHandler(data, event) {
                switch (event.topic) {
                    case 'addTab':
                        addTab(data);
                        break;
                    case 'selectTab':
                        scope.selectTab(data);
                        break;
                    case 'updateTitle':
                        updateTitle(data.id, data.title, data.clipLeft ,data.icon , data.cssClass, data.recordId);
                        break;
                    case 'updateTitleSecurely':
                        updateTitleSecurely(data.id, data.title, data.clipLeft, data.recordId);
                        break;
                    case 'updateDetails':
                        updateDetails(data.id, data.details);
                        break;
                    case 'setDirtyState':
                        setDirtyState(data.id, data.isDirty);
                        break;
                    case 'setLoadingState':
                        setLoadingState(data.id, data.isLoading);
                        break;
                    case 'showLoadingIndicator':
                        showLoadingIndicator(data);
                        break;
                    case 'hideLoadingIndicator':
                        hideLoadingIndicator(data);
                        break;
                    case 'updateTabUrl':
                        updateTabUrl(data.id, data.url);
                        break;
                    case 'closeTab':
                        scope.closeTab(data, null, true);
                        break;
                }
            }
            function addTab(origTab) {
                /* This rebuilds the tab object to prevent memory leaks
                    from windows that are closed after this is invoked.
                    Simply copying the object doesn't work. */
                var tab = {}, tabRecordId;
                _.forOwn(origTab, function(val, key) {
                    tab[key] = val;
                });
                if (tabIsValid(tab)) {
                    var lastlogin_locale = tab.locale;
                    if(!angular.isUndefined(tab.url) && _.includes(tab.url, 'lookupLibraries')) {
                        tab.name = 'DM_lookupLibraries' ;
                    }
                    if( !angular.isUndefined(tab.name) && !angular.isUndefined(lastlogin_locale) && (current_locale != lastlogin_locale)) {
                        tab.title = NavigationService.getModuleTitle(tab.name);
                    }

                    AuthenticationService.authenticateUser()
                        .then(function () {
                            tabRecordId = getTabRecordId(tab.id);
                            if(tabRecordId){
                                tab.id = tabRecordId.id;
                            }
                            if (tabExists(tab.id)) {
                                _checkTabDirtyState(tab.id);
                                _addExistingTab();
                            } else {
                                _addNewTab();
                            }
                            setTabsInLocalStorage();
                            scope.selectTab(tab.id);
                        });
                } else {
                    $log.error('Cannot add a tab without an ID.');
                }
                function _addNewTab() {
                    scope.tabs.push(tab);
                    if (_.isString(tab.url)) {
                        drawTabContent(tab);
                    }
                    updateWidths();
                }
                function _addExistingTab() {
                    var previousUrl = getTab(tab.id).url;
                    _.merge(getTab(tab.id), _.omit(tab, 'title')); // don't change the title of existing tabs
                    if(previousUrl !== tab.url){
                        element.find('iframe#' + tab.id).attr('src', tab.url);
                    }
                    $timeout(truncateTitles);
                }
                function _checkTabDirtyState(id){
                    var tab = getTab(id);
                    if (_.isObject(tab)) {
                        if (tab.isDirty){
                            _openDirtyStateConfirmationPopUp();
                        }
                    }
                }
                function _openDirtyStateConfirmationPopUp(){
                    ModalService.openDirtyStateConfirmation(null, null, true,false)
                        .then(function(response) {
                            if (response.action === 'continue') {
                                tab.isDirty = false;
                                element.find('iframe#' + tab.id).attr('src', tab.url);
                            }
                        });
                }
            }
            function updateTitle(id, title, clipLeft, icon, cssClass, recordId) {
                var tab = getTab(id);
                if (_.isObject(tab)) {
                    delete tab.secureTitle;
                    tab.title = title;
                    tab.clipLeft = clipLeft || false;
                    if(!_.isUndefined(icon)){
                        tab.icon = icon;
                    }
                    if(!_.isUndefined(cssClass)){
                        tab.cssClass = cssClass;
                    }
                    setTabsInLocalStorage();
                    $timeout(truncateTitles);
                    if (recordId && recordId != null) {
                        tab.recordId = recordId.toString();
                    }
                }
            }
            function updateTitleSecurely(id, title, clipLeft, recordId) {
                var tab = getTab(id);
                if (_.isObject(tab)) {
                    tab.secureTitle = title;
                    tab.clipLeft = clipLeft || false;
                    setTabsInLocalStorage();
                    $timeout(truncateTitles);
                    if (recordId && recordId != null) {
                        tab.recordId = recordId.toString();
                    }
                }
            }
            function updateDetails(id, details) {
                /* This rebuilds the details object to prevent memory leaks
                    from windows that are closed after this is invoked.
                    Simply copying the object doesn't work. */
                var tab = getTab(id);
                if (_.isObject(tab)) {
                    tab.details = [];
                    _.forEach(details, function(detail) {
                        tab.details.push({
                            ordinal: detail.ordinal,
                            label: detail.label,
                            value: detail.value
                        });
                    });
                }
            }
            function updateTabUrl(id, url){
                var tab = getTab(id);
                if (_.isObject(tab)) {
                    tab.url = url;
                    element.find('iframe#' + tab.id).attr('src', tab.url);
                    setTabsInLocalStorage();
                    $timeout(truncateTitles);
                }
            }
            function setDirtyState(id, isDirty){
                var tab = getTab(id);
                if (_.isObject(tab)) {
                    tab.isDirty = isDirty;
                }
            }
            function setLoadingState(id, isLoading){
                var tab = getTab(id);
                if (_.isObject(tab)) {
                    tab.isLoading = isLoading;
                }
            }
            function getTab(id) {
                return _.find(scope.tabs, {id: id});
            }
            function getTabRecordId(id) {
                return _.find(scope.tabs,  {recordId: id});
            }
            function getTabIndex(id) {
                return _.findIndex(scope.tabs, {id: id});
            }
            function tabExists(id) {
                return !_.isEmpty(getTab(id));
            }
            function tabIsValid(tab) {
                return _.isObject(tab) && _.isString(tab.id);
            }
            function updateWidths() {
                scope.tabWidth = (100 / scope.tabs.length) + '%';
                $timeout(truncateTitles);
            }
            function truncateTitles() {
                /*  We must use jQuery to do this for left-truncated tabs since
                    there is no CSS solution. jQuery methods can return rounded
                    values, so to err on the side of caution we ignore its decimals
                    and add or subtract some pixels to the widths. Checking the
                    title length protects against an infinite loop if a tab is too
                    small for any characters to be displayed. This must be run in
                    a $timeout if the model is being modified; otherwise, it might
                    run before the view is up-to-date. */
                _.forEach(_.filter(scope.tabs, {clipLeft: true}), function(tab) {
                    var tabElement = jQuery('#' + tab.id + '-tab'),
                        titleElement = tabElement.find('.alpha-main-tab-dynamic-title'),
                        iconWidth = Math.floor(tabElement.find('.alpha-main-tab-icon').outerWidth(true) + 2),
                        containerWidth = Math.floor(tabElement.find('.alpha-main-tab-link').width() - 2);
                    titleElement.text(getTabTitle(tab));
                    while (titleElement.text().length > 1 && _titleIsTooWide()) {
                        titleElement.text('\u2026' + titleElement.text().substr(2));
                    }
                    function _titleIsTooWide() {
                        return iconWidth + Math.floor(titleElement.outerWidth(true) + 2) >= containerWidth;
                    }
                });
            }
            function selectOtherTab(oldId) {
                var newTab;
                if (tabExists(previousTabId)) {
                    scope.selectTab(previousTabId);
                } else {
                    newTab = scope.tabs[getTabIndex(oldId) - 1];
                    if (_.isObject(newTab)) {
                        scope.selectTab(newTab.id);
                    }
                }
            }
            function drawTabContent(tab) {
                /* This ensures frames are never redrawn by Angular,
                    which can cause their content to reload unexpectedly */
                var html = '<div id="' + tab.id + '__content"' +
                           '    class="alpha-main-tab-content">' +
                           '    <iframe id="' + tab.id + '"' +
                           '        class="alpha-main-tab-frame"></iframe>' +
                           '</div>',
                    iframeElement;
                element.find('.alpha-main-tab-content-wrapper').append(html);
                iframeElement = element.find('iframe#' + tab.id);
                showLoadingIndicator(tab.id);
                if (_hideIndicatorOnLoad()) {
                    iframeElement.on('load', function() {
                        scope.$apply(function() {
                            hideLoadingIndicator(tab.id);
                        });
                    });
                }
                else {
                    iframeElement.on('load', function() {
                        if(iframeElement.contents().find('pre').text().indexOf('Record Access Denied You do not have Record Access permission') != -1){
                            scope.$apply(function() {
                                hideLoadingIndicator(tab.id);
                            });
                        }
                    });
                }
                iframeElement.attr('src', tab.url);
                if(_.startsWith(tab.url, applicationContextRoot)){
                    hideMenuOnIframeClick(tab.id);
                }
                function _hideIndicatorOnLoad() {
                    /* Because the bootstrap of Angular is delayed for record detail pages,
                        they need to hide their own loading indicator. */
                    return !_.startsWith(tab.url, applicationContextRoot + '/record/view/view/');
                }
            }
            function showLoadingIndicator(id) {
                setLoadingState(id, true);
                LoaderService.startLoadingElement(element.find('#' + id + '__content'));
            }
            function hideLoadingIndicator(id) {
                setLoadingState(id, false);
                LoaderService.stopLoadingElement(element.find('#' + id + '__content'));
            }
            function hideMenuOnIframeClick(id) {
                element.find('iframe#' + id).on('load', function() {
                    jQuery(this).contents().click(function() {
                        jQuery('.alpha-main-nav .open').removeClass('open');
                    });
                });
            }
            function destroyTabContent(id) {
                try {
                    Events.unsubscribeChildWindow(element.find('iframe#' + id)[0].contentWindow);
                }
                catch (e) {
                    /* This catches errors caused by accessing cross-domain frames. Those
                        frames have no Events service and will not be in the child window
                        collection, so this error is nothing to worry about. */
                    $log.info(e.message);
                }
                element.find('#' + id + '__content').remove();
            }
            function showTabContent(id) {
                element.find('.alpha-main-tab-content').hide();
                element.find('#' + id + '__content').show();
            }
            function triggerResize(id) {
                /* This triggers the resize event on the window of the specified tab, or
                    the main window if that tab is static. This can correct issues caused
                    by elements being rendered while tabs are hidden, such as charts. */
                var iframeElement = element.find('iframe#' + id),
                    windowObject = iframeElement.length ? iframeElement[0].contentWindow : $window,
                    evt;
                try {
                    if (_.isObject(windowObject)) {
                        if (_.isFunction(Event)) {
                            evt = new Event('resize'); // Modern browsers
                        } else {
                            evt = windowObject.document.createEvent('Event'); // IE11
                            evt.initEvent('resize', true, false);
                        }
                        windowObject.dispatchEvent(evt);
                    }
                }
                catch (e) {
                    /* This catches errors caused by accessing cross-domain frames. Those
                        frames have no Events service and will not be in the child window
                        collection, so this error is nothing to worry about. */
                    $log.info(e.message);
                }

            }
            function setTabsInLocalStorage(tabs){
                var localStorageTab;
                /* If the tabs are still initializing, we don't want to do this since it
                    could replace the tabs in local storage before they have been loaded. */
                if (!initializing) {
                    localStorageTab = {};
                    localStorageTab['activeTab'] = selectedTabId;
                    localStorageTab['tabs'] = _.map(scope.tabs, function(o) {
                        var obj={
                            id: o.id,
                            title: o.title,
                            icon: o.icon,
                            locale: current_locale,
                            name: o.name
                        };
                        if(o.url){
                            obj.url = o.url;
                        }
                        if(o.permanent){
                            obj.permanent = o.permanent;
                        }
                        if(o.clipLeft){
                            obj.clipLeft = o.clipLeft;
                        }
                        return obj;
                    });
                    AlphaLocalStorageService.setDataInStorage(storageKey, localStorageTab);
                }
            }

            function loadTabsFromLocalStorage(hasAdminAccess){
                var tabsData = AlphaLocalStorageService.getDataFromStorage(storageKey);
                if(!_.isEmpty(tabsData)){
                    if(tabsData['tabs']){
                       _.forEach(tabsData['tabs'], function(value) {
                           if(!_isAdminTab(value.url) || (_isAdminTab(value.url) && hasAdminAccess)){
                               addTab(value);
                           }
                        });
                        if((tabsData['activeTab'] && !_isAdminTab(tabsData['activeTab']) || (tabsData['activeTab'] && _isAdminTab(tabsData['activeTab']) && hasAdminAccess))){
                            scope.selectTab(tabsData['activeTab']);
                        }
                    }
                }
                function _isAdminTab(string){
                   return _.includes(string, 'userManagement') || _.includes(string, 'groupManagement');
                }
            }

            function initTabs(){
                UserPreferences.getCurrentPreferences()
                    .then(function(prefs) {
                        var userStorage = AlphaLocalStorageService.getDataFromStorage(_.toUpper(prefs.userName));
                        storageKey = _getStorageKey(prefs);
                        if (_.isObject(userStorage) && !!userStorage.restoreTabs) {
                            AuthenticationService.checkUserAuthority(prefs.client.id, 'SecurityAssignmentAuthority')
                                .then(function(authority) {
                                    loadTabsFromLocalStorage(authority.hasAuthority);
                                })
                                .finally(function() {
                                    initializing = false;
                                });
                        } else {
                            initializing = false;
                            setTabsInLocalStorage();
                        }
                    })
                    .catch(function() {
                        initializing = false;
                    });
            }

            function _getStorageKey(prefs) {
                var key;
                if (_.isObject(scope.staticTab)) {
                    key = prefs.userName + '_' + prefs.client.id + '_' + scope.staticTab.id;
                } else if (attrs.section) {
                    key = prefs.userName + '_' + prefs.client.id + '_' + attrs.section;
                }
                return key;
            }
        }
    }
})();
