(function () {
    'use strict';
    angular
        .module('alpha.common.alphaSmartTable')
        .directive('alphaSmartTableChartSelection', alphaSmartTableChartSelection);

    function alphaSmartTableChartSelection(){
        return {
            restrict: 'E',
            replace: true,
            require: '^alphaSmartTable',
            scope: {
                columns: '=',
                type: '=',
                chartColumns: '=',
                columnSelectionVisible: '=',
                measure: '=',
                containerId: '@id'
            },
            templateUrl: applicationContextRoot + '/static/custom/common/alphaSmartTable/partials/alphaSmartTableColumnSelection.html',
            controller : 'ColumnSelectionController',
            controllerAs: 'csCtrl',
            bindToController :true
        };
    }
})();
